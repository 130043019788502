<template>
  <div class="card h-100">
    <div class="p-3 card-body">
      <h6>{{ chartTitle }}</h6>
      <div>
        <canvas :id="id" :ref="id" :width="width" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  props: {
    id: {
      type: String,
      default: 'pie-chart'
    },
    chartTitle: {
      type: String,
      default: ''
    },
    chartData: {
      type: Array,
      default: () => []
    },
    width: {
      type: [String, Number],
      default: '100%'
    },
    height: {
      type: [String, Number],
      default: '100%'
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      handler(data) {
        if (!data.x || data.x.length === 0) return
        this.renderChart()
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    renderChart() {
      if (!this.$refs[this.id]) {
        return // Canvas not available yet, skip rendering
      }

      const labels = this.chartData.x
      const data = this.chartData.y

      const config = {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [
            {
              data: data,
              backgroundColor: this.generateConsistentColors(data.length)
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            },
            title: {
              display: true,
              text: this.chartTitle
            },
            datalabels: {
              display: true,
              color: '#fff',
              formatter: (value, ctx) => {
                const label = ctx.chart.data.labels[ctx.dataIndex]
                return `${label}: ${value}`
              }
            }
          },
          onClick: (event, elements) => {
            if (elements.length > 0) {
              const clickedIndex = elements[0].index

              const clickedLabel = this.chartData.x[clickedIndex]
              const clickedValue = this.chartData.y[clickedIndex]

              this.$emit('dataClick', {
                index: clickedIndex,
                label: clickedLabel,
                value: clickedValue,
                title: this.chartTitle
              })
            }
          }
        }
      }

      let chartStatus = Chart.getChart(this.$refs[this.id])
      if (chartStatus != undefined) {
        chartStatus.destroy()
      }

      this.chart = new Chart(this.$refs[this.id].getContext('2d'), config)
    },
    generateRandomColors(numColors) {
      // Function to generate random colors
      const colors = []
      for (let i = 0; i < numColors; i++) {
        const color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        colors.push(color)
      }
      return colors
    },
    generateConsistentColors(numColors) {
      const colorPalette = [
        '#1f77b4',
        '#ff7f0e',
        '#2ca02c',
        '#d62728',
        '#9467bd',
        '#8c564b',
        '#e377c2',
        '#7f7f7f',
        '#bcbd22',
        '#17becf'
      ]

      const colors = []
      for (let i = 0; i < numColors; i++) {
        if (i < colorPalette.length) {
          colors.push(colorPalette[i])
        } else {
          // If more colors are needed than the predefined palette, generate random colors
          const randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16)
          colors.push(randomColor)
        }
      }
      return colors
    }
  }
}
</script>

<style scoped>
/* Add your component-specific styles here */
</style>
