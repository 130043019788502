<script>
import { computed, onMounted, ref, watch, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'
export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    const increaseIsFetching = () => {
      store.commit('increaseIsFetching')
    }

    const decreaseIsFetching = () => {
      store.commit('decreaseIsFetching')
    }

    const toDeleteIds = ref([])
    const disableInput = ref(true)
    const listOfAttr = ref([])
    const newAttrs = ref([reactive({})])
    const factorData = ref('')
    const editable = ref(true)

    function submitForm() {
      // check if any of the score is in range of 0-10, else return
      if (validateScoreRange(listOfAttr.value) || validateScoreRange(newAttrs.value)) {
        alert('Score must be in the range of 0-10')
        disableInput.value = false
        return
      }
      updateAllAttr.call(this)
    }

    function validateScoreRange(attributes) {
      return attributes.some((attr) => attr.score < 0 || attr.score > 10)
    }

    function toggleSave() {
      disableInput.value = !disableInput.value
      if (disableInput.value) submitForm.call(this)
    }

    function addToDelete(id) {
      const index = toDeleteIds.value.indexOf(id)
      if (index !== -1) {
        // If id is already in the list, remove it
        toDeleteIds.value.splice(index, 1)
      } else {
        // If id is not in the list, add it
        toDeleteIds.value.push(id)
      }
    }

    function deleteFactor() {
      if (factorData.value.weightage != 0) {
        alert('Weightage must be adjusted to 0 before deleting factor')
        return
      }

      try {
        const response = axios.delete(`${apiUrl.value}/factor`, {
          data: { id: route.params.id },
          headers: getAPIHeader.value()
        })
        console.log(response)
        router.push({ name: 'FinancialFactor' })
      } catch (error) {
        alert('Unable to delete program')
        console.log(error)
      }

      listOfAttr.value.forEach((attr) => {
        deleteAttr(attr.id)
      })
    }

    async function updateAttr(attr) {
      console.log(attr)
      try {
        const response = await axios.put(
          `${apiUrl.value}/factorAttribute`,
          {
            id: attr.id,
            factorId: attr.factorId,
            name: attr.name,
            description: attr.description,
            score: attr.score
          },
          {
            headers: getAPIHeader.value()
          }
        )
        console.log('Attribute updated successfully:', response.data)
        // this.$emit('fetch-data');
      } catch (error) {
        console.log(error)
      }
    }

    async function addAttr(attr) {
      console.log(attr)
      if (!attr.name) return
      try {
        const response = await axios.post(
          `${apiUrl.value}/factorAttribute`,
          {
            factorId: route.params.id,
            name: attr.name,
            description: attr.description,
            score: attr.score
          },
          {
            headers: getAPIHeader.value()
          }
        )
        console.log('Attribute added successfully:', response.data)
        // this.$emit('fetch-data');
      } catch (error) {
        console.log(error)
      }
    }

    async function deleteAttr(id) {
      try {
        const response = await axios.delete(`${apiUrl.value}/factorAttribute`, {
          data: { id: id },
          headers: getAPIHeader.value()
        })

        console.log('Attribute deleted successfully:', response.data)
      } catch (error) {
        console.log(error)
      }
    }

    async function updateFactor(factor) {
      try {
        const response = await axios.put(
          `${apiUrl.value}/factor`,
          {
            id: factor.id,
            resourceId: factor.resourceId,
            name: factor.name,
            description: factor.description,
            weightage: factor.weightage
          },
          {
            headers: getAPIHeader.value()
          }
        )
        console.log('Factor updated successfully:', response.data)
        // this.$emit('fetch-data');
      } catch (error) {
        console.log(error)
      }
    }

    async function updateAllAttr() {
      increaseIsFetching()
      const updateAttrPromises = this.listOfAttr.map(updateAttr)
      const addAttrPromises = this.newAttrs.map(addAttr)
      const deleteAttrPromises = this.toDeleteIds.map(deleteAttr)

      const results = await Promise.all(
        [...updateAttrPromises],
        [...addAttrPromises],
        [...deleteAttrPromises],
        [updateFactor(factorData.value)]
      )
      decreaseIsFetching()
      if (results) {
        alert('Factor updated successfully')
        window.location.reload()
      }
      console.log('All factors updated successfully:', results)
    }
    const getAttribute = async () => {
      try {
        const response = await axios.get(`${apiUrl.value}/factor/details/${route.params.id}`, {
          headers: getAPIHeader.value()
        })

        // Destructure data from the response
        const { Factor } = response.data
        factorData.value = Factor
        editable.value = Factor.editable == 1 ? true : false
        // Update the reactive property
        console.log('test', response.data)
        listOfAttr.value = Factor.FactorAttributes
        console.log(typeof listOfAttr.value)
      } catch (error) {
        console.error(error)
      }
    }

    const getAPIHeader = computed(() => {
      return store.getters.getAPIHeader
    })

    const apiUrl = computed(() => {
      return store.state.apiUrl
    })

    onMounted(() => {
      getAttribute()
    })

    watch(
      newAttrs,
      (newValue) => {
        if (newValue.at(-1)?.name) {
          newAttrs.value.push(reactive({}))
        }
        newValue.forEach((input, i) => {
          console.log(input.name, input.score, input.description)
          if (!input.name && !input.score && !input.description && i != newValue.length - 1) {
            newAttrs.value.splice(i, 1)
          }
        })
      },
      { deep: true }
    )

    return {
      listOfAttr,
      newAttrs,
      factorData,
      keys: [
        { id: 1, data: 'name', name: 'Attribute' },
        { id: 2, data: 'score', name: 'Score' },
        { id: 3, data: 'description', name: 'Description' }
      ],
      getAPIHeader,
      apiUrl,
      disableInput,
      editable,
      toDeleteIds,
      addToDelete,
      deleteFactor,
      toggleSave,
      submitForm
    }
  }
}
</script>

<template>
  <div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="pb-0 card-header">
              <div class="flex-row title-wrapper">
                <div class="col-md-10 col-lg-10 mb-2">
                  <div class="d-lg-flex">
                    <div>
                      <h5 class="pb-1">{{ factorData.name }}</h5>
                      <p>{{ factorData.description }}</p>
                    </div>
                  </div>
                </div>
                <div class="flex-row">
                  <button
                    :class="disableInput ? 'edit' : 'save'"
                    class="btn btn-submain"
                    @click.prevent="toggleSave"
                  >
                    {{ disableInput ? 'Edit' : 'Save' }}
                  </button>
                  <button
                    v-if="!disableInput"
                    class="btn btn-submain edit"
                    @click.prevent="
                      () => {
                        disableInput = true
                      }
                    "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div class="px-0 card-body">
              <div class="px-4 mt-3">
                <div class="flex-row title-wrapper">
                  <h2>Factor Details</h2>
                  <button
                    :disabled="disableInput"
                    @click="deleteFactor"
                    class="btn btn-submain edit"
                  >
                    Delete Factor
                  </button>
                </div>

                <div class="mb-3 flex-row">
                  <div class="flex-col input-wrapper">
                    <label for="">Name</label>
                    <input
                      v-model="factorData.name"
                      :disabled="disableInput || !editable"
                      type="text"
                    />
                  </div>

                  <div class="flex-col input-wrapper">
                    <label for="">Description</label>
                    <input
                      v-if="disableInput"
                      v-model="factorData.description"
                      disabled
                      type="text"
                      style="width: 500px"
                    />
                    <textarea
                      v-else
                      v-model="factorData.description"
                      class="long-input"
                      :disabled="disableInput"
                      type="text"
                    />
                  </div>

                  <div class="flex-col input-wrapper">
                    <label for="">Weightage</label>
                    <input v-model="factorData.weightage" disabled type="number" />
                  </div>
                </div>
                <br />
                <div v-if="!disableInput">
                  <div v-if="editable">
                    <div
                      v-for="(attr, i) in listOfAttr"
                      :key="attr.id"
                      class="flex-row"
                      :class="toDeleteIds.includes(attr.id) ? 'delete' : ''"
                    >
                      <div class="flex-col input-wrapper">
                        <label :class="i == 0 ? 'show' : 'hide'">Name</label>
                        <input v-model="attr.name" :disabled="disableInput" type="text" />
                      </div>
                      <div class="flex-col input-wrapper">
                        <label :class="i == 0 ? 'show' : 'hide'">Score</label>
                        <input v-model="attr.score" :disabled="disableInput" type="number" />
                      </div>
                      <div class="flex-col input-wrapper">
                        <label :class="i == 0 ? 'show' : 'hide'">Description</label>
                        <textarea
                          v-model="attr.description"
                          class="long-input"
                          :disabled="disableInput"
                          type="text"
                        />
                      </div>

                      <!-- <button
                      v-if="!disableInput"
                      class="delete"
                      @click="addToDelete(attr.id)"
                    ></button> -->

                      <button
                        v-if="!disableInput"
                        type="button"
                        class="btn mb-0 border-0 btn-outline-danger"
                        @click="addToDelete(attr.id)"
                      >
                        <!-- {{ toDeleteIds.includes(attr.id) ? 'Undo' : '&#10060;' }} -->
                        <i
                          v-if="!toDeleteIds.includes(attr.id)"
                          class="fas fa-trash-alt"
                          aria-hidden="true"
                        ></i>
                        <i v-else class="fas fa-undo" aria-hidden="true"></i>
                      </button>
                    </div>

                    <div class="divider"></div>

                    <div v-for="attr in newAttrs" :key="attr" class="new-attr flex-row">
                      <input
                        v-model="attr.name"
                        :disabled="disableInput"
                        type="text"
                        placeholder="Name of attribute"
                      />
                      <input
                        v-model="attr.score"
                        :disabled="disableInput"
                        type="number"
                        placeholder="Score"
                      />
                      <textarea
                        v-model="attr.description"
                        class="long-input"
                        :disabled="disableInput"
                        type="text"
                        placeholder="Description"
                      />
                    </div>
                  </div>
                </div>

                <div v-else class="table-responsive">
                  <VTable :data="listOfAttr" class="table">
                    <template #head>
                      <tr class="tableHeader">
                        <template v-for="i in keys" :key="i">
                          <VTh
                            class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                            :sort-key="i.data"
                          >
                            {{ i.name }}
                          </VTh>
                        </template>
                      </tr>
                    </template>
                    <template #body="{ rows }">
                      <tr v-for="row in rows" :key="row">
                        <template v-for="i in keys" :key="i">
                          <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                            {{ row[i.data] ?? 'N/A' }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </VTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
input,
textarea {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4rem;
}

textarea[disabled],
input[disabled] {
  border: none;
  background: transparent !important;
}

select {
  min-width: 5rem;
  padding-right: 2rem;
}

.input-wrapper label.hide {
  visibility: hidden;
  line-height: 0;
  padding-top: unset;
  padding-bottom: unset;
}
.flex-row {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-start;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col.input-wrapper {
  min-width: min-content;
}

.input-wrapper label {
  text-wrap: nowrap;
  margin: unset;
  padding: 0.6rem;
}

.flex-row:nth-of-type(even) input,
.flex-row:nth-of-type(even) textarea {
  background: rgb(196, 227, 235);
}

.flex-row.title-wrapper {
  display: flex;
  justify-content: space-between;
}

.flex-row input {
  border-radius: 15px;
  padding: 0.6rem;
}

.flex-row.new-detail {
  overflow-x: auto;
}

button.save,
button.edit {
  color: white;
  height: fit-content;
}
button.save {
  background-color: green;
}

button.edit {
  background-color: red;
}

textarea.long-input {
  width: clamp(350px, 40dvw, 800px);
}

.flex-row.delete {
  border: 2px solid red;
}

.flex-row.delete input,
.flex-row.delete textarea {
  background: #ffcccc;
}

.divider {
  border-bottom: 1px solid #ccc;
  margin: 1rem 0;
  width: 100%;
}
</style>
