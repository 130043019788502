<template>
  <div
    id="EditProductPicModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="EditProductPicModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="EditProductPicModal" class="modal-title fs-5">Asset Image</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <label for="companyLogoPic" class="form-label"> Asset Image </label>
          <input
            :id="assetPic"
            class="form-control"
            type="file"
            accept="image/png, image/webp, image/jpeg, image/jpg"
            @change="handleFileChange($event)"
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="updateProfile()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "EditProductPicModal",
  props: {
    productId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      assetPic: {
        file: [],
      },
    };
  },
  computed: {
    ...mapGetters(["validateValue", "getAPIHeader", "formattedDate"]),
    ...mapState(["apiUrl"]),
  },

  mounted() {
    this.id = this.$route.params.id;
    this.token = this.getAPIHeader();
  },

  methods: {
    ...mapMutations(["decreaseIsFetching", "increaseIsFetching"]),
    handleFileChange(event) {
      const fileInput = event.target;
      const files = fileInput.files;
      if (files.length > 0) {
        this.assetPic.file = files[0];
      }
    },

    updateProfile() {
      const formData = new FormData();
      formData.append("id", this.id);
      formData.append("file", this.assetPic.file);

      this.increaseIsFetching();

      axios
        .post(`${this.apiUrl}/product/upload`, formData, {
          headers: this.token,
          "Content-Type": "multipart/form-data",
        })
        .then(() => {
          this.decreaseIsFetching();
          this.$emit("fetch-data");
        });
    },
  },
};
</script>

<style></style>
