<template>
  <div class="p-3 multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Product</h5>
      <div class="multisteps-form__content">
        <div class="mt-3 row">
          <div class="col-sm-5">
            <label for="choices-brand">Brand Name*</label>
            <select
              id="choices-brand"
              v-model="selectedBrand"
              :readonly="isReadOnly"
              class="form-control"
            >
              <option value="">Select Product</option>
            </select>
          </div>
        </div>
        <div class="mt-3 row">
          <div class="col-sm-5">
            <label for="choices-model">Model*</label>
            <select
              id="choices-model"
              v-model="selectedModel"
              :readonly="isReadOnly"
              class="form-control"
            >
              <option value="">Select Model</option>
            </select>
          </div>
          <div class="col-sm-4">
            <label for="choices-vendor">Vendor/Supplier*</label>
            <select
              id="choices-vendor"
              v-model="selectedVendor"
              :readonly="isReadOnly"
              class="form-control"
            >
              <option value="">Select Vendor/Supplier</option>
            </select>
          </div>
        </div>
        <div class="mt-3 row">
          <div class="col-sm-4">
            <label for="quantity">Cost of Equipment*</label>
            <div class="d-flex align-items-center">
              <span class="me-2">RM</span>
              <input
                id="quantity"
                v-model="productCost"
                :readonly="isReadOnly"
                class="form-control"
                type="text"
              />
            </div>
            <div class="note">Cost: {{ initialCost }}</div>
          </div>
          <div class="col-sm-4">
            <label for="choices-model">Condition*</label>
            <select
              id="choices-model"
              v-model="selectedCondition"
              :readonly="isReadOnly"
              class="form-control"
            >
              <option value="">Select Condition</option>
              <option v-if="!conditionInAddedProduct('New')" value="New">New</option>
              <option v-if="!conditionInAddedProduct('Used')" value="Used">Used</option>
              <option v-if="!conditionInAddedProduct('Pre-Owned')" value="Pre-Owned">
                Pre-Owned
              </option>
              <option v-if="!conditionInAddedProduct('Recon')" value="Recon">Recon</option>
            </select>
          </div>
        </div>
        <div class="mt-3 row">
          <div class="col-sm-4">
            <label for="unitAmount">Number of Unit</label>
            <div class="d-flex align-items-center">
              <input
                id="unitAmount"
                v-model="unitAmount"
                :readonly="isReadOnly"
                class="form-control"
                type="number"
                min="1"
              />
            </div>
          </div>

          <div class="col-sm-4">
            <label for="unitAmount">JPJ Registration Fee</label>
            <div class="d-flex align-items-center">
              <select v-model="jpjFee" class="form-control">
                <option selected value="250">250</option>
                <option value="750">750</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row my-5">
          <h6>Financial Program</h6>
          <table v-if="application?.FinancialProgram" class="mx-3 w-50">
            <tr>
              <td>Name</td>
              <td>:</td>
              <td>{{ application.FinancialProgram.name }}</td>
            </tr>
            <tr>
              <td>Tier</td>
              <td>:</td>
              <td>{{ application.FinancialProgramTier?.name ?? 'N/A' }}</td>
            </tr>
            <tr>
              <td>Risk Level</td>
              <td>:</td>
              <td>
                {{ application.FinancialProgramTier?.riskLevel ?? 'N/A' }}
              </td>
            </tr>
          </table>
        </div>
        <div class="mt-3 row">
          <div class="col-sm-4">
            <label for="loanAmount">Finance Amount*</label>
            <div class="d-flex align-items-center">
              <span class="me-2">RM </span>
              <input
                id="loanAmount"
                v-model="loanAmount"
                :readonly="isReadOnly"
                :max="calMaxLoanAmount"
                class="form-control"
                type="text"
              />
            </div>

            <div v-if="application?.FinancialProgram">
              <p>
                RM 0.00 (0%) ~ RM
                {{ formatCurrency(calMaxLoanAmount) }} ({{ getLoanMaxPercentage }}%)*
              </p>
            </div>
          </div>
          <div class="col-8 col-sm-8">
            <div class="row">
              <div class="col-sm-3">
                <label for="dropdown">Prepayment*</label>
                <select
                  id="choices-year"
                  v-model="selectedPrepayment[0]"
                  :readonly="isReadOnly"
                  class="form-control"
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                </select>
              </div>
              <div class="col-auto col-sm-auto mt-4 pt-3">
                <p>+</p>
              </div>
              <div class="col-sm-3 mt-2">
                <label for="dropdown"></label>
                <select
                  id="choices-year"
                  v-model="selectedPrepayment[1]"
                  :readonly="isReadOnly"
                  class="form-control"
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3 row">
          <div class="col-sm-4">
            <label for="dropdown">Insurance*</label>
            <select
              id="choices-year"
              v-model="selectedInsurance"
              :readonly="isReadOnly"
              class="form-control"
            >
              <option value="1.25">1.25%</option>
              <option value="1">1%</option>
              <option value="1.1">1.10%</option>
              <option value="0.85">0.85%</option>
              <option value="0.9">0.90%</option>
              <option value="0">Opt Out</option>
            </select>
          </div>
        </div>

        <div class="mt-3 row">
          <div class="col-sm-4">
            <label for="interestRate">Interest Rate*</label>
            <div class="d-flex align-items-center">
              <input
                id="interestRate"
                v-model="interestRate"
                :readonly="isReadOnly"
                :min="
                  application?.FinancialProgramTier
                    ? application.FinancialProgramTier.minInterestRate
                    : 0
                "
                :max="
                  application?.FinancialProgramTier
                    ? application.FinancialProgramTier.maxInterestRate
                    : 100
                "
                class="form-control"
                type="number"
              />
              <span class="ms-2">%</span>
            </div>

            <div v-if="application?.FinancialProgramTier">
              <p>
                {{ application.FinancialProgramTier.minInterestRate }}% ~
                {{ application.FinancialProgramTier.maxInterestRate }}%*
              </p>
            </div>
          </div>
          <div class="col-sm-4">
            <label for="dropdown">Tenure Years*</label>
            <div class="d-flex align-items-center">
              <div class="w-50">
                <select
                  id="choices-year"
                  v-model="selectedTenureYears"
                  :readonly="isReadOnly"
                  class="form-control"
                >
                  <option value="">Select Year</option>
                  <template v-for="year in maxTenure" :key="year">
                    <option :value="year">{{ year }} Year</option>
                  </template>
                </select>
              </div>
              <span class="ms-2">x 12 months</span>
            </div>
          </div>
        </div>
        <div class="mt-3 row">
          <div class="col-sm-4">
            <label for="dropdown">Payment Method*</label>
            <select
              id="choices-year"
              v-model="selectedPaymentMethod"
              :readonly="isReadOnly"
              class="form-control"
            >
              <option value="">Select Payment Method</option>
              <template
                v-for="resource in resources.filter((x) => x.category == 'REPAYMENT_MODE')"
                :key="resource"
              >
                <option :value="resource.id">{{ resource.name }}</option>
              </template>
            </select>
          </div>
        </div>

        <div class="mt-5 row">
          <div class="col-3 col-sm-3">
            <button
              :disabled="isReadOnly"
              class="btn btn-submain text-white"
              type="button"
              @click="addProduct"
            >
              Add Product
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(order, index) in ordersAggregated" :key="index">
      <div v-if="order.length > 0" class="card mt-4 px-3 py-4">
        <div v-if="order?.[0]?.id" class="m-2 button-wrapper">
          <button
            :disabled="isReadOnly"
            class="btn btn-icon-only"
            :class="order?.editing ? 'text-success' : 'text-normal'"
            type="button"
            @click="toggleEdit(index)"
          >
            <i :class="order?.editing ? 'bi bi-floppy-fill' : 'fas fa-edit'"></i>
          </button>

          <button
            :disabled="isReadOnly"
            class="btn btn-icon-only text-danger"
            type="button"
            @click="removeProduct(index)"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
        <div class="row">
          <div class="col-auto col-sm-auto">
            <h5 class="font-weight-bolder my-3 d-inline">
              {{ order[0]?.condition }}
              {{ brands.find((x) => x.id == order[0]?.brandId)?.name }}
            </h5>
          </div>
          <div class="col-auto col-sm-auto">
            <h5 class="font-weight-bolder my-3 d-inline">
              ({{ products.find((x) => x.id == order[0]?.productId)?.name }})
            </h5>
          </div>
        </div>
        <div class="multisteps-form__content">
          <div class="mt-3">
            <div class="row">
              <div class="col-sm-3">
                <p class="font-weight-bolder">Vendor/Supplier</p>
              </div>
              <div class="col-auto">
                <p class="font-weight-bolder">:</p>
              </div>
              <div class="col-sm-7">
                <p>{{ vendors.find((x) => x.id == order[0]?.vendorId)?.name }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">
                <p class="font-weight-bolder">Quantity</p>
              </div>
              <div class="col-auto">
                <p class="font-weight-bolder">:</p>
              </div>
              <div class="col-sm-7">
                <p>{{ order?.[0]?.quantity ?? order.length }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">
                <p class="font-weight-bolder">Cost of Equipment</p>
              </div>
              <div class="col-auto">
                <p class="font-weight-bolder">:</p>
              </div>
              <div class="col-sm-7">
                <p>
                  <span v-if="!order.editing"> RM {{ formatCurrency(order[0]?.cost) }} </span>

                  <span v-else class="edit">
                    <label for="">Cost</label>
                    <input
                      v-model="order.resProductCost"
                      type="number"
                      :placeholder="order[0]?.cost"
                    />
                  </span>
                </p>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-sm-3">
                <p class="font-weight-bolder">Tenure (Months)</p>
              </div>
              <div class="col-auto">
                <p class="font-weight-bolder">:</p>
              </div>
              <div class="col-sm-7">
                <p>{{ order[0]?.tenure * 12 }} Months</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">
                <p class="font-weight-bolder">Amount of Finance</p>
              </div>
              <div class="col-auto">
                <p class="font-weight-bolder">:</p>
              </div>
              <div class="col-sm-7">
                <p>
                  <span v-if="!order.editing">
                    RM {{ formatCurrency(order[0]?.loanAmount) }} (
                    {{ order[0]?.marginFinance?.toFixed(3) }}% MOF)
                  </span>
                  <span v-else class="edit">
                    <label for="">Finance Amount</label>
                    <input
                      v-model="order.resFinanceAmount"
                      type="text"
                      :placeholder="order[0]?.loanAmount"
                      :max="getLoanMax(order[0]?.condition, order[0]?.cost)"
                    />
                  </span>
                </p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">
                <p class="font-weight-bolder">Prepayment</p>
              </div>
              <div class="col-auto">
                <p class="font-weight-bolder">:</p>
              </div>
              <div class="col-sm-7">
                <p>
                  ({{ order[0]?.prepaymentArray[0] }} + {{ order[0]?.prepaymentArray[1] }}) RM
                  {{ formatCurrency(order[0]?.prepaymentAmount) }}
                </p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">
                <p class="font-weight-bolder">Insurance</p>
              </div>
              <div class="col-auto">
                <p class="font-weight-bolder">:</p>
              </div>
              <div class="col-sm-7">
                <p>
                  <span v-if="!order.editing">
                    RM
                    {{ formatCurrency(order[0]?.cost) }} @ {{ order[0]?.insuranceRate }}% (RM
                    {{ formatCurrency(order[0]?.insuranceSST) }})
                  </span>
                  <span v-else class="edit">
                    <label for="">% Insurance Rate</label>
                    <input
                      v-model="order.resInsuranceRate"
                      type="number"
                      :placeholder="order[0]?.insuranceRate"
                    />
                  </span>
                </p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">
                <p class="font-weight-bolder">Downpayment</p>
              </div>
              <div class="col-auto">
                <p class="font-weight-bolder">:</p>
              </div>
              <div class="col-sm-7">
                <p>RM {{ formatCurrency(order[0]?.downpayment) }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">
                <p class="font-weight-bolder">Interest Rate</p>
              </div>
              <div class="col-auto">
                <p class="font-weight-bolder">:</p>
              </div>
              <div class="col-sm-7">
                <p>
                  <span v-if="!order.editing">
                    {{ order[0]?.interestRate }}% p.a (Effective Yield
                    {{ order[0]?.effectiveRate?.toFixed(3) }}%)
                  </span>
                  <span v-else class="edit">
                    <label for="">% Interest Rate</label>
                    <input
                      v-model="order.resInterestRate"
                      type="number"
                      :placeholder="order[0]?.interestRate"
                    />
                  </span>
                </p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-3">
                <p class="font-weight-bolder">Monthly Payment</p>
              </div>
              <div class="col-auto">
                <p class="font-weight-bolder">:</p>
              </div>
              <div class="col-sm-7">
                <p>
                  RM
                  {{ formatCurrency(order[0]?.monthlyInstallment) }}
                  x {{ order[0]?.tenure * 12 - 1 }} Months ; RM
                  {{ formatCurrency(order[0]?.finalInstallment) }}
                  x 1 Months ;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4 px-3 py-4">
      <div class="mt-4 button-row d-flex col-12">
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Save"
          @click="submitForm()"
          >Save</argon-button
        >
      </div>
    </div>
  </div>
</template>

<script>
//   import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from '@/components/ArgonButton.vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import Choices from 'choices.js'
import axios from 'axios'
import { formattedAmount, undoFormatCurrency } from '@/assets/js/calculator'

export default {
  name: 'ProductRequest',
  components: {
    //   ArgonInput,
    ArgonButton
  },
  props: {
    isReadOnly: {
      type: Boolean,
      default: false
    },
    application: {
      type: Object,
      default: () => {}
    },
    applicationProducts: {
      type: Array,
      default: () => []
    },
    resources: {
      type: Array,
      default: () => []
    }
  },
  emits: ['fetch-data', 'next-step'],
  data() {
    return {
      applicationData: [],
      //value
      products: [],
      vendors: [],
      brands: [],
      models: [],
      apiHeader: {},
      financialProgramTier: {},
      //form data
      orders: [],
      ordersAggregated: [],
      selectedBrand: '',
      selectedModel: '',
      selectedVendor: '',
      productCost: 0,
      initialCost: 0,
      selectedTenureYears: '',
      loanAmount: '',
      selectedInsurance: 1.25,
      selectedPrepayment: [0, 0],
      interestRate: '',
      selectedPaymentMethod: '',
      selectedCondition: '',
      jpjFee: 250,
      //choices
      brandChoiceSelect: {},
      modelChoiceSelect: {},
      vendorChoiceSelect: {},
      //
      aggregationParam: ['productId', 'condition']
    }
  },
  computed: {
    ...mapState(['isLoading', 'apiUrl', 'user']),
    ...mapGetters(['getAPIHeader']),
    maxTenure() {
      return this.application?.FinancialProgramTier?.maxTenure ?? 5
    },
    calMaxLoanAmount() {
      if (!this.application?.FinancialProgram) {
        return 1
      }
      const condition = this.selectedCondition == 'New' ? 'percentageOnNew' : 'percentageOnUsed'
      const percentage = this.application?.FinancialProgram[condition] / 100

      return this.undoFormatCurrency(this.productCost) * percentage
    },
    getLoanMaxPercentage() {
      const condition = this.selectedCondition == 'New' ? 'percentageOnNew' : 'percentageOnUsed'
      const percentage = this.application?.FinancialProgram[condition] / 100

      return percentage * 100
    }
  },
  watch: {
    application(newData) {
      this.interestRate = newData?.FinancialProgramTier?.maxInterestRate ?? 0

      if (this.application.hirerId) {
        this.applicationData = this.application
        this.fetchExistingOrder()
      }
    },

    applicationProducts() {
      this.fetchExistingOrder()
    },

    ['selectedBrand']() {
      this.fetchModelData()
    },
    ['selectedModel']() {
      this.fetchCost()
    },
    ['isReadOnly']() {
      if (this.isReadOnly == true) {
        this.brandChoiceSelect.disable()
        this.modelChoiceSelect.disable()
        this.vendorChoiceSelect.disable()
        this.financialProgramChoiceSelect.disable()
      } else {
        this.brandChoiceSelect.enable()
        this.modelChoiceSelect.enable()
        this.vendorChoiceSelect.enable()
        this.financialProgramChoiceSelect.enable()
      }
    },

    orders: {
      handler() {
        this.aggregateOrder()
      },
      deep: true // Enable deep watching
    }
  },

  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.fetchProductData()
    this.fetchExistingOrder()
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    undoFormatCurrency,
    fetchProductData() {
      axios
        .get(`${this.apiUrl}/product/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          const uniqueBrandNames = new Set()
          this.products = data.Products.filter((x) => x.Brand)
          this.brands = this.products
            .filter((product) => {
              const brandName = product.Brand.name
              if (!uniqueBrandNames.has(brandName)) {
                uniqueBrandNames.add(brandName)
                return true
              }
              return false
            })
            .map((x) => x.Brand)
          let brandChoices = []
          let modelChoices = []

          this.brands.forEach((brand) => {
            brandChoices.push({
              value: brand.id,
              label: brand.name
            })
          })

          if (document.getElementById('choices-brand')) {
            this.brandChoiceSelect = new Choices(document.getElementById('choices-brand'), {
              removeItemButton: true,
              allowHTML: true,
              choices: brandChoices,
              classNames: {
                containerInner: 'choices__inner brand__choice'
              }
            })
          }

          if (document.getElementById('choices-model')) {
            this.modelChoiceSelect = new Choices(document.getElementById('choices-model'), {
              removeItemButton: true,
              allowHTML: true,
              choices: modelChoices,
              classNames: {
                containerInner: 'choices__inner model__choice'
              }
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })

      axios
        .get(`${this.apiUrl}/vendor/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.vendors = data.Vendors

          let vendorChoices = []
          this.vendors.forEach((vendor) => {
            vendorChoices.push({
              value: vendor.id,
              label: vendor.name
            })
          })

          if (document.getElementById('choices-vendor')) {
            this.vendorChoiceSelect = new Choices(document.getElementById('choices-vendor'), {
              removeItemButton: true,
              allowHTML: true,
              choices: vendorChoices,
              classNames: {
                containerInner: 'choices__inner vendor__choice'
              }
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    fetchModelData() {
      let modelChoices = []
      this.models = this.products.filter((product) => product.brandId == this.selectedBrand)

      this.models.forEach((model) => {
        modelChoices.push({
          value: model.id,
          label: model.name
        })
      })
      if (Object.keys(this.modelChoiceSelect).length > 0) {
        this.modelChoiceSelect.destroy()
      }
      if (document.getElementById('choices-model')) {
        this.modelChoiceSelect = new Choices(document.getElementById('choices-model'), {
          removeItemButton: true,
          allowHTML: true,
          choices: modelChoices,
          classNames: {
            containerInner: 'choices__inner model__choice'
          }
        })
      }
    },
    fetchCost() {
      this.initialCost = this.models.find((model) => model.id == this.selectedModel)?.cost
      this.initialCost = formattedAmount(this.initialCost)
      // this.productCost = this.initialCost
    },
    fetchExistingOrder() {
      this.order = new Array()

      const promises = this.applicationProducts.map((applicantProduct) => {
        return new Promise((resolve, reject) => {
          this.Calculator(
            applicantProduct.cost,
            applicantProduct.loanAmount,
            JSON.parse(applicantProduct.prepayment),
            applicantProduct.tenure,
            applicantProduct.interestRate,
            applicantProduct.insuranceRate
          )
            .then((result) => {
              this.orders.push({
                id: applicantProduct.id,
                applicantId: this.applicationData.id,
                brandId: applicantProduct.Brand.id,
                productId: applicantProduct.productId,
                vendorId: applicantProduct.vendorId,
                cost: applicantProduct.cost,
                interestRate: applicantProduct.interestRate,
                loanAmount: applicantProduct.loanAmount,
                tenure: applicantProduct.tenure,
                manufactureYear: applicantProduct.manufactureYear,
                downpayment: applicantProduct.downpayment,
                resourceId: applicantProduct.resourceId,
                prepayment: JSON.parse(applicantProduct.prepayment),
                condition: applicantProduct.condition,
                ...result
              })
              resolve(result)
            })
            .catch((err) => {
              reject(err) // Reject the outer Promise if there's an error in the Calculator function
            })
        })
      })

      Promise.all(promises)
        .then(() => {
          // All promises have resolved, execute this.aggregateOrder()
          this.aggregateOrder()
        })
        .catch((error) => {
          // Handle errors if any of the promises reject
          console.error('Error:', error)
        })
    },
    addProduct() {
      if (!this.validateProduct()) return
      new Promise((resolve, reject) => {
        this.Calculator(
          this.undoFormatCurrency(this.productCost),
          this.undoFormatCurrency(this.loanAmount),
          this.selectedPrepayment,
          this.selectedTenureYears,
          this.interestRate,
          this.selectedInsurance,
          this.unitAmount
        )
          .then((result) => {
            this.orders.push({
              applicantId: this.application.id,
              brandId: this.selectedBrand,
              productId: this.selectedModel,
              vendorId: this.selectedVendor,
              cost: this.productCost,
              insuranceRate: this.selectedInsurance,
              tenure: this.selectedTenureYears,
              manufactureYear: '2024',
              resourceId: this.selectedPaymentMethod,
              condition: this.selectedCondition,
              quantity: this.unitAmount,
              ...result
            })
            resolve(result)
          })
          .catch((err) => {
            reject(err) // Reject the outer Promise if there's an error in the Calculator function
          })
      })
    },

    aggregateOrder() {
      const aggregatedData = this.orders.reduce((result, currentObj) => {
        // Aggregate the data by the key, which is the combination of the aggregationParam
        // .join to create a unique (category) key for each combination of the aggregationParam
        const key = this.aggregationParam.map((criteria) => currentObj[criteria]).join('-')

        if (!result[key]) {
          // If the key doesn't exist in the result object, create an array for it
          result[key] = []
        }

        // Perform a deep copy of the current object and add any additional properties as needed
        const newObj = JSON.parse(JSON.stringify(currentObj))

        // Push the deep-copied object to the array for the corresponding key
        result[key].push(newObj)

        return result
      }, {})

      this.ordersAggregated = aggregatedData
    },
    conditionInAddedProduct(condition) {
      return this.orders.find(
        (order) => order.condition == condition && this.selectedModel == order.productId
      )
    },
    removeProduct(index) {
      // if (this.orders[index] && this.orders[index].id) {
      //   this.submitDeleteProductForm(this.orders[index].id)
      // }
      // this.orders.splice(index, 1)
      const userConfirmed = confirm('Are you sure you want to delete the product ?')
      if (!userConfirmed) {
        return
      }

      this.ordersAggregated[index].forEach((order) => {
        if (order.id) {
          this.submitDeleteProductForm(order.id)
        }
      })

      this.ordersAggregated[index] = []
    },
    validateProduct() {
      const errMsg = []
      if (!this.selectedBrand) {
        errMsg.push('Please select a brand')
      }
      if (!this.selectedModel) {
        errMsg.push('Please select a model')
      }
      if (!this.selectedVendor) {
        errMsg.push('Please select a vendor')
      }
      if (!this.productCost) {
        errMsg.push('Please enter the cost of equipment')
      }
      if (!this.selectedCondition) {
        errMsg.push('Please select a condition')
      }
      if (!this.unitAmount) {
        errMsg.push('Please enter the number of unit')
      }
      if (!this.loanAmount) {
        errMsg.push('Please enter the finance amount')
      }
      if (!this.selectedPrepayment) {
        errMsg.push('Please select a prepayment')
      }
      if (!this.selectedInsurance) {
        errMsg.push('Please select an insurance')
      }
      if (!this.interestRate) {
        errMsg.push('Please enter the interest rate')
      }
      if (!this.selectedTenureYears) {
        errMsg.push('Please select a tenure')
      }
      if (!this.selectedPaymentMethod) {
        errMsg.push('Please select a payment method')
      }

      if (errMsg.length > 0) {
        alert(errMsg.join('\n'))
        return false
      }

      return true
    },
    Calculator(
      productCost,
      loanAmount,
      selectedPrepayment,
      selectedTenureYears,
      interestRate,
      insuranceRate
    ) {
      return axios
        .post(
          `${this.apiUrl}/applicantProduct/calculate`,
          {
            cost: parseFloat(productCost),
            loanAmount: parseFloat(loanAmount),
            prepayment: selectedPrepayment,
            loanMonth: selectedTenureYears * 12,
            interestRate: interestRate
          },
          {
            headers: this.apiHeader
          }
        )
        .then((res) => {
          return axios
            .post(
              `${this.apiUrl}/applicantProduct/calculate_loan`,
              {
                cost: parseFloat(productCost),
                loanAmount: parseFloat(loanAmount),
                insuranceRate: insuranceRate
              },
              {
                headers: this.apiHeader
              }
            )
            .then((response) => {
              return { ...res.data, ...response.data }
            })
            .catch((err) => {
              console.log(err)
            })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    submitForm() {
      this.orders.forEach((order, index) => {
        if (!order.id) {
          axios
            .post(
              `${this.apiUrl}/applicantProduct`,
              {
                applicantId: this.application.id,
                hirerId: this.application.hirerId,
                quantity: this.unitAmount,
                jpjFee: this.jpjFee,
                prepayment: order.prepaymentArray,
                ...order
              },
              {
                headers: this.apiHeader
              }
            )
            .then((data) => {
              console.log(data)
              if (index == this.orders.length - 1) {
                this.$emit('fetch-data')
                this.$emit('next-step')
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      })
    },
    submitDeleteProductForm(id) {
      axios
        .delete(`${this.apiUrl}/applicantProduct`, {
          headers: this.apiHeader,
          params: {
            id: id
          }
        })
        .then(() => {})
        .catch((err) => {
          console.log(err)
        })
    },
    formatCurrency(amount) {
      if (!amount || isNaN(amount)) {
        return amount
      } else {
        // return amount.toLocaleString(undefined, {
        //   minimumFractionDigits: 2,
        //   maximumFractionDigits: 2
        // })
        return formattedAmount(amount)
      }
    },
    getLoanMax(condition, cost) {
      const max = condition == 'New' ? 1 : 0.9
      return cost * max
    },
    toggleEdit(index) {
      if (this.ordersAggregated[index]?.editing) {
        this.ordersAggregated[index].editing = false
        // also toggle update API (save)
        this.saveEdit(index)
      } else {
        this.ordersAggregated[index].editing = true
      }
    },
    async saveEdit(index) {
      this.ordersAggregated[index].editing = false
      const product = this.ordersAggregated[index]

      const productCost = product?.resProductCost ?? product[0]?.cost
      const loanAmount = product?.resFinanceAmount ?? product[0]?.loanAmount
      const prepayment = product[0]?.prepayment
      const tenure = product[0]?.tenure
      const interestRate = product?.resInterestRate ?? product[0]?.interestRate
      const insuranceRate = product?.resInsuranceRate ?? product[0]?.insuranceRate

      const maxLoan = this.getLoanMax(product[0]?.condition, productCost)

      if (loanAmount > maxLoan) {
        alert(
          `Finance Amount for ${product[0]?.condition} product exceeded ${
            (maxLoan / productCost) * 100
          }% (${maxLoan}) of the cost of equipment`
        )
        return
      }

      this.Calculator(productCost, loanAmount, prepayment, tenure, interestRate, insuranceRate)
        .then((result) => {
          // this.orders[index] = {
          //   ...this.orders[index],
          //   ...result
          // }
          this.ordersAggregated[index].forEach((order) => {
            try {
              axios
                .put(
                  `${this.apiUrl}/applicantProduct`,
                  {
                    id: order.id,
                    cost: productCost,
                    loanAmount: loanAmount,
                    interestRate: interestRate,
                    insuranceRate: insuranceRate
                  },
                  {
                    headers: this.apiHeader
                  }
                )
                .then(() => {
                  product[0].cost = productCost
                  product[0].loanAmount = loanAmount
                  product[0].interestRate = interestRate
                  product[0].insuranceRate = insuranceRate
                  product[0].finalInstallment = result.finalInstallment
                  product[0].monthlyInstallment = result.monthlyInstallment
                  product[0].downpayment = result.downpayment
                  product[0].marginFinance = result.marginFinance
                  product[0].prepaymentAmount = result.prepaymentAmount
                  product[0].insuranceSST = result.insuranceSST
                })
                .catch((err) => {
                  console.log(err)
                })

              // this.$emit('fetch-data');
            } catch (error) {
              alert(error)
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
      // also toggle update API (save)
    }
  }
}
</script>

<style>
.flex-col {
  display: flex;
  flex-direction: column;
}

.button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
}

.edit {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.edit label {
  margin: unset;
}

.edit input {
  width: clamp(150px, 15vw, 250px);
}

.note {
  font-size: 0.8rem;
  color: #999;
}
</style>
