<template>
  <div class="p-3 multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <!-- <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">
        Rule Based - Speed Reference Check
      </h5>
    </div> -->
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Risk Acceptance Criteria</h5>
    </div>
    <template v-for="(creteria, i) in creteriaData" :key="creteria">
      <div class="card mt-4 px-3 py-4">
        <h5 class="font-weight-bolder my-3">{{ creteria.Criteria.name }}</h5>
        <p>{{ creteria.Criteria.description }}</p>
        <table>
          <!-- <tr>
            <th class="w-10" style="vertical-align: top">Description</th>
            <td></td>
          </tr> -->
          <tr>
            <th class="w-20"><div class="mt-3">Y / N</div></th>

            <td>
              <div class="w-25 mt-3">
                <select
                  id="yesNoDropdown"
                  v-model="creteria.status"
                  :readonly="isReadOnly"
                  class="form-control"
                >
                  <option value="">N/A</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <th style="vertical-align: top"><div class="mt-3">Remark</div></th>

            <td>
              <div class="w-100 d-flex mt-3">
                <textarea
                  id="subject"
                  v-model="creteria.remark"
                  :readonly="isReadOnly"
                  class="form-control w-50"
                  rows="5"
                ></textarea>
                <div v-if="creteria.ctosRemark" class="w-50 ps-2">
                  <div class="flex-row">
                    <h6>CTOS</h6>
                    <button
                      type="button"
                      class="btn btn-warning"
                      @click="pasteToRemark(creteria.ctosRemark, i)"
                    >
                      Paste to remark
                    </button>
                  </div>
                  <textarea
                    class="form-control"
                    rows="5"
                    readonly
                    :value="creteria.ctosRemark"
                  ></textarea>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </template>
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Credit Information and Conduct of Account of Borrower</h5>
      <div class="table-responsive" v-if="Object.entries(creditInfo).length > 0">
        <table class="table">
          <tr class="border-bottom">
            <th>Month</th>
            <th>Withdrawal</th>
            <th>Deposit</th>
          </tr>
          <tr v-for="[key, value] in Object.entries(creditInfo)" :key="value">
            <td>{{ key }}</td>
            <td :class="value['Total Deposits'] < 0 ? 'text-danger' : ''">
              {{
                formattedAmount(value['Total Deposits'])
                  ? formattedAmount(value['Total Deposits'])
                  : formattedAmount(0)
              }}
            </td>
            <td :class="value['Total Deposits'] < 0 ? 'text-danger' : ''">
              {{
                formattedAmount(value['Total Withdrawals'])
                  ? formattedAmount(value['Total Withdrawals'])
                  : formattedAmount(0)
              }}
            </td>
          </tr>
        </table>
      </div>
      <div v-else>
        <h6 class="ms-3">No Data To Show</h6>
      </div>

      <br />
      <h6 class="font-weight-bolder my-3">Comments</h6>
      <textarea
        id="subject"
        v-model="creditInfoComment.value"
        class="form-control w-80"
        rows="5"
      ></textarea>
    </div>
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Financial Analysis of the Borrower</h5>
      <div class="w-100" v-if="Object.keys(financialAnalysis).length > 0">
        <table class="table w-100">
          <tr class="border-bottom">
            <th>Management Accounts as at FYE</th>
            <th class="text-wrap" v-for="key in Object.keys(financialAnalysis)" :key="key">
              {{ key.substring(7).trim().split(' ')[0] }}
            </th>
          </tr>
          <tr>
            <td>Turnover</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.turnover < 0 ? 'text-danger' : ''"
            >
              <input type="number" v-model="value.turnover" class="form-control" />
            </td>
          </tr>
          <tr>
            <td>Profit Before Tax</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.PBT < 0 ? 'text-danger' : ''"
            >
              <input type="number" v-model="value.PBT" class="form-control" />
            </td>
          </tr>
          <tr>
            <td>NPAT</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.NPAT < 0 ? 'text-danger' : ''"
            >
              <input type="number" v-model="value.NPAT" class="form-control" />
            </td>
          </tr>
          <tr>
            <td>Net Worth (Tangible)</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.netWorth < 0 ? 'text-danger' : ''"
            >
              <input type="number" v-model="value.netWorth" class="form-control" />
            </td>
          </tr>
          <tr>
            <td>Current Ratio (CA/CL)</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.currentRatio < 0 ? 'text-danger' : ''"
            >
              <input type="number" v-model="value.currentRatio" class="form-control" />
            </td>
          </tr>
          <tr>
            <td>Gearing Ratio (TL/TNW)</td>
            <td
              v-for="value in Object.values(financialAnalysis)"
              :key="value"
              :class="value.gearingRatio < 0 ? 'text-danger' : ''"
            >
              <input type="number" v-model="value.gearingRatio" class="form-control" />
            </td>
          </tr>
          <tr>
            <td>Leverage Ratio (TBB/TNW)</td>
            <td v-for="value in Object.values(financialAnalysis)" :key="value">
              <input type="number" v-model="value.leverageRatio" class="form-control" />
            </td>
          </tr>
          <tr>
            <td>Date FYE</td>
            <td v-for="value in Object.values(financialAnalysis)" :key="value">
              {{ value.dateFYE ?? 'N/A' }}
            </td>
          </tr>
          <tr>
            <td>File</td>
            <td v-for="value in Object.values(financialAnalysis)" :key="value">
              <a target="_blank" :href="value.pdfURL"><i class="far fa-file-pdf"></i></a>
            </td>
          </tr>
        </table>
      </div>
      <div v-else>
        <h6 class="ms-3">No Data To Show</h6>
      </div>

      <br />
      <h6 class="font-weight-bolder my-3">Comments</h6>
      <textarea
        id="subject"
        v-model="financialComment.value"
        class="form-control w-80"
        rows="5"
      ></textarea>
    </div>
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Debtors & Creditors Ageing Analysis</h5>
      <div class="w-100">
        <div class="row">
          <div class="col-md-5">
            <table class="table w-100">
              <tr>
                <th colspan="3" class="text-center">Debtors Ageing as at 14 Sept 2023</th>
              </tr>
              <tr class="border-bottom">
                <th>Days</th>
                <th class="text-wrap">RM'000</th>
                <th class="text-wrap">%</th>
              </tr>
              <tr>
                <td>Current Month</td>
                <td :class="customerAgeing['Current Mth'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(customerAgeing['Current Mth'] ?? 0) }}
                </td>
                <td :class="customerAgeing['Current Mth'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(
                      calPercent(customerAgeing['Current Mth'], customerAgeing['Total'])
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td>1 Month</td>
                <td :class="customerAgeing['1 Month'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(customerAgeing['1 Month'] ?? 0) }}
                </td>
                <td :class="customerAgeing['1 Month'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(calPercent(customerAgeing['1 Month'], customerAgeing['Total']))
                  }}
                </td>
              </tr>
              <tr>
                <td>2 Months</td>
                <td :class="customerAgeing['2 Months'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(customerAgeing['2 Months'] ?? 0) }}
                </td>
                <td :class="customerAgeing['2 Months'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(calPercent(customerAgeing['2 Months'], customerAgeing['Total']))
                  }}
                </td>
              </tr>
              <tr>
                <td>3 Months</td>
                <td :class="customerAgeing['3 Months'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(customerAgeing['3 Months'] ?? 0) }}
                </td>
                <td :class="customerAgeing['3 Months'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(calPercent(customerAgeing['3 Months'], customerAgeing['Total']))
                  }}
                </td>
              </tr>
              <tr>
                <td>4 Months</td>
                <td :class="customerAgeing['4 Months'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(customerAgeing['4 Months'] ?? 0) }}
                </td>
                <td :class="customerAgeing['4 Months'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(calPercent(customerAgeing['4 Months'], customerAgeing['Total']))
                  }}
                </td>
              </tr>
              <tr>
                <td>5 Months & Above</td>
                <td :class="customerAgeing['5 Mths & above'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(customerAgeing['5 Mths & above'] ?? 0) }}
                </td>
                <td :class="customerAgeing['5 Mths & above'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(
                      calPercent(customerAgeing['5 Mths & above'], customerAgeing['Total'])
                    )
                  }}
                </td>
              </tr>
              <tr class="text-bold text-dark">
                <td>Total</td>
                <td :class="customerAgeing['Total'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(customerAgeing['Total'] ?? 0) }}
                </td>
                <td :class="customerAgeing['Total'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(calPercent(customerAgeing['Total'], customerAgeing['Total']))
                  }}
                </td>
              </tr>
            </table>
          </div>
          <div class="col-md-5 ms-auto">
            <table class="table w-100">
              <tr>
                <th colspan="3" class="text-center">Creditors Ageing as at 14 Sept 2023</th>
              </tr>
              <tr class="border-bottom">
                <th>Days</th>
                <th class="text-wrap">RM'000</th>
                <th class="text-wrap">%</th>
              </tr>
              <tr>
                <td>Current Month</td>
                <td :class="supplierAgeing['Current Mth'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(supplierAgeing['Current Mth'] ?? 0) }}
                </td>
                <td :class="supplierAgeing['Current Mth'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(
                      calPercent(supplierAgeing['Current Mth'], supplierAgeing['Total'])
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td>1 Month</td>
                <td :class="supplierAgeing['1 Month'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(supplierAgeing['1 Month'] ?? 0) }}
                </td>
                <td :class="supplierAgeing['1 Month'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(calPercent(supplierAgeing['1 Month'], supplierAgeing['Total']))
                  }}
                </td>
              </tr>
              <tr>
                <td>2 Months</td>
                <td :class="supplierAgeing['2 Months'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(supplierAgeing['2 Months'] ?? 0) }}
                </td>
                <td :class="supplierAgeing['2 Months'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(calPercent(supplierAgeing['2 Months'], supplierAgeing['Total']))
                  }}
                </td>
              </tr>
              <tr>
                <td>3 Months</td>
                <td :class="supplierAgeing['3 Months'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(supplierAgeing['3 Months'] ?? 0) }}
                </td>
                <td :class="supplierAgeing['3 Months'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(calPercent(supplierAgeing['3 Months'], supplierAgeing['Total']))
                  }}
                </td>
              </tr>
              <tr>
                <td>4 Months</td>
                <td :class="supplierAgeing['4 Months'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(supplierAgeing['4 Months'] ?? 0) }}
                </td>
                <td :class="supplierAgeing['4 Months'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(calPercent(supplierAgeing['4 Months'], supplierAgeing['Total']))
                  }}
                </td>
              </tr>
              <tr>
                <td>5 Months & Above</td>
                <td :class="supplierAgeing['5 Mths & above'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(supplierAgeing['5 Mths & above'] ?? 0) }}
                </td>
                <td :class="supplierAgeing['5 Mths & above'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(
                      calPercent(supplierAgeing['5 Mths & above'], supplierAgeing['Total'])
                    )
                  }}
                </td>
              </tr>
              <tr class="text-bold text-dark">
                <td>Total</td>
                <td :class="supplierAgeing['Total'] < 0 ? 'text-danger' : ''">
                  {{ formattedAmount(supplierAgeing['Total'] ?? 0) }}
                </td>
                <td :class="supplierAgeing['Total'] < 0 ? 'text-danger' : ''">
                  {{
                    formattedAmount(calPercent(supplierAgeing['Total'], supplierAgeing['Total']))
                  }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <br />
      <h6 class="font-weight-bolder my-3">Comments</h6>
      <textarea
        id="subject"
        v-model="debtorComment.value"
        class="form-control w-80"
        rows="5"
      ></textarea>
    </div>
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Rationales & Recommendation</h5>
      <div class="mt-3 mx-1 my-1 row">
        <textarea
          id="subject"
          v-model="rcmdData"
          :readonly="isReadOnly"
          class="form-control w-80"
          rows="5"
        ></textarea>
        <p v-if="rcmdError" class="text-danger">Value is empty</p>
      </div>
      <button type="button" class="btn btn-outline-primary ms-auto w-20 mt-3" @click="addrcmdData">
        ADD
      </button>
      <table class="text-start">
        <tr v-for="(row, index) in rcmdList" :key="row">
          <td>{{ index + 1 }}.</td>
          <td class="text-start">{{ row.comment }}</td>
          <td>
            <button class="btn" type="button" :disabled="isReadOnly" @click="removeRcmdData(index)">
              <i class="fas fa-trash-alt"></i>
            </button>
          </td>
        </tr>
      </table>
    </div>

    <div class="card mt-4 px-3 py-4">
      <div class="mt-2 button-row d-flex col-12">
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          @click="submitForm()"
          >Save</argon-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from '@/components/ArgonButton.vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { formattedAmount, undoFormatCurrency, calPercent } from '@/assets/js/calculator'
import axios from 'axios'

export default {
  name: 'ProductInfo',
  components: {
    ArgonButton
  },
  props: {
    resources: {
      type: Array,
      default: () => []
    },
    application: {
      type: Object,
      default: () => {}
    },
    applicationCriterias: {
      type: Array,
      default: () => []
    },
    applicationRecommendations: {
      type: Array,
      default: () => []
    },
    applicationComments: {
      type: Array,
      default: () => []
    }
  },
  emits: ['fetch-data', 'next-step'],
  data() {
    return {
      supplierAgeing: [],
      customerAgeing: [],
      creditInfo: [],
      financialAnalysis: [],
      creteriaData: [],
      rcmdList: [],
      rcmdError: false,
      rcmdData: '',
      creditInfoComment: {
        value: '',
        id: ''
      },
      financialComment: {
        value: '',
        id: ''
      },
      debtorComment: {
        value: '',
        id: ''
      }
    }
  },

  computed: {
    ...mapState(['isLoading', 'apiUrl', 'user']),
    ...mapGetters(['getAPIHeader'])
  },
  watch: {
    applicationCriterias() {
      this.getCriteriaData()
    },
    applicationRecommendations() {
      this.getRecommendationData()
    },
    applicationComments() {
      this.getCommentData()
    }
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.getCriteriaData()
    this.getRecommendationData()
    this.getCommentData()
    this.getAgeingData()
    this.getFinancialAnalysis()
    this.getCreditInfo()
  },

  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    formattedAmount,
    undoFormatCurrency,
    calPercent,
    getCreditInfo() {
      //Fetch Credit Info
      axios
        .get(`${this.apiUrl}/applicantDocument/credit_info/${this.application.id}`, {
          headers: this.getAPIHeader()
        })
        .then(({ data }) => {
          this.creditInfo = data
          console.log('credit info', this.creditInfo)
          for (const value of Object.values(this.creditInfo)) {
            for (const [key, innerValue] of Object.entries(value)) {
              value[key] = undoFormatCurrency(innerValue)
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getFinancialAnalysis() {
      //Fetch Financial Analysis
      axios
        .get(`${this.apiUrl}/applicantDocument/financial_analysis/${this.application.id}`, {
          headers: this.getAPIHeader()
        })
        .then(({ data }) => {
          console.log('Fetched Data', data)
          this.financialAnalysis = data
          console.log('this.financialAnalysis', this.financialAnalysis)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    getAgeingData() {
      //Fetch Supplier Ageing
      axios
        .get(`${this.apiUrl}/applicantDocument/supplier_ageing/${this.application.id}`, {
          headers: this.getAPIHeader()
        })
        .then(({ data }) => {
          console.log('Fetched Data', data)
          this.supplierAgeing = data
          console.log('this.supplierAgeing', this.supplierAgeing)
        })
        .catch((err) => {
          console.log(err)
        })

      //Fetch Customer Ageing
      axios
        .get(`${this.apiUrl}/applicantDocument/customer_ageing/${this.application.id}`, {
          headers: this.getAPIHeader()
        })
        .then(({ data }) => {
          this.customerAgeing = data
          console.log('this.customerAgeing', this.customerAgeing)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getCriteriaData() {
      this.creteriaData = new Array()
      this.applicationCriterias.forEach((c) => {
        this.creteriaData.push({
          ...c,
          status: c?.status ?? ''
        })
      })
    },
    getCommentData() {
      if (this.applicationComments && this.applicationComments.length > 0) {
        this.applicationComments.forEach((x) => {
          this[x.category] = x
        })
      }
    },
    getRecommendationData() {
      this.rcmdList = this.applicationRecommendations
    },
    addrcmdData() {
      if (this.rcmdData.length > 0) {
        this.rcmdError = false
        this.rcmdList.push({
          id: null,
          comment: this.rcmdData
        })
      } else {
        this.rcmdError = true
      }

      this.rcmdData = ''
    },
    removeRcmdData(index) {
      if (this.rcmdList[index] && this.rcmdList[index].id) {
        this.submitDeleteRcmdForm(this.rcmdList[index].id)
      }
      this.rcmdList.splice(index, 1)
    },
    submitForm() {
      this.submitFinancialAnalysisForm()
      this.submitCriteriaForm()
    },

    formatFinancialAnalysisForm() {
      //format empty value to null to ensure proper submission
      console.log('Before formatting', this.financialAnalysis)
      console.log('Only Values', Object.values(this.financialAnalysis))
      for (let [date, analysisData] of Object.entries(this.financialAnalysis)) {
        console.log('Date', date)
        console.log('Value', analysisData)
        for (let [attribute, attributeData] of Object.entries(analysisData)) {
          if (!attributeData) {
            this.financialAnalysis[date][attribute] = null
          }
        }
      }
      console.log('After formatting', this.financialAnalysis)
    },

    submitFinancialAnalysisForm() {
      console.log('Applicant Id', this.application.id)
      this.formatFinancialAnalysisForm()
      let payload = {
        applicantId: this.application.id,
        data: this.financialAnalysis
      }
      axios.put(`${this.apiUrl}/applicantDocument/financial_analysis`, payload, {
        headers: this.apiHeader
      })
    },
    submitCriteriaForm() {
      this.submitCommentForm(this.application.id)
      this.creteriaData.forEach((c, index) => {
        let payload = {
          id: c.id,
          remark: c.remark,
          status: c.status === '' ? null : c.status
        }

        this.increaseIsFetching()
        axios
          .put(`${this.apiUrl}/applicantCriteria`, payload, {
            headers: this.apiHeader
          })
          .then(() => {
            if (index == this.creteriaData.length - 1) {
              this.submitRcmdForm(c.applicantId)
            }
            this.decreaseIsFetching()
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    submitRcmdForm(id) {
      if (this.rcmdList.length > 0) {
        this.increaseIsFetching()
        this.rcmdList.forEach((r, index) => {
          let payload = {
            comment: r.comment
          }
          let action = 'post'
          if (r.id) {
            action = 'put'
            payload.id = r.id
          } else {
            payload.applicantId = id
          }
          axios[action](`${this.apiUrl}/applicantRecommendation`, payload, {
            headers: this.apiHeader
          })
            .then(() => {
              if (index == this.rcmdList.length - 1) {
                this.decreaseIsFetching()
                this.$emit('fetch-data')
                this.$emit('next-step')
              }
            })
            .catch((err) => {
              console.log(err)
            })
        })
      } else {
        this.$emit('fetch-data')
        this.$emit('next-step')
      }
    },
    submitCommentForm(id) {
      const commentCats = ['creditInfoComment', 'financialComment', 'debtorComment']
      commentCats.forEach((commentCat) => {
        if (this[commentCat].value) {
          this.decreaseIsFetching()
          let payload = {
            value: this[commentCat].value,
            category: commentCat
          }
          let action = 'post'
          if (this[commentCat].id) {
            action = 'put'
            payload.id = this[commentCat].id
          } else {
            payload.applicantId = id
          }
          axios[action](`${this.apiUrl}/applicantComment`, payload, {
            headers: this.apiHeader
          })
            .then(() => {
              this.decreaseIsFetching()
            })
            .catch((err) => {
              this.decreaseIsFetching()
              console.log(err)
            })
        }
      })
    },
    submitDeleteRcmdForm(id) {
      this.increaseIsFetching()
      axios['delete'](`${this.apiUrl}/applicantRecommendation`, {
        headers: this.apiHeader,
        params: {
          id: id
        }
      })
        .then(() => {})
        .catch((err) => {
          console.log(err)
        })
    },

    pasteToRemark(copied, index) {
      if (this.creteriaData[index].remark) {
        this.creteriaData[index].remark += copied
      } else {
        this.creteriaData[index].remark = copied
      }
    }
  }
}
</script>

<style>
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.table > :not(:first-child) {
    border-top: 0px !important;
}
</style>
