<template>
  <div class="py-4 container-fluid pt-0">
    <div class="card">
      <div id="Profile" class="card-body">
        <div class="row">
          <div class="col-1">
            <img
              class="w-100 img-fluid rounded img-thumbnail"
              :src="
                company.logoUrl ? company.logoUrl : require('@/assets/img/xcmg_logo_vertical.png')
              "
              alt=""
            />
            <div class="mt-2">
              <button
                class="btn btn-primary mx-1"
                data-bs-toggle="modal"
                data-bs-target="#EditCompanyPicModal"
              >
                Edit
              </button>
            </div>
          </div>
          <div class="col-9">
            <h5 class="fw-bold text-capitalize">
              {{ company.name }}
            </h5>
            <h6 class="fw-bold text-secondary">
              {{ company.code }}
            </h6>
            <p v-if="company.latePayment" class="badge badge-danger">Late Payment</p>
            <p class="p-2">Reg. No : {{ company.registrationNo }}</p>
          </div>
          <div class="col d-flex justify-content-end align-items-start">
            <button
                class="btn btn-danger"
                type="button"
                @click="deleteData()"
              >
                Delete
              </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-md-6 h-100">
        <div class="card shadow">
          <div class="card-header d-flex">
            <h6>Company Profile</h6>
            <div class="ms-auto">
              <button
                class="btn btn-sm btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#ProfileModal"
              >
                Edit
              </button>
            </div>
          </div>
          <div class="card-body pt-0">
            <table class="">
              <tr class="border-0">
                <th class="w-25">Name</th>
                <th>:</th>
                <td class="text-capitalize">{{ company.name ?? 'N/A' }}</td>
              </tr>
              <tr class="border-0">
                <th>Reg. No</th>
                <th>:</th>
                <td class="truncate">{{ company.registrationNo ?? 'N/A' }}</td>
              </tr>
              <tr class="border-0">
                <th>Business Sector</th>
                <th>:</th>
                <td>{{ company.Resource?.name ?? 'N/A' }}</td>
              </tr>
              <tr class="border-0">
                <th>Establish Year</th>
                <th>:</th>
                <td>{{ company.establishYear ?? 'N/A' }}</td>
              </tr>
              <tr class="border-0">
                <th>Email</th>
                <th>:</th>
                <td>{{ company.email ?? 'N/A' }}</td>
              </tr>
              <tr class="border-0">
                <th>Reg. Address</th>
                <th>:</th>
                <td class="w-100">
                  {{ company.address ?? 'N/A' }}
                </td>
              </tr>
              <tr class="border-0">
                <th>Phone No.</th>
                <th>:</th>
                <td>{{ company.phone ?? 'N/A' }}</td>
              </tr>
              <tr class="border-0">
                <th>Remark</th>
                <th>:</th>
                <td>{{ company.remark ?? 'N/A' }}</td>
              </tr>
              <tr class="border-0">
                <th>Financial Program</th>
                <th>:</th>
                <td
                  v-if="
                    company.History_CTOS?.Applicant?.FinancialProgram &&
                    company.History_CTOS?.Applicant?.FinancialProgramTier
                  "
                >
                  {{ company.History_CTOS.Applicant.FinancialProgram.name }} -
                  {{ company.History_CTOS.Applicant.FinancialProgramTier.name }}
                </td>
                <td v-else>N/A</td>
              </tr>
              <tr class="border-0">
                <th>Total Loan Limit</th>
                <th>:</th>
                <td>
                  {{
                    company.loanLimit ? 'RM ' + Number(company.loanLimit).toLocaleString() : 'N/A'
                  }}
                </td>
              </tr>
              <tr class="border-0">
                <th>Total Exposure</th>
                <th>:</th>
                <td>{{ totalExposure ?? 'N/A' }}</td>
              </tr>
              <tr class="border-0">
                <th>CTOS Score</th>
                <th>:</th>
                <td>
                  {{ company.History_CTOS?.analyze_result?.rating_score }}
                </td>
              </tr>

              <tr class="border-0">
                <th>Last Loan Approval Date</th>
                <th>:</th>
                <td>
                  {{ applicantProducts?.[0]?.agreementExecution }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6 h-100">
        <div class="card shadow h-100">
          <div class="card-header d-flex">
            <h6>Last Application</h6>
          </div>
          <div class="card-body table-responsive pt-0">
            <div v-if="applicantDocuments.length != 0">
              <div v-for="applicant in applicantDocuments" :key="applicant.id">
                <div
                  v-if="
                    getResourceFileName(applicant.resourceId) != 'Bank Account Statement' &&
                    getResourceFileName(applicant.resourceId) != 'Other'
                  "
                >
                  <table class="table">
                    <tr class="border-0">
                      <th class="w-10">File</th>
                      <th class="w-5">:</th>
                      <th>{{ getResourceFileName(applicant.resourceId) }}</th>
                    </tr>
                    <tr class="border-0">
                      <th>File Path</th>
                      <th>:</th>
                      <th>
                        <a target="_blank" :href="applicant.url"><i class="far fa-file-pdf"></i></a>
                      </th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div v-else>
              <p>No Documents Found</p>
            </div>
          </div>
          <div>
            <div class="card-body table-responsive pt-0">
              <table class="table">
                <tr class="border-0">
                  <th>XCMG Score</th>
                  <th>:</th>
                  <td>{{ applicantProducts?.[0]?.Applicant?.xcmgScore ?? 'No Score Found' }}</td>
                </tr>

                <tr class="border-0">
                  <th>Last Updated</th>
                  <th>:</th>
                  <td>
                    {{
                      new Date(applicantProducts?.[0]?.Applicant?.updatedAt).toLocaleString() ??
                      'No Score Found'
                    }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div id="directorshipDiv">
        <div
          class="collapse-container fw-bold fs-6 shadow-lg border border-1 border-primary bg-white w-100 px-4 py-2 my-3"
          data-bs-toggle="collapse"
          data-bs-target="#collapseDirectorship"
          aria-expanded="false"
          aria-controls="collapseDirectorship"
        >
          <span class="text-primary">Directorships</span>
        </div>
        <div id="collapseDirectorship" class="collapse show">
          <div class="card">
            <div class="px-0 pb-0 card-body">
              <div class="text-end me-4">
                <button
                  class="btn btn-outline-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#ContactModal"
                  @click="
                    () => {
                      selectedContactId = null
                    }
                  "
                >
                  Create New
                </button>
              </div>

              <div class="px-4 mt-3">
                <div v-if="hirerContacts.length > 0" class="table-responsive">
                  <VTable
                    v-model:currentPage="drship.currentPage"
                    :data="hirerContacts"
                    :page-size="drship.pageSize"
                    :filters="drship.filters"
                    class="table"
                    @total-pages-changed="
                      () => {
                        drship.totalPages = $event
                      }
                    "
                  >
                    <template #head>
                      <tr class="tableHeader">
                        <template v-for="i in drship.keys" :key="i">
                          <VTh
                            class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                            :sort-key="i.data"
                          >
                            {{ i.name }}
                          </VTh>
                        </template>
                        <th
                          class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                        >
                          Action
                        </th>
                      </tr>
                    </template>
                    <template #body="{ rows }">
                      <tr v-for="row in rows" :key="row">
                        <template v-for="i in drship.keys" :key="i">
                          <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                            {{ row[i.data] ? row[i.data] : 'N/A' }}
                          </td>
                        </template>
                        <td>
                          <button
                            class="btn btn-icon btn-outline-dark border-0"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#ContactModal"
                            @click="
                              () => {
                                selectedContactId = row.id
                              }
                            "
                          >
                            <i class="far fa-edit"></i>
                          </button>
                        </td>
                      </tr>
                    </template>
                  </VTable>
                </div>

                <div v-else>
                  <h5 class="text-bold">No Record Found</h5>
                </div>

                <div class="table-controls row mt-6">
                  <div class="col-md-6 mb-2">
                    <div class="d-lg-flex">
                      <p>
                        {{
                          hirerContacts.length < drship.currentPage * drship.pageSize
                            ? `Showing ${
                                drship.currentPage * drship.pageSize - drship.pageSize + 1
                              } to ${hirerContacts.length} of ${hirerContacts.length} entries`
                            : `Showing ${
                                drship.currentPage * drship.pageSize - drship.pageSize + 1
                              } to ${drship.currentPage * drship.pageSize} of ${
                                hirerContacts.length
                              } entries`
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6 mb-2 ms-auto">
                    <div class="d-lg-flex justify-content-end">
                      <VTPagination
                        :current-page="drship.currentPage"
                        :boundary-links="true"
                        :total-pages="drship.totalPages"
                        :max-page-links="drship.maxPageLinks"
                        :hide-single-page="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="applicationDiv">
        <div
          class="collapse-container fw-bold fs-6 shadow-lg border border-1 border-primary bg-white w-100 px-4 py-2 my-3"
          data-bs-toggle="collapse"
          data-bs-target="#collapseApplication"
          aria-expanded="false"
          aria-controls="collapseApplication"
        >
          <span class="text-primary">Existing Facilities</span>
        </div>
        <div id="collapseApplication" class="collapse show">
          <div class="card">
            <div class="px-0 pb-0 card-body">
              <div class="px-4 mt-3">
                <div v-if="applicantProducts.length > 0" class="table-responsive">
                  <VTable
                    v-model:currentPage="application.currentPage"
                    :data="applicantProducts"
                    :page-size="application.pageSize"
                    :filters="application.filters"
                    class="table"
                    @total-pages-changed="
                      () => {
                        application.totalPages = $event
                      }
                    "
                  >
                    <template #head>
                      <tr class="tableHeader">
                        <template v-for="i in application.keys" :key="i">
                          <VTh
                            class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                            :sort-key="i.data"
                          >
                            {{ i.name }}
                          </VTh>
                        </template>
                      </tr>
                    </template>
                    <template #body="{ rows }">
                      <tr v-for="row in rows" :key="row">
                        <template v-for="i in application.keys" :key="i">
                          <td
                            v-if="i.name != 'Create Date'"
                            class="text-sm font-weight-normal text-start text-wrap align-middle"
                          >
                            {{ formattedAmount(row[i.data]) ?? 'N/A' }}
                          </td>
                          <td
                            v-else
                            class="text-sm font-weight-normal text-start text-wrap align-middle"
                          >
                            {{ formatDate(row[i.data]) }}
                          </td>
                        </template>
                      </tr>
                      <tr>
                        <td class="text-start text-wrap align-middle fw-bold" colspan="3">
                          Total Loan Amount
                        </td>
                        <td class="text-start text-wrap align-middle fw-bold">
                          RM {{ formattedAmount(totalLoan) }}
                        </td>
                      </tr>
                    </template>
                  </VTable>
                </div>

                <div v-else>
                  <h5 class="text-bold">No Record Found</h5>
                </div>

                <div class="table-controls row mt-6">
                  <div class="col-md-6 mb-2">
                    <div class="d-lg-flex">
                      <p>
                        {{
                          hirerContacts.length < application.currentPage * application.pageSize
                            ? `Showing ${
                                application.currentPage * application.pageSize -
                                application.pageSize +
                                1
                              } to ${hirerContacts.length} of ${hirerContacts.length} entries`
                            : `Showing ${
                                application.currentPage * application.pageSize -
                                application.pageSize +
                                1
                              } to ${application.currentPage * application.pageSize} of ${
                                hirerContacts.length
                              } entries`
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6 mb-2 ms-auto">
                    <div class="d-lg-flex justify-content-end">
                      <VTPagination
                        :current-page="application.currentPage"
                        :boundary-links="true"
                        :total-pages="application.totalPages"
                        :max-page-links="application.maxPageLinks"
                        :hide-single-page="false"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditCompanyPicModal :company-id="company.id" @fetch-data="fetchData" />
  <contact-modal
    :contacts="hirerContacts"
    :hirer-id="company.id"
    :selected-contact-id="selectedContactId"
    :resources="resources"
    @fetch-data="fetchData"
  />
  <profile-modal :profile-data="company" />
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import {formattedAmount} from '@/assets/js/calculator'
import ContactModal from './components/ContactModal.vue'
import ProfileModal from './components/ProfileModal.vue'
import EditCompanyPicModal from './components/EditCompanyPicModal.vue'

export default {
  name: 'CompanyProfile',
  components: {
    ProfileModal,
    // tableComponent,
    ContactModal,
    EditCompanyPicModal,
},
  data() {
    return {
      fetchedPic: {},
      totalLoan: 0,
      company: {},
      hirerContacts: [],
      applicantProducts: [],
      applicantDocuments: [],
      resources: [],
      applicantDocumentTypes: [],
      selectedContactId: null,
      drship: {
        selectedKey: 'All',
        totalPages: 1,
        currentPage: 1,
        maxPageLinks: 10,
        keys: [
          { id: 1, data: 'name', name: 'Name' },
          { id: 2, data: 'email', name: 'Email' }
        ],
        filters: {
          name: {
            value: '',
            keys: ['name', 'cost']
          }
        },
        pageSize: 10
      },
      application: {
        selectedKey: 'All',
        totalPages: 1,
        currentPage: 1,
        maxPageLinks: 10,
        keys: [
          { id: 1, data: 'letterOffer', name: 'Offer Letter' },
          { id: 2, data: 'createdAt', name: 'Create Date' },
          { id: 3, data: 'hpNo', name: 'HP Number' },
          { id: 4, data: 'loanAmount', name: 'Loan Amount' }
        ],
        filters: {
          name: {
            value: '',
            keys: ['financialProgramId', 'createdAt', 'progressId']
          }
        },
        pageSize: 10
      }
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader', 'formattedDate']),
    ...mapState(['apiUrl']),
    totalExposure() {
      if (
        this.company &&
        this.company.Applicants &&
        this.company.Applicants[0] &&
        this.company.Applicants[0].ApplicantProducts
      ) {
        const totalExposure = this.company.Applicants[0].ApplicantProducts.reduce(
          (total, x) => total + Number(x.loanAmount),
          0
        )
        return 'RM ' + totalExposure.toLocaleString() // Adding 'RM' and formatting with commas
      } else {
        return 'N/A' // or any default value you prefer
      }
    }
  },
  watch: {
    ['drship.selectedKey']() {
      this.filters.name.keys = []
      if (this.selectedKey == 'All') {
        this.keys.forEach((key) => {
          this.filters.name.keys.push(key.data)
        })
      } else {
        this.filters.name.keys.push(this.selectedKey)
      }
    },
    ['applicantProducts']() {
      const totalLoan = this.applicantProducts.reduce((acc, curr) => {
        console.log(acc, curr)
        return acc + parseFloat(curr.loanAmount)
      }, 0)

      this.totalLoan = totalLoan
    }
  },
  beforeMount() {
    this.apiHeader = this.getAPIHeader()
    this.fetchData()
    this.fetchResourceData()
  },
  mounted() {},
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    formattedAmount,
    getResourceFileName(targetId) {
      const result = this.applicantDocumentTypes.find((documentType) => documentType.id == targetId)
      return result?.name
    },

    extractFileName(url) {
      const lastSlashIndex = url.lastIndexOf('/')
      if (lastSlashIndex !== -1) {
        return url.substring(lastSlashIndex + 1)
      } else {
        return url // If there is no "/", return the original string
      }
    },

    updateNewPic(newPic) {
      this.fetchedPic = newPic
    },

    deleteData() {
      console.log("This company id is",this.$route.params.id)
      if (!confirm('Are you sure you want to delete this ?')) return
      axios
        .delete(`${this.apiUrl}/hirer`, {
          data : { id : this.$route.params.id},
          headers : this.apiHeader 
        })
        .then((res) => {
          console.log('RESPONSE RECEIVED: ', res.data)
          this.$router.push({
            name: 'CompanyList',
          })
        })
        .catch((err) => {
          console.log(err)
        })      
    },

    fetchData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/hirer/details/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.company = data.Hirer
          this.hirerContacts = data.Hirer.HirerContacts
          this.applicantProducts = data.Hirer.ApplicantProducts
          // console.log(this.applicantProducts?.[0].Applicant, 'company')
          console.log("Company Data",this.company)
          console.log(this.applicantProducts?.[0]?.agreementExecution, 'last loan')
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    fetchResourceData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/resource/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.resources = data.Resources
          this.applicantDocumentTypes = this.resources.filter(
            (resource) =>
              resource.category === 'DOCUMENT_APPLICANT' ||
              resource.category === 'DOCUMENT_AGREEMENT'
          )
          this.decreaseIsFetching()
        })
    },
    setActiveTab(tab) {
      this.activeTab = tab
    },

    getDiffMonth(date) {
      return moment().diff(moment(date), 'months')
    },
    createLink() {
      this.$router.push({
        name: 'CompanyCreatePage'
      })
    },
    formatDate(dateString) {
      const formattedDate = moment(dateString).format('YYYY-MM-DD')
      return formattedDate
    }
  }
}
</script>
<style>
.collapse-container {
  cursor: pointer;
  border-radius: 20px;
}
</style>
