<template>
  <div
    id="VendorModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="VendorModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="VendorModalLabel" class="modal-title fs-5">
            {{ vendor.id ? 'Edit' : 'New' }} Vendor
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-group col-md-6 mb-2 mt-2">
            <label class="form-label">Vendor Name*</label>
            <input
              id="name"
              v-model="vendorData.name"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': error.name }"
            />
            <span class="text-danger" :class="{ 'd-none': !error.name }">Name is required</span>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="submitForm()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'VendorModal',
  props: {
    hirerId: {
      vendor: Number,
      default: null
    },
    vendor: {
      vendor: Array,
      default: () => []
    }
  },
  emits: ['fetch-data'],
  data() {
    return {
      apiHeader: {},
      vendorData: {
        name: null
      },
      error: {
        name: false
      }
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader', 'formattedDate']),
    ...mapState(['apiUrl'])
  },
  watch: {
    vendor(newData) {
      if (newData.id) {
        this.vendorData = {
          id: this.vendor.id,
          name: this.vendor.name
        }
      } else {
        this.vendorData = {
          name: null
        }
      }
    }
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
  },

  methods: {
    submitForm() {
      let validate = true
      Object.keys(this.vendorData).forEach((x) => {
        if (this.vendorData[x] == null || this.vendorData[x] == '') {
          this.error[x] = true
          validate = false
        } else {
          this.error[x] = false
        }
      })
      if (validate) {
        let action = 'post'
        if (this.vendor.id) {
          action = 'put'
        }
        axios[action](`${this.apiUrl}/vendor`, this.vendorData, {
          headers: this.apiHeader
        })
          .then((res) => {
            console.log('RESPONSE RECEIVED: ', res.data)
            this.$emit('fetch-data')
          })
          .catch((err) => {
            console.log('AXIOS ERROR: ', err)
          })
      }
    }
  }
}
</script>

<style></style>
