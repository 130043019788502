<template>
  <div
    id="EditProfileModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="EditProfileModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="EditProfileModalLabel" class="modal-title fs-5 text-capitalize">
            {{ inputLabel }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <!-- <div class="col-6">
              <label class="form-label">Condition</label>
             
            </div> -->
            <div class="col-6">
              <label class="form-label">{{ inputLabel }}</label>
              <input
                v-if="inputType == 'text'"
                v-model="valueData"
                type="text"
                class="form-control col-8"
              />
              <select
                v-if="inputType == 'selection'"
                v-model="valueData"
                class="form-control col-8"
              >
                <template v-for="selection in selections" :key="selection.value">
                  <option :value="selection.value">{{ selection.name }}</option>
                </template>
              </select>
              <VueDatePicker
                v-if="inputType == 'date'"
                v-model="valueData"
                class="mb-6"
                auto-apply
                format="dd-MM-yyyy"
                model-type="dd-MM-yyyy"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="updateProfile()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'

export default {
  name: 'EditProfileModal',
  components: {
    VueDatePicker
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    inputLabel: {
      type: String,
      default: null
    },
    inputKey: {
      type: String,
      default: null
    },
    inputType: {
      type: String,
      default: null
    },
    selections: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: null
    }
  },

  emits: ['fetch-data'],
  data() {
    return {
      apiHeader: '',
      valueData: null
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader', 'formattedDate']),
    ...mapState(['apiUrl']),
    prepaymentPartA: {
      get() {
        return this.profileToUpdate.prepayment[0]
      },
      set(value) {
        this.profileToUpdate.prepayment[0] = parseFloat(value)
      }
    },
    prepaymentPartB: {
      get() {
        return this.profileToUpdate.prepayment[1]
      },
      set(value) {
        this.profileToUpdate.prepayment[1] = parseFloat(value)
      }
    }
  },

  watch: {
    value(newData) {
      this.valueData = newData
    }
  },

  mounted() {
    this.apiHeader = this.getAPIHeader()
  },

  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    updateProfile() {
      let payload = {
        id: this.id
      }
      if (this.inputType == 'date') {
        payload[this.inputKey] = moment(this.valueData, 'DD-MM-YYYY').format('YYYY-MM-DD')
      } else {
        payload[this.inputKey] = this.valueData
      }

      axios
        .put(`${this.apiUrl}/applicantProduct/`, payload, {
          headers: this.apiHeader
        })
        .then((res) => {
          console.log('RESPONSE RECEIVED: ', res.data)
          this.$emit('fetch-data')
        })
        .catch((err) => {
          console.log('AXIOS ERROR: ', err)
        })
    }
  }
}
</script>

<style></style>
