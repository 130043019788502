import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import { isAuthenticated } from './authService.js'

//login
import Illustration from '../views/auth/signin/Illustration.vue'

//Dashboard page
import DashboardDefault from '../views/dashboard/Default.vue'

//Application
import MasterList from '../views/application/MasterList.vue'
import MasterProfile from '../views/application/MasterProfile.vue'
import ApplicationList from '../views/application/List.vue'
import NewApplication from '../views/application/NewApplication.vue'
import PendingApproval from '../views/application/PendingApproval.vue'
import ApplicationLog from '../views/application/Log.vue'

//FinancialFactor
import FinancialFactor from '../views/program/financialFactor.vue'
import FinancialFactorDetail from '../views/program/financialFactorDetail.vue'

//Collection
import CollectionList from '../views/application/collection/List.vue'
import CollectionProfile from '../views/application/collection/Profile.vue'
import CollectionOverview from '../views/application/collection/Overview.vue'

//Criteria page
import CriteriaList from '../views/program/criteria/List.vue'
import CriteriaProfile from '../views/program/criteria/Profile.vue'

//FinancialProgram
import FinancialProgram from '../views/program/financialProgram.vue'
import FinancialProgramDetail from '../views/program/financialProgramDetail.vue'

//Error page
import Error404 from '../views/auth/error/Error404.vue'
import Error500 from '../views/auth/error/Error500.vue'

//Resource page
import ResourceList from '../views/resource/List.vue'
import ResourceProfile from '../views/resource/Profile.vue'

//User page
import UserList from '../views/user/List.vue'
import UserProfile from '../views/user/Profile.vue'

//Company page
import CompanyList from '../views/company/List.vue'
import CompanyProfile from '../views/company/Profile.vue'

//Type page
import TypeList from '../views/type/List.vue'
import TypeProfile from '../views/type/Profile.vue'

//Product page
// import ProductList from "../views/product/List.vue";
import ProductProfile from '../views/product/Profile.vue'

//Vendor page
import VendorList from '../views/vendor/List.vue'
import VendorProfile from '../views/vendor/Profile.vue'

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: Illustration,
    meta: { requiresAuth: false, name: 'login' }
  },
  {
    path: '/dashboard/default',
    name: 'DashboardDefault',
    component: DashboardDefault,
    meta: { requiresAuth: true, name: 'Home' }
  },

  //Application routes
  {
    path: '/application/master',
    name: 'ApplicationMasterList',
    component: MasterList,
    meta: { requiresAuth: true, name: 'List' }
  },
  {
    path: '/application/master/profile/:id',
    name: 'MasterProfile',
    component: MasterProfile,
    meta: { requiresAuth: true, name: 'Profile' }
  },
  {
    path: '/application/log',
    name: 'ApplicationLog',
    component: ApplicationLog,
    meta: { requiresAuth: true, name: 'Log' }
  },
  {
    path: '/application',
    name: 'ApplicationList',
    component: ApplicationList,
    meta: { requiresAuth: true, name: 'List' }
  },
  {
    path: '/application/new',
    name: 'NewApplication',
    component: NewApplication,
    meta: { requiresAuth: true, name: 'New Application' }
  },
  {
    path: '/application/pending/:id',
    name: 'PendingApplication',
    component: NewApplication,
    meta: { requiresAuth: true, name: 'Pending Application' }
  },
  {
    path: '/application/pending',
    name: 'PendingApproval',
    component: PendingApproval,
    meta: { requiresAuth: true, name: 'PendingApproval' }
  },
  //collection routes
  {
    path: '/application/collection/list/:status',
    name: 'CollectionList',
    component: CollectionList,
    meta: { requiresAuth: true, name: 'Financial Collection' }
  },
  {
    path: '/application/collection/profile/:id',
    name: 'CollectionProfile',
    component: CollectionProfile,
    meta: { requiresAuth: true, name: 'Financial Collection Profile' }
  },
  {
    path: '/application/collection/overview',
    name: 'CollectionOverview',
    component: CollectionOverview,
    meta: { requiresAuth: true, name: 'Financial Collection Overview' }
  },

  // Criteria routes
  {
    path: '/program/criteria',
    name: 'CriteriaList',
    component: CriteriaList,
    meta: { requiresAuth: true, name: 'List' }
  },
  {
    path: '/program/criteria/profile/:id',
    name: 'CriteriaProfile',
    component: CriteriaProfile,
    meta: { requiresAuth: true, name: 'Profile' }
  },
  {
    path: '/program/tier',
    name: 'FinancialProgram',
    component: FinancialProgram,
    meta: { requiresAuth: true, name: 'Financial Program' }
  },
  {
    path: '/program/tier/:id',
    name: 'FinancialProgramDetail',
    component: FinancialProgramDetail,
    meta: { requiresAuth: true, name: 'Financial Program Detail' }
  },
  //factor routes
  {
    path: '/program/tier/:financialProgramId/factor',
    name: 'FinancialFactor',
    component: FinancialFactor,
    meta: { requiresAuth: true, name: 'Financial Factor' }
  },
  {
    path: '/program/tier/:financialProgramId/factor/:id',
    name: 'FinancialFactorDetail',
    component: FinancialFactorDetail,
    meta: { requiresAuth: true, name: 'Financial Factor Detail' }
  },
  //Error routes
  {
    path: '/error/error404',
    name: 'Error Error404',
    component: Error404,
    meta: { requiresAuth: false, name: 'Error404' }
  },
  {
    path: '/error/error500',
    name: 'Error Error500',
    component: Error500,
    meta: { requiresAuth: false, name: 'Error500' }
  },

  // Resource routes
  {
    path: '/resource/list',
    name: 'ResourceList',
    component: ResourceList,
    meta: { requiresAuth: true, name: 'List' }
  },
  {
    path: '/resource/profile/:category',
    name: 'ResourceProfile',
    component: ResourceProfile,
    meta: { requiresAuth: true, name: 'Profile' }
  },

  // User routes
  {
    path: '/user/list',
    name: 'UserList',
    component: UserList,
    meta: { requiresAuth: true, name: 'List' }
  },
  {
    path: '/user/profile/:id',
    name: 'UserProfile',
    component: UserProfile,
    meta: { requiresAuth: true, name: 'Profile' }
  },
  {
    path: '/user/me',
    name: 'UserMyProfile',
    component: UserProfile,
    meta: { requiresAuth: true, name: 'Profile' }
  },
  // Company routes
  {
    path: '/company/list',
    name: 'CompanyList',
    component: CompanyList,
    meta: { requiresAuth: true, name: 'List' }
  },
  {
    path: '/company/profile/:id',
    name: 'CompanyProfile',
    component: CompanyProfile,
    meta: { requiresAuth: true, name: 'Profile' }
  },
  {
    path: '/product/profile/:id',
    name: 'ProductProfile',
    component: ProductProfile,
    meta: { requiresAuth: true, name: 'Profile' }
  },
  // Type routes
  {
    path: '/type/list',
    name: 'TypeList',
    component: TypeList,
    meta: { requiresAuth: true, name: 'List' }
  },
  {
    path: '/type/profile/:id',
    name: 'TypeProfile',
    component: TypeProfile,
    meta: { requiresAuth: true, name: 'Profile' }
  },
  // Vendor routes
  {
    path: '/vendor/list',
    name: 'VendorList',
    component: VendorList,
    meta: { requiresAuth: true, name: 'List' }
  },
  {
    path: '/vendor/profile/:id',
    name: 'VendorProfile',
    component: VendorProfile,
    meta: { requiresAuth: true, name: 'Profile' }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active'
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.length == 0) {
    next('/error/error404') // Redirect to the not found URL
  }
  if (to.meta.requiresAuth && !(await isAuthenticated())) {
    await next({
      name: 'LoginPage'
    })
  } else {
    if (to.meta.name) {
      if (document.querySelector('#app').classList.contains('g-sidenav-pinned')) {
        store.commit('navbarMinimize')
      }
      store.dispatch('getName', to.meta.name)
      next()
    } else {
      next('/error/error500')
    }
  }
})

export default router
