<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'AddReason',
  props: {
    applicantProductTransactionId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      reason: null,
      exitingReasons: [],
      predefinedReasons: [
        'Insufficient funds',
        'Maintaining cash for future development',
        'Debtors unable to liquidate the payments'
      ]
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl']),
    reasonForSelect() {
      let list = [...this.exitingReasons, ...this.predefinedReasons]
      list = Array.from(new Set(list))
      return list
    }
  },
  watch: {},
  mounted() {
    this.getExistingReason()
  },

  methods: {
    addReason() {
      try {
        axios
          .put(
            `${this.apiUrl}/applicantProductTransaction/`,
            {
              id: this.applicantProductTransactionId,
              note: this.reason
            },
            { headers: this.getAPIHeader() }
          )
          .then((res) => {
            const { status, msg } = res.data
            if (status == 0) {
              alert(msg)
            } else {
              alert('Reason Added')
              this.$emit('invalidateData')
            }
          })
      } catch (error) {
        alert('An error occurred while adding reason')
        console.log(error)
      }
    },
    getExistingReason() {
      try {
        axios
          .get(`${this.apiUrl}/applicantProductTransaction/reasons`, {
            headers: this.getAPIHeader()
          })
          .then((res) => {
            const { reasons } = res.data
            if (reasons.length == 0) {
              console.log('No reason found')
            } else {
              this.exitingReasons = reasons
            }
          })
      } catch (error) {
        alert('An error occurred while fetching reason')
        console.log(error)
      }
    }
  }
}
</script>

<template>
  <div
    id="AddReasonModal"
    ref="AddReasonModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="AddReasonModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="AddReasonModal" class="modal-title fs-5">Reason For Late</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="flex-col">
              <label for="reason">Enter New Reason</label>
              <input v-model="reason" name="reason" id="reason" type="text" />
            </div>
            <div class="flex-col">
              <label for="reason">Select Existing Reason</label>
              <select v-model="reason" name="reason" id="reason" type="text">
                <option v-for="reason in reasonForSelect" :key="reason" :value="reason">
                  {{ reason }}
                </option>
              </select>
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="addReason">
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
