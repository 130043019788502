<script>
import { AgGridVue } from 'ag-grid-vue3'
import { formattedAmount } from '@/assets/js/calculator'
import { reverseDateFormatter } from '@/assets/js/dateFunction'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-material.css'
export default {
  name: 'VueTable',
  components: {
    AgGridVue
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    rowSelection: {
      type: String,
      default: 'single'
    },
    suppressRowClickSelection: {
      type: Boolean,
      default: false
    },
    keys: {
      type: Array,
      default: () => []
    },
    paginationPageSize: {
      type: Number,
      default: 20
    },
    pagination: {
      type: Boolean,
      default: true
    },
    checkbox: {
      type: Boolean,
      default: false
    },
    exportable: {
      type: Boolean,
      default: true
    },
    searchable: {
      type: Boolean,
      default: true
    }
  },
  emits: ['onSelectionChanged'],
  data() {
    return {
      filterText: null,
      columnDefs: [],
      filterValue: null,
      defaultColDef: {
        pivot: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        menuTabs: ['filterMenuTab'],
        editable: false,
        cellDataType: 'text',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true
      },
      dataTypeDefinitions: {
        date: {
          baseDataType: 'date',
          extendsDataType: 'date',
          valueFormatter: (params) => {
            if (!params.value) return ''
            const [day, month, year] = params.value.split('-')
            if ((day, month, year)) {
              return `${day}-${month}-${year}`
            }
          }
        },
        percentage: {
          extendsDataType: 'number',
          baseDataType: 'number',
          valueFormatter: (params) => {
            if (params.value == null) return ''
            return `${params.value} %`
          }
        },
        currency: {
          extendsDataType: 'number',
          baseDataType: 'number',
          valueFormatter: (params) => {
            if (params.value == null) return ''
            return `${params?.colDef?.currency ?? '$'} ${formattedAmount(params.value)}`
          }
        },
        objectRenderer: {
          baseDataType: 'object',
          extendsDataType: 'object'
        },
        installmentStatus: {
          baseDataType: 'object',
          extendsDataType: 'object',
          valueFormatter: (params) => {
            const { chargeAmount, paid, dueDate } = params.data
            const beDueDate = this.reverseDateFormatter(dueDate)

            if (paid == chargeAmount) return `Paid`
            if (paid > 0 && paid < chargeAmount) return `Partial`
            if (paid == 0) {
              if (new Date() < new Date(beDueDate)) return 'Pending'
            }
            return `Overdue`
          }
        },
        installmentPerformance: {
          baseDataType: 'object',
          extendsDataType: 'object',
          valueFormatter: (params) => {
            const { dueDate, paidAt, chargeAmount, paid } = params.data
            const beDueDate = this.reverseDateFormatter(dueDate)
            const bePaidAt = this.reverseDateFormatter(paidAt)
            // test here
            console.log(
              dueDate,
              new Date(beDueDate),
              'beDueDate',
              paidAt,
              new Date(bePaidAt),
              'bePaidAt'
            )
            console.log('test-1', new Date(bePaidAt) < new Date(beDueDate))
            console.log('test-2', paid < chargeAmount && new Date() > new Date(beDueDate))
            // If paidAt is after dueDate, then it is late OR
            // If Paid amount is less than charge amount and current date is after dueDate, then it is late
            if (
              new Date(bePaidAt) > new Date(beDueDate) ||
              (paid < chargeAmount && new Date() > new Date(beDueDate))
            ) {
              return `Late`
            } else {
              return `Normal`
            }
          }
        },
        installmentReason: {
          baseDataType: 'object',
          extendsDataType: 'object',
          valueFormatter: (params) => {
            const { performanceStatus, note } = params.data
            // Fully Paid
            // Partially Paid
            // Overdue
            // Pending
            if (note) return note
            if (performanceStatus == 'Overdue') return 'add'
          }
        },
        enumV1: {
          baseDataType: 'text',
          extendsDataType: 'text'
          //   valueFormatter: (params) => {
          //     if (params.value === 1) {
          //       return 'Yes'
          //     } else if (params.value === 0) {
          //       return 'No'
          //     } else {
          //       return 'Pending'
          //     }
          //   }
        }
      },
      gridApi: null,
      domLayout: 'autoHeight'
    }
  },
  watch: {
    keys() {
      this.columnDefs = this.keys
      if (this.columnDefs.length > 0) {
        this.columnDefs[0].headerCheckboxSelection = this.checkbox
        this.columnDefs[0].checkboxSelection = this.checkbox
        this.columnDefs[0].showDisabledCheckboxes = this.checkbox
      }
    }
  },
  mounted() {
    this.columnDefs = this.keys.map((key) => {
      const { comparator, cellRenderer } = this.getRendererAndComparator(key)

      return {
        ...key,
        // Provide custom comparator, filter-comparator for repective data type
        comparator,
        cellRenderer
      }
    })
    if (this.columnDefs.length > 0) {
      this.columnDefs[0].headerCheckboxSelection = this.checkbox
      this.columnDefs[0].checkboxSelection = this.checkbox
      this.columnDefs[0].showDisabledCheckboxes = this.checkbox
    }
  },
  methods: {
    reverseDateFormatter,
    onSelectionChanged() {
      const selectedRows = this.gridApi.getSelectedRows()
      this.$emit('onSelectionChanged', selectedRows)
    },
    onRowClicked(event) {
      const clickedRow = event.data
      this.$emit('onRowClicked', clickedRow, event)
    },
    onBtnExport() {
      this.gridApi.exportDataAsCsv()
    },
    onGridReady(params) {
      this.gridApi = params.api
    },
    onFilterTextBoxChanged() {
      this.gridApi.setGridOption(
        'quickFilterText',
        document.getElementById('filter-text-box').value
      )
    },
    onResetFilter() {
      this.gridApi.setFilterModel(null)
      this.filterText = null
      this.gridApi.setGridOption('quickFilterText', '')
    },
    getRendererAndComparator(key) {
      if (key.cellDataType === 'date') {
        return { comparator: this.dateSort, cellRenderer: null }
      }

      if (key.cellDataType === 'enumV1') {
        return {
          cellRenderer: this.renderEnumV1
        }
      }

      if (key.cellDataType === 'objectRenderer') {
        return {
          cellRenderer: this.renderPaymentStatus
        }
      }

      if (key.cellDataType === 'installmentStatus') {
        return {
          cellRenderer: this.renderInstallmentStatus
        }
      }

      if (key.cellDataType === 'installmentPerformance') {
        return {
          cellRenderer: this.renderInstallmentPerformance
        }
      }

      if (key.cellDataType == 'installmentReason') {
        return {
          cellRenderer: this.renderInstallmentReason
        }
      }

      if (key.cellDataType == 'booleanPendingPayment') {
        return {
          cellRenderer: this.renderCurrentPayment
        }
      }

      // Add more guard clauses for other data types if needed

      return { comparator: null, cellRenderer: null }
    },
    dateSort(date1, date2) {
      if (!date1 || !date2) return 0
      const [day1, month1, year1] = date1.split('-')
      const [day2, month2, year2] = date2.split('-')
      const d1 = new Date(year1, month1, day1)
      const d2 = new Date(year2, month2, day2)
      if (d1 < d2) {
        return -1
      }
      if (d1 > d2) {
        return 1
      }
      return 0
    },
    renderEnumV1(params) {
      if (params.value === 1) {
        return `<span class='badge badge-success'> Yes </span>`
      } else if (params.value === 0) {
        return `<span class='badge badge-danger'>No</span>`
      } else {
        return `<span class='badge badge-secondary'>Pending</span>`
      }
    },

    renderPaymentStatus(params) {
      const { full, partial, overdue } = params.value
      return `
      <span class="pay-full"> ${full}</span>
      /
      <span class="pay-partial">${partial}</span>
      /
      <span class="pay-overdue">${overdue} </span>
      `
    },
    renderInstallmentStatus(params) {
      if (params.valueFormatted === 'Paid') {
        return `<span class='badge badge-success'> Paid </span>`
      } else if (params.valueFormatted === 'Partial') {
        return `<span class='badge badge-warning'> Partial </span>`
      } else if (params.valueFormatted === 'Pending') {
        return `<span class='badge badge-info'> Pending </span>`
      } else {
        return `<span class='badge badge-danger'> Overdue </span>`
      }
    },
    renderInstallmentPerformance(params) {
      if (params.valueFormatted === 'Late') {
        return `<span class='badge badge-danger'> Late </span>`
      } else {
        return `<span class='badge badge-success'> Normal </span>`
      }
    },

    renderInstallmentReason(params) {
      if (params.valueFormatted === 'add') {
        return `<button class='btn btn-info' data-purpose="addReason" data-id=${params.data.id} data-toggle="modal" data-target="#AddReasonModal"> Add </button>`
      } else {
        return params.valueFormatted
      }
    },

    renderCurrentPayment(params) {
      if (params.value == false) return `<span class='badge badge-success'> Cleared</span>`
      if (params.value == true) return '<span class="badge badge-danger">Pending</span>'
      return 'N/A'
    },
    filterDate(selectedDate, dateString) {
      const [day, month, year] = dateString.split('-')
      const date = new Date(year, month - 1, day)

      if (date.getTime() < selectedDate.getTime()) {
        return -1
      }
      if (date.getTime() > selectedDate.getTime()) {
        return 1
      }
      return 0
    }
  }
}
</script>

<template>
  <div v-if="searchable" class="table-controls row">
    <div class="col-md-3 col-lg-3 mb-3 ms-auto d-flex">
      <input
        id="filter-text-box"
        v-model="filterText"
        class="form-control"
        placeholder="Type to search..."
        @input="onFilterTextBoxChanged"
      />
      <button class="btn btn-outline-warning mb-0 ms-2" @click="onResetFilter()">Reset</button>
    </div>
  </div>
  <div v-if="exportable" class="table-controls row">
    <div class="col-auto ms-auto">
      <button class="btn btn-outline-primary" @click="onBtnExport()">Export</button>
    </div>
  </div>

  <div v-if="data.length > 0" class="ag-theme-material">
    <ag-grid-vue
      :row-data="data"
      :column-defs="columnDefs"
      :dom-layout="domLayout"
      :default-col-def="defaultColDef"
      :row-selection="rowSelection"
      :pagination-page-size="paginationPageSize"
      :pagination="pagination"
      :suppress-row-click-selection="suppressRowClickSelection"
      :dataTypeDefinitions="dataTypeDefinitions"
      @grid-ready="onGridReady"
      @selection-changed="onSelectionChanged"
      @rowClicked="onRowClicked"
    ></ag-grid-vue>
  </div>
  <div v-else>No Record Found</div>
</template>
