<template>
  <div
    id="ContactModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="ContactModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="ContactModalLabel" class="modal-title fs-5">
            {{ selectedContactId ? 'Edit' : 'New' }} Directorship
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <label class="form-label">Name*</label>
              <input v-model="contactData.name" type="text" class="form-control col-8" />
            </div>
            <div class="col-6">
              <label class="form-label">Identity No*</label>
              <input v-model="contactData.identityNo" type="text" class="form-control col-8" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <label class="form-label">Phone (Optional)</label>
              <input v-model="contactData.phone" type="text" class="form-control col-8" />
            </div>
            <div class="col-6">
              <label class="form-label">Email (Optional)</label>
              <input v-model="contactData.email" type="text" class="form-control col-8" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="submitForm()"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'
// import Choices from "choices.js";

export default {
  name: 'ContactModal',
  props: {
    hirerId: {
      type: Number,
      default: null
    },
    contacts: {
      type: Array,
      default: () => []
    },
    selectedContactId: {
      type: Number,
      default: null
    },
    resources: {
      type: Array,
      default: () => []
    }
  },
  emits: ['fetch-data'],
  data() {
    return {
      apiHeader: {},
      contactData: {
        hirerId: null,
        identityNo: '',
        name: '',
        phone: '',
        email: ''
      },
      error: {
        hirerId: false,
        identityNo: false,
        name: false,
      },
      //choices
      positionChoices: {}
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader', 'formattedDate']),
    ...mapState(['apiUrl'])
  },
  watch: {
    hirerId(id) {
      this.contactData.hirerId = id
    },
    selectedContactId(id) {
      const foundContact = this.contacts.find((x) => x.id === id)
      if (foundContact) {
        this.contactData = {
          id: foundContact.id,
          hirerId: foundContact.hirerId,
          identityNo: foundContact.identityNo,
          name: foundContact.name,
          phone: foundContact.phone,
          email: foundContact.email
        }
        // this.buildResourceChoice(foundContact.resourceId);
      } else {
        this.contactData = {
          hirerId: this.hirerId,
          identityNo: null,
          name: null,
          phone: null,
          email: null
        }
      }
      console.log(this.contactData)
    },
    resources() {
      // this.buildResourceChoice(null);
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.apiHeader = this.getAPIHeader()
  },

  methods: {
    submitForm() {
      let validate = true
      Object.keys(this.error).forEach((x) => {
        if (this.contactData[x] == null || this.contactData[x] == '') {
          this.error[x] = true
          validate = false
        } else {
          this.error[x] = false
        }
      })
      console.log("Error result", this.error)
      console.log("Validate Result",validate)
      if (validate) {
        let action = 'post'
        if (this.selectedContactId) {
          action = 'put'
        }
        axios[action](`${this.apiUrl}/hirerContact/`, this.contactData, {
          headers: this.apiHeader
        })
          .then((res) => {
            console.log('RESPONSE RECEIVED: ', res.data)
            window.location.reload()
          })
          .catch((err) => {
            if (err.response.data.msg.includes('"phone')) {
              alert('Phone is not valid, it require a valid phone number')
            } else if (err.response.data.msg.includes('"email')) {
              alert('Email is not valid, it require a valid email address')
            } else {
              alert(err.response.data.msg)
            }

            console.log('AXIOS ERROR: ', err)
          })
      }
    }
    // buildResourceChoice(id) {
    //   const choices = this.resources
    //     .filter((x) => x.category == "CONTACT_POSITION")
    //     .map((x) => ({
    //       label: x.name,
    //       value: x.id,
    //       selected: x.id == id,
    //     }));
    //   console.log(choices);
    //   if (Object.keys(this.positionChoices).length > 0) {
    //     this.positionChoices.destroy();
    //   }
    //   const element = document.getElementById("position-choices");
    //   if (element) {
    //     this.positionChoices = new Choices(element, {
    //       searchEnabled: true,
    //       searchChoices: true,
    //       searchPlaceholderValue: "Search here",
    //       choices: choices,
    //     });
    //   }
    // },
  }
}
</script>

<style></style>
