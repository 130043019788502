<template>
  <div
    id="ProfileModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="ProfileModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="ProfileModalLabel" class="modal-title fs-5">Company</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <label class="form-label">Name</label>
              <input v-model="profileToUpdate.name" type="text" class="form-control col-8" />
            </div>
            <div class="col-6">
              <label class="form-label">Registration No</label>
              <input
                v-model="profileToUpdate.registrationNo"
                type="text"
                class="form-control col-8"
              />
            </div>
            <div class="col-6">
              <label class="form-label">Short Name</label>
              <input v-model="profileToUpdate.shortname" type="text" class="form-control col-8" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <label for="dropdown">Business Sector</label>
              <select id="sector-choices" v-model="profileToUpdate.resourceId" class="form-control">
                <option value="">Select Sector</option>
              </select>
              <div v-if="profileToUpdate.resourceId == 40" class="flex-col">
                <label for="">Other Business Sector (Please State)</label>
                <input
                  v-model="profileToUpdate.newResourceName"
                  placeholder="Enter Sector Name"
                  type="text"
                  class="form-control col-8"
                />
              </div>
            </div>
            <div class="col-6">
              <label class="form-label">Establish Year</label>
              <input
                v-model="profileToUpdate.establishYear"
                type="text"
                class="form-control col-8"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <label class="form-label">Email</label>
              <input v-model="profileToUpdate.email" type="text" class="form-control col-8" />
            </div>
            <div class="col-6">
              <label class="form-label">Address</label>
              <input v-model="profileToUpdate.address" type="text" class="form-control col-8" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <label class="form-label">Website</label>
              <input v-model="profileToUpdate.website" type="text" class="form-control col-8" />
            </div>
            <div class="col-6">
              <label class="form-label">Phone</label>
              <input v-model="profileToUpdate.phone" type="text" class="form-control col-8" />
            </div>
            <div class="col-6">
              <label class="form-label">Client Loan Limit</label>
              <input
                v-model="profileToUpdate.loanLimit"
                type="text"
                class="form-control col-8"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-8">
              <label class="form-label">Remark</label>
              <textarea v-model="profileToUpdate.remark" class="form-control col-8" rows="3">
              </textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="updateProfile()"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import Choices from 'choices.js'

export default {
  name: 'ProfileModal',
  components: {},
  props: {
    profileData: {
      type: Object
    }
  },
  data() {
    return {
      id: '',
      token: '',
      profileToUpdate: {
        id: '',
        resourceId: '',
        name: '',
        email: '',
        address: '',
        website: '',
        phone: '',
        remark: '',
        registrationNo: '',
        shortname: '',
        establishYear: '',
        newResourceName: ''
      },
      sectorChoices: {}
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader', 'formattedDate']),
    ...mapState(['apiUrl'])
  },

  watch: {
    profileData() {
      this.extracData()
    }
  },

  mounted() {
    this.id = this.$route.params.id
    this.token = this.getAPIHeader()
    this.fetchResourceData()
  },

  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    extracData() {
      this.profileToUpdate.id = this.profileData.id
      this.profileToUpdate.resourceId = this.profileData.resourceId
      this.profileToUpdate.name = this.profileData.name
      this.profileToUpdate.email = this.profileData.email
      this.profileToUpdate.address = this.profileData.address
      this.profileToUpdate.website = this.profileData.website
      this.profileToUpdate.phone = this.profileData.phone
      this.profileToUpdate.remark = this.profileData.remark
      this.profileToUpdate.registrationNo = this.profileData.registrationNo
      this.profileToUpdate.shortname = this.profileData.shortname
      this.profileToUpdate.establishYear = this.profileData.establishYear
    },

    fetchResourceData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/resource/all`, {
          headers: this.token
        })
        .then(({ data }) => {
          this.resources = data.Resources
          this.decreaseIsFetching()
          this.buildResourceChoice()
        })

       
    },

    getValueByLabel(dataArray, labelToFind) {
      console.log('Getting Value')
      console.log('Data Array', dataArray)
      console.log('LabelToFind', labelToFind)
      const result = dataArray.find((item) => item.label === labelToFind)
      return result ? result.value : null
    },

    buildResourceChoice() {
      const choices = this.resources
        .filter((x) => x.category == 'HIRER_BUSINESS')
        .map((x) => ({
          label: x.name,
          value: x.id
        }))
      if (Object.keys(this.sectorChoices).length > 0) {
        this.sectorChoices.destroy()
      }
      const element = document.getElementById('sector-choices')
      if (element) {
        this.sectorChoices = new Choices(element, {
          searchEnabled: true,
          searchChoices: true,
          searchPlaceholderValue: 'Search here',
          choices: choices
        })

        this.sectorChoices.setChoiceByValue(this.profileData.resourceId)
      }
    },

    updateProfile() {
      axios
        .put(`${this.apiUrl}/hirer/`, this.profileToUpdate, {
          headers: this.token
        })
        .then((res) => {
          console.log('RESPONSE RECEIVED: ', res.data)
          window.location.reload()
        })
        .catch((err) => {
          console.log('AXIOS ERROR: ', err)
        })
    }
  }
}
</script>

<style></style>
