<template>
  <div
    id="NewUserModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="NewUserModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="NewUserModalLabel" class="modal-title fs-5">New User</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-group col-md-6 mb-2 mt-2">
              <label class="form-label">Full Name*</label>
              <input
                id="name"
                v-model="userData.name"
                type="text"
                class="form-control"
                placeholder="John"
                :class="{ 'is-invalid': requiredKeys[0].invalid }"
              />
              <span class="text-danger" :class="{ 'd-none': !requiredKeys[0].invalid }">{{
                requiredKeys[0].text
              }}</span>
            </div>
            <div class="row">
              <div class="form-group col-md-6 mb-2 mt-2">
                <label class="form-label">Role*</label>
                <select
                  id="choices-role"
                  v-model="userData.roleId"
                  class="form-control"
                  name="choices-role"
                  :class="{ 'is-invalid': requiredKeys[1].invalid }"
                >
                  <option value="">Select Role</option>
                </select>
                <span class="text-danger" :class="{ 'd-none': !requiredKeys[1].invalid }">{{
                  requiredKeys[1].text
                }}</span>
              </div>
              <div class="form-group col-md-6 mb-2 mt-2">
                <label class="form-label">Department*</label>
                <select
                  id="choices-department"
                  v-model="userData.departmentId"
                  class="form-control"
                  name="choices-role"
                  :class="{ 'is-invalid': requiredKeys[2].invalid }"
                >
                  <option value="">Select Department</option>
                </select>
                <span class="text-danger" :class="{ 'd-none': !requiredKeys[2].invalid }">{{
                  requiredKeys[2].text
                }}</span>
              </div>
            </div>

            <div id="contactDiv">
              <h6 class="mt-4">Contact Info (Optional)</h6>
              <div class="row">
                <div class="form-group col-md-6 mb-2 mt-2">
                  <label class="form-label">Email</label>
                  <input
                    id="email"
                    v-model="userData.email"
                    type="email"
                    class="form-control"
                    placeholder="john@example.com"
                  />
                </div>
                <div class="form-group col-md-6 mb-2 mt-2">
                  <label class="form-label">Phone No</label>
                  <input
                    v-model="userData.phone"
                    type="text"
                    class="form-control"
                    placeholder="012 3456789"
                    @keypress="numbersonly"
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="authDiv">
            <h6 class="mt-4">Authentication</h6>
            <div class="row">
              <div class="form-group col-md-6 mb-2 mt-2">
                <label class="form-label">Username</label>
                <div class="d-flex mb-3">
                  <input
                    v-model="userAuthData.username"
                    type="text"
                    class="form-control rounded-end"
                    placeholder="username"
                    :class="[
                      requiredAuthKeys[0].validateNow
                        ? requiredAuthKeys[0].invalid
                          ? 'is-invalid'
                          : 'is-valid'
                        : ''
                    ]"
                  />
                </div>
                <span class="text-danger" :class="{ 'd-none': !requiredAuthKeys[0].invalid }">{{
                  requiredAuthKeys[0].text
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6 mb-2 mt-2">
                <label class="form-label">Password</label>
                <input
                  v-model="userAuthData.password"
                  type="password"
                  class="form-control"
                  placeholder="Abc@123456789"
                  :class="[
                    requiredAuthKeys[1].validateNow
                      ? requiredAuthKeys[1].invalid
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  ]"
                  @input="validatePassword"
                />
                <span class="text-danger" :class="{ 'd-none': !requiredAuthKeys[1].invalid }">{{
                  requiredAuthKeys[1].text
                }}</span>
                <h5 class="mt-5">Password requirements</h5>
                <p class="text-muted mb-2">Please follow this guide for a strong password:</p>
                <ul class="text-muted ps-4 mb-0 float-start">
                  <li>
                    <span class="text-sm">One special character</span>
                  </li>
                  <li>
                    <span class="text-sm">Min 8 characters</span>
                  </li>
                  <li>
                    <span class="text-sm">One capital letter</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button id="btnClose" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :class="{ disabled: submitting }"
            @click="submitUserForm"
          >
            <div v-if="submitting" class="spinner-border spinner-border-sm" role="status"></div>
            <span class="ms-1">
              {{ submitting ? 'Submitting' : 'Submit' }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Choices from 'choices.js'
import moment from 'moment'
import axios from 'axios'
import { mapState, mapMutations, mapGetters } from 'vuex'
export default {
  name: 'NewUserModal',
  props: {},
  emits: ['fetch-data'],
  data() {
    return {
      userData: {
        name: '',
        phone: '',
        email: '',
        roleId: '',
        departmentId: ''
      },
      userAuthData: {
        username: '',
        password: ''
      },
      roles: [],
      apiHeader: {},
      requiredKeys: [
        {
          name: 'name',
          text: 'Please enter full name',
          invalid: false,
          className: null
        },
        {
          name: 'roleId',
          text: 'Please select role',
          invalid: false,
          className: 'role__choice'
        },
        {
          name: 'departmentId',
          text: 'Please select department',
          invalid: false,
          className: 'department__choice'
        }
      ],
      requiredAuthKeys: [
        {
          name: 'username',
          text: 'Username is not valid',
          validateNow: false,
          invalid: false
        },
        {
          name: 'password',
          text: 'Password is invalid',
          validateNow: false,
          invalid: false
        }
      ],
      submitting: false
    }
  },
  computed: {
    ...mapState(['isLoading', 'apiUrl', 'user']),
    ...mapGetters(['getAPIHeader', 'validateValue'])
  },
  watch: {},
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.fetchRoleData()
    this.fetchDepartmentData()
    // const ids = [4, 5, 6, 7, 8];
    // ids.forEach((x) => {
    //   this.deleteUserAuthForm(x);
    // });
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    fetchRoleData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/role/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.roles = data.Roles
          let roleChoices = []
          this.roles.forEach((role) => {
            roleChoices.push({
              value: role.id,
              label: role.name
            })
          })
          if (document.getElementById('choices-role')) {
            new Choices(document.getElementById('choices-role'), {
              choices: roleChoices,
              classNames: {
                containerInner: 'choices__inner role__choice'
              }
            })
          }
          this.decreaseIsFetching()
        })
    },
    fetchDepartmentData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/department/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.departments = data.Departments
          let departmentChoices = []
          this.departments.forEach((department) => {
            departmentChoices.push({
              value: department.id,
              label: department.name
            })
          })
          if (document.getElementById('choices-department')) {
            new Choices(document.getElementById('choices-department'), {
              choices: departmentChoices,
              classNames: {
                containerInner: 'choices__inner department__choice'
              }
            })
          }
          this.decreaseIsFetching()
        })
    },
    validateUserForm() {
      let result = true
      this.requiredKeys.forEach((x, index) => {
        const value = this.userData[x.name]
        console.log(value)
        if (this.validateValue(value)) {
          result = false
          this.requiredKeys[index].invalid = true
          if (this.requiredKeys[index].className != null) {
            const classes = document.getElementsByClassName(this.requiredKeys[index].className)
            if (classes.length > 0) {
              classes[0].classList.add('border-danger')
            }
          }
        } else {
          this.requiredKeys[index].invalid = false
          if (this.requiredKeys[index].className != null) {
            const classes = document.getElementsByClassName(this.requiredKeys[index].className)
            if (classes.length > 0) {
              classes[0].classList.remove('border-danger')
            }
          }
        }
      })

      if (this.userAuthData.username == '' || this.userAuthData.username == null) {
        result = false
      }

      return result
    },
    submitUserForm() {
      if (this.validateUserForm() && this.validateUserAuthForm()) {
        this.submitting = true
        axios
          .post(
            `${this.apiUrl}/user`,
            {
              username: this.userAuthData.username,
              ...this.userData
            },
            {
              headers: this.apiHeader
            }
          )
          .then(({ data }) => {
            this.submitUserAuthForm(data.id)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    validateUserAuthForm() {
      let result = false

      if (!this.requiredAuthKeys[0].invalid && this.validatePassword()) {
        result = true
      }
      return result
    },
    submitUserAuthForm(userId) {
      axios
        .post(
          `${this.apiUrl}/userAuth`,
          {
            userId: userId,
            ...this.userAuthData
          },
          {
            headers: this.apiHeader
          }
        )
        .then(() => {
          this.submitting = false
          document.getElementById('btnClose').click()
          this.$swal.fire({
            title: 'Submitted Successfully',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
            willClose: () => {
              location.reload()
            }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    formattedDate(date) {
      return moment(date).format('DD-MM-YYYY hh:mm A')
    },
    formattedDateNoTime(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    validatePassword() {
      const capitalLetterRegex = /[A-Z]/
      const specialCharRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/
      this.requiredAuthKeys[1].invalid = false
      this.requiredAuthKeys[1].validateNow = false
      if (this.userAuthData.password.length < 8) {
        this.requiredAuthKeys[1].invalid = true
        this.requiredAuthKeys[1].validateNow = true
      } else if (!capitalLetterRegex.test(this.userAuthData.password)) {
        this.requiredAuthKeys[1].invalid = true
        this.requiredAuthKeys[1].validateNow = true
      } else if (!specialCharRegex.test(this.userAuthData.password)) {
        this.requiredAuthKeys[1].invalid = true
        this.requiredAuthKeys[1].validateNow = true
      }
      console.log(!this.requiredAuthKeys[1].invalid)
      return !this.requiredAuthKeys[1].invalid
    }
  }
}
</script>

<style></style>
