<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="dashboardCollapse"
          nav-text="Dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-chart-bar-32 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'DashboardDefault' }" mini-icon="D" text="Default" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="applicationCollapse"
          nav-text="Finance"
          :class="getRoute() === 'application' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-box-2 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item
                :to="{ name: 'ApplicationMasterList' }"
                mini-icon="L"
                text="Master List"
              />
              <sidenav-item :to="{ name: 'ApplicationList' }" mini-icon="A" text="Application" />
              <sidenav-item :to="{ name: 'PendingApproval' }" mini-icon="P" text="Pending" />
              <sidenav-item :to="{ name: 'ApplicationLog' }" mini-icon="L" text="Log" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="collectionCollapse"
          nav-text="Collection"
          :class="getRoute() === 'Collection' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-collection text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item
                :to="{ name: 'CollectionList', params: { status: 'pending' } }"
                mini-icon="C"
                text="List"
              />

              <sidenav-item :to="{ name: 'CollectionOverview' }" mini-icon="O" text="Overview" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="programCollapse"
          nav-text="Program"
          :class="getRoute() === 'program' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-building text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- <sidenav-item :to="{ name: 'FinancialFactor' }" mini-icon="L" text="Factor" /> -->
              <sidenav-item :to="{ name: 'CriteriaList' }" mini-icon="C" text="Criteria" />
              <sidenav-item :to="{ name: 'FinancialProgram' }" mini-icon="T" text="Tier" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="companyCollapse"
          nav-text="Client"
          :class="getRoute() === 'company' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-building text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'CompanyList' }" mini-icon="L" text="List" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="productCollapse"
          nav-text="Asset"
          :class="
            getRoute() === 'product' || getRoute() === 'vendor' || getRoute() === 'asset'
              ? 'active'
              : ''
          "
        >
          <template #icon>
            <i class="ni ni-basket text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'TypeList' }" mini-icon="T" text="Type" />
            </ul>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'VendorList' }" mini-icon="V" text="Vendor" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="userCollapse"
          nav-text="User"
          :class="getRoute() === 'user' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-ungroup text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'UserList' }" mini-icon="L" text="List" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="resourceCollapse"
          nav-text="Resource"
          :class="getRoute() === 'resource' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-box-2 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'ResourceList' }" mini-icon="L" text="List" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item"></li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from './SidenavItem.vue'
import SidenavCollapse from './SidenavCollapse.vue'
// import SidenavCard from "./SidenavCard.vue";
//import SidenavCollapseItem from "./SidenavCollapseItem.vue";

export default {
  name: 'SidenavList',
  components: {
    SidenavItem,
    SidenavCollapse
    // SidenavCard,
    // SidenavCollapseItem,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split('/')
      return routeArr[1]
    }
  }
}
</script>
