<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'
// import Choices from "choices.js";

export default {
  name: 'CriteriaModal',
  props: {
    criterias: {
      type: Array,
      default: () => []
    },
    selectedCriteriaId: {
      type: Number,
      default: null
    }
  },
  emits: ['fetch-data'],
  data() {
    return {
      apiHeader: {},
      resources: [],
      criteriaData: {
        category: '',
        name: '',
        description: ''
      },
      error: {
        category: false,
        name: false,
        description: false
      }
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader', 'formattedDate']),
    ...mapState(['apiUrl'])
  },
  watch: {
    selectedCriteriaId(id) {
      const foundContact = this.criterias.find((x) => x.id === id)
      console.log(foundContact, id, this.criterias)
      if (foundContact) {
        this.criteriaData = {
          id: foundContact.id,
          category: foundContact.category,
          description: foundContact.description,
          name: foundContact.name
        }
      } else {
        this.criteriaData = {
          category: '',
          description: null,
          name: null
        }
      }
      console.log(this.criteriaData)
    },
    resources() {
      // this.buildResourceChoice(null);
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.apiHeader = this.getAPIHeader()
    this.fetchResourceData(0)
  },

  methods: {
    submitForm() {
      let validate = true

      Object.keys(this.error).forEach((x) => {
        if (this.criteriaData[x] == null || this.criteriaData[x] == '') {
          this.error[x] = true
          validate = false
        } else {
          this.error[x] = false
        }
      })
      if (validate) {
        let action = 'post'
        if (this.selectedCriteriaId) {
          action = 'put'
        }
        axios[action](`${this.apiUrl}/criteria`, this.criteriaData, {
          headers: this.apiHeader
        })
          .then((res) => {
            console.log('RESPONSE RECEIVED: ', res.data)
            window.location.reload()
          })
          .catch((err) => {
            console.log('AXIOS ERROR: ', err)
          })
      }
    },
    deleteCriteria() {
      axios
        .delete(`${this.apiUrl}/criteria`, {
          data: {
            id: this.selectedCriteriaId
          },
          headers: this.apiHeader
        })
        .then((res) => {
          console.log('RESPONSE RECEIVED: ', res.data)
          window.location.reload()
        })
        .catch((err) => {
          console.log('AXIOS ERROR: ', err)
        })
    },

    fetchResourceData() {
      axios
        .get(`${this.apiUrl}/resource/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.resources = data.Resources.filter((x) => x.category == 'CRITERIA_CATEGORY')
        })
    }
  }
}
</script>

<template>
  <div
    id="CriteriaModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="CriteriaModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="CriteriaModalLabel" class="modal-title fs-5">
            {{ selectedCriteriaId ? 'Edit' : 'New' }} Criteria
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <label class="form-label">Category</label>

              <select id="dropdown" v-model="criteriaData.category" class="form-select ml-2">
                <option value="">Select Category</option>
                <option v-for="resource in resources" :key="resource.id" :value="resource.name">
                  {{ resource.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label class="form-label">Name</label>
              <input v-model="criteriaData.name" type="text" class="form-control col-8" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <label class="form-label">Description</label>
              <textarea v-model="criteriaData.description" class="form-control col-8" rows="3">
              </textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="flex-row wrapper">
            <div v-if="selectedCriteriaId">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
                @click="deleteCriteria()"
              >
                Delete
              </button>
            </div>
            <div v-else>
              
            </div>
            <div class="flex-row gap-s">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                @click="submitForm()"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wrapper {
  width: 100%;
}

.gap-s {
  gap: 1rem;
}
</style>
