<script>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
// import { useRouter } from 'vue-router'

export default {
  name: 'AddProgram',
  props: {},
  setup() {
    // const router = useRouter()
    const store = useStore()

    const programName = ref('')
    const maxCapped = ref('')
    // Defaulting Program Tenure as it does not affect user selection, the Program Tier will be used instead to determine the tenure
    const maxTenure = ref(5)
    const percentageOnNew = ref('')
    const percentageOnUsed = ref('')

    const increaseIsFetching = () => {
      store.commit('increaseIsFetching')
    }

    const decreaseIsFetching = () => {
      store.commit('decreaseIsFetching')
    }

    function submitForm() {
      if (
        programName.value == '' ||
        maxCapped.value == '' ||
        percentageOnNew.value == '' ||
        percentageOnUsed.value == ''
      ) {
        alert('Please fill in all fields')
        return
      }

      if (
        percentageOnNew.value > 100 ||
        percentageOnNew.value <= 0 ||
        percentageOnUsed.value > 100 ||
        percentageOnUsed.value <= 0
      ) {
        alert('Percentage of Loan Amount must be between 1 and 100')
        return
      }
      // updateAllPrograms.call(this)
      addNewFactor.call(this)
    }

    async function addNewFactor() {
      try {
        increaseIsFetching()
        const response = await axios.post(
          `${apiUrl.value}/financialProgram`,
          {
            name: programName.value,
            maxCapped: maxCapped.value,
            maxTenure: maxTenure.value,
            percentageOnNew: percentageOnNew.value,
            percentageOnUsed: percentageOnUsed.value
          },
          {
            headers: getAPIHeader.value()
          }
        )
        decreaseIsFetching()
        window.location.reload()

        // this.$emit('fetch-data');
        return response.data
      } catch (error) {
        console.log(error)
      }
    }

    const getAPIHeader = computed(() => {
      return store.getters.getAPIHeader
    })

    const apiUrl = computed(() => {
      return store.state.apiUrl
    })

    onMounted(() => {})

    return {
      programName,
      maxCapped,
      percentageOnNew,
      percentageOnUsed,
      submitForm,
      getAPIHeader,
      apiUrl
    }
  }
}
</script>

<template>
  <div
    id="addProgramModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="addProgramModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="addProgramModalLabel" class="modal-title fs-5">Create New Program</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-group col-md-6 mb-2 mt-2">
            <label class="form-label">Program Name</label>
            <input id="name" v-model="programName" type="text" class="form-control" />

            <label class="form-label">Maximum Loan Amount</label>
            <input id="name" v-model="maxCapped" type="number" class="form-control" />

            <label class="form-label">Maximum Loan % (New)*</label>
            <input
              id="name"
              v-model="percentageOnNew"
              type="number"
              class="form-control"
              max="100"
            />

            <label class="form-label">Maximum Loan % (Pre-Owned)*</label>
            <input
              id="name"
              v-model="percentageOnUsed"
              type="number"
              class="form-control"
              max="100"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="submitForm">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
