<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ToHold',
  props: {
    lastPayment: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      amount: 0,
      date: '',
      lastPaidAt: ''
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl'])
  },
  watch: {
    lastPayment() {
      const [day, month, year] = this.lastPayment.paidAt.split('-')
      this.lastPaidAt = `${year}-${month}-${day}`
    }
  },
  mounted() {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // Adding zero-padding if needed
    const day = String(today.getDate()).padStart(2, '0') // Adding zero-padding if needed
    this.date = `${year}-${month}-${day}`
  },

  methods: {
    checkDateYMD(date) {
      // Regex for date format YYYY-MM-DD, where YYYY is between 1979 and 2099 and MM and DD are valid months and days
      const dateRegex = /^(19[789]\d|20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
      return dateRegex.test(date)
    },
    makePayment() {
      if (this.amount <= 0) {
        alert('Amount must be greater than 0')
        return
      }

      if (!this.checkDateYMD(this.date)) {
        alert('Invalid date format')
        return
      }

      if (new Date(this.date) < new Date(this.lastPaidAt)) {
        alert('Payment date must be later than the last payment date')
        return
      }

      try {
        axios
          .post(
            `${this.apiUrl}/applicantProductPayment`,
            {
              applicantProductId: this.$route.params.id,
              amount: this.amount,
              paidAt: this.date
            },
            { headers: this.getAPIHeader() }
          )
          .then((res) => {
            const { status, msg } = res.data
            if (status == 0) {
              alert(msg)
            } else {
              alert('New payment successfully made')
              this.$emit('invalidateData')
            }
          })
      } catch (error) {
        alert('An error occurred while making payment')
        console.log(error)
      }
    }
  }
}
</script>

\
<template>
  <div id="payModal" class="modal fade" tabindex="-1" aria-labelledby="payModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="payModal" class="modal-title fs-5">Make New Payment</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="flex-col">
              <label for="amount">Amount to Pay</label>
              <input v-model="amount" name="amount" id="amount" type="number" />
            </div>

            <div class="flex-col">
              <label for="date">Date of payment (YYYY-MM-DD)</label>
              <input v-model="date" name="date" id="date" type="text" placeholder="{YYYY-MM-DD}" />
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="makePayment"
          >
            Pay (TBI)
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
