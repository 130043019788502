<script>
import moment from 'moment'
import setTooltip from '@/assets/js/tooltip.js'
import { mapState, mapMutations, mapGetters } from 'vuex'
import EditProductModal from './components/EditProductModal.vue'
import EditProductPicModal from './components/EditProductPicModal.vue'
import AgGrid from '@/components/custom/AgGrid'

import axios from 'axios'
export default {
  name: 'ProductProfile',
  components: {
    EditProductModal,
    EditProductPicModal,
    AgGrid
  },
  data() {
    return {
      id: '',
      product: {},
      brand: {},
      applications: [],
      applicationKey: [
        {
          field: 'name',
          headerName: 'Asset Name'
        },
        {
          field: 'hpNo',
          headerName: 'HP No'
        },
        { field: 'letterOffer', headerName: 'Letter Offer' },
        {
          field: 'cost',
          headerName: 'Cost',
          cellDataType: 'currency',
          currency: 'RM'
        },
        {
          field: 'chasis',
          headerName: 'Chasis'
        },
        {
          field: 'engine',
          headerName: 'Engine'
        },
        {
          field: 'registrationNo',
          headerName: 'Registration No'
        },
        {
          field: 'condition',
          headerName: 'Condition'
        },
        {
          field: 'hirerName',
          headerName: 'Company'
        },
        {
          field: 'loanAmount',
          headerName: 'Loan Amount',
          cellDataType: 'currency',
          currency: 'RM'
        }
      ],
      dummyPic: require('@/assets/img/wheelLoader.jpg'),
      apiHeader: {},
      selectedImage: null
    }
  },
  computed: {
    ...mapState(['isLoading', 'apiUrl']),
    ...mapGetters(['validateValue', 'readFileAsBase64', 'getAPIHeader'])
  },
  watch: {},
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.id = this.$route.params.id
    this.fetchData()
    setTooltip(this.$store.state.bootstrap)
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),

    formattedAmount(amount) {
      if (isNaN(amount)) {
        return amount
      }

      if (amount != null) {
        const parts = amount.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        return parts.join('.')
      }

      return amount
    },

    fetchData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/product/details/${this.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.product = data.Product
          this.brand = data.Product.Brand
          this.applications = data.Product.ApplicantProducts.map((x) => {
            return {
              ...x,
              ...this.product,
              hirerName: x.Hirer?.name,
              condition: x.isNew ? 'New' : 'Used'
            }
          })
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    removePic() {
      this.dummyPic = null
    },
    updateNewPicUrl(newUrl) {
      this.dummyPic = newUrl
    },
    getDiffMonth(date) {
      return moment().diff(moment(date), 'months')
    },

    formattedDate(dateString) {
      const formattedDate = moment(dateString).format('YYYY-MM-DD HH:mm')
      return formattedDate
    },
    openFilePicker() {
      const fileInput = this.$refs.ImageInput
      fileInput.click()
    },
    handleFileChange(event) {
      const fileInput = event.target
      if (fileInput.files.length > 0) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.selectedImage = e.target.result
        }
        reader.readAsDataURL(fileInput.files[0])
      } else {
        this.selectedImage = null
      }
    }
  }
}
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="d-flex justify-content-between align-items-start"></div>

    <div class="row mb-3">
      <div class="col-4">
        <div class="card shadow">
          <div class="m-3">
            <div class="d-flex">
              <h5>Asset Image</h5>
              <div class="mt-2 ms-auto">
                <button
                  class="btn btn-primary mx-1"
                  data-bs-toggle="modal"
                  data-bs-target="#EditProductPicModal"
                >
                  Edit
                </button>
              </div>
            </div>
            <div class="border rounded-1 shadow">
              <img class="img-fluid" :src="product.url" style="max-width: 100%" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="card">
          <div class="card-header d-flex">
            <h5>Asset Information</h5>
            <div class="ms-auto">
              <button
                type="button"
                class="btn btn0-sm btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#EditProductModal"
              >
                Edit
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="m-3">
              <div class="row">
                <div class="col-md-6">
                  <label for="inputProductName" class="form-label">Type Name</label>
                  <p>{{ this.brand.name }}</p>
                </div>
                <div class="col-md-6">
                  <label for="inputWeight" class="form-label">Model</label>
                  <p>{{ this.product.name }}</p>
                </div>
                <!-- <div class="col-4">
                  <label for="inputCollection" class="form-label">Condition</label>
                  <p>{{ this.product.isNew  === 1 ? 'New' : 'Used'  }}</p>              
                </div> -->
                <div class="col-4">
                  <label for="inputPrice" class="form-label">Price</label>
                  <p>RM {{ formattedAmount(this.product.cost) }}</p>
                </div>
                <!-- <div class="col-4">
                  <label for="inputQuantity" class="form-label">Quantity</label>
                  <input type="text" class="form-control" id="inputQuantity">
                </div>
                <div class="col-md-6">
                  <label for="inputDescription" class="form-label">Description (optional)</label>
                  <input type="text" class="form-control" id="inputDescription">
                </div>
                <div class="col-md-6">
                  <label for="inputCategory" class="form-label">Category</label>
                  <select id="inputCategory" class="form-select" aria-label="Default select example">
                    <option selected disabled>Category</option>
                    <option value="1">Fork Loader</option>
                    <option value="2">Underground Loader</option>
                    <option value="3">Wheel Dozer</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="inputColor" class="form-label">Color</label>
                  <select id="inputColor" class="form-select" aria-label="Default select example">
                    <option selected disabled>Color</option>
                    <option value="1">Black</option>
                    <option value="2">Yellow</option>
                    <option value="3">Orange</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="inputPhoneNumber" class="form-label">Phone Number</label>
                  <input type="text" class="form-control" id="inputPhoneNumber">
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="applicationDiv">
      <div
        class="collapse-container fw-bold fs-6 shadow-lg border border-1 border-primary bg-white w-100 px-4 py-2 my-3"
        data-bs-toggle="collapse"
        data-bs-target="#collapseApplication"
        aria-expanded="false"
        aria-controls="collapseApplication"
      >
        <span class="text-primary">Applications</span>
      </div>
      <div id="collapseApplication" class="collapse show">
        <div class="card">
          <div class="px-0 pb-0 card-body">
            <div class="px-4 mt-3">
              <div v-if="applications.length > 0" class="table-responsive">
                <!-- replace table here -->
                <AgGrid
                  :data="applications"
                  :keys="applicationKey"
                  :searchable="false"
                  :exportable="false"
                  @fetch-data="fetchData"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditProductModal :profileData="product" />
  <EditProductPicModal :product-id="product.id" @fetch-data="fetchData" />
</template>
