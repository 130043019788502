<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="table-controls row">
              <div class="col-md-4 col-lg-2 mb-2">
                <div class="d-lg-flex">
                  <div>
                    <h5 class="pb-1">Company</h5>
                    <p>All companies</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 mb-2 ms-auto">
                <div class="d-lg-flex">
                  <div class="my-auto mt-4 ms-auto mt-lg-0">
                    <div class="my-auto ms-auto">
                      <button
                        class="btn btn-submain text-white"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#AddNewCompanyModal"
                      >
                        +&nbsp; Create New
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-controls row">
              <div class="col-md-9 col-lg-9 mb-3"></div>
              <div class="col-md-3 col-lg-3 mb-3">
                <input
                  id="search"
                  v-model="filters.name.value"
                  class="form-control"
                  placeholder="Type to search..."
                />
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="px-4 mt-3">
              <div v-if="hirers.length > 0" class="table-responsive">
                <VTable
                  v-model:currentPage="currentPage"
                  :data="hirers"
                  :page-size="pageSize"
                  :filters="filters"
                  class="table"
                  @total-pages-changed="totalPages = $event"
                >
                  <template #head>
                    <tr class="tableHeader">
                      <VTh
                        class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                        sort-key="name"
                      >
                        Name
                      </VTh>
                      <VTh
                        class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                        sort-key="category"
                      >
                        Business Sector
                      </VTh>
                      <VTh
                        class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                        sort-key="establishYear"
                      >
                        Establish Year
                      </VTh>
                    </tr>
                  </template>
                  <template #body="{ rows }">
                    <tr
                      v-for="row in rows"
                      :key="row.id"
                      class="clickable"
                      @click="($event) => toLink(row.id)"
                    >
                      <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                        {{ row.name }}
                      </td>
                      <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                        {{ row.Resource?.name ?? 'N/A' }}
                      </td>
                      <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                        {{ row.establishYear }}
                      </td>
                    </tr>
                  </template>
                </VTable>
              </div>

              <div v-else>
                <h5 class="text-bold">No Record Found</h5>
              </div>

              <div class="table-controls row mt-6">
                <div class="col-md-6 col-lg-2 mb-2">
                  <div class="d-lg-flex">
                    <p>
                      {{
                        hirers.length < currentPage * pageSize
                          ? `Showing ${currentPage * pageSize - pageSize + 1} to ${
                              hirers.length
                            } of ${hirers.length} entries`
                          : `Showing ${currentPage * pageSize - pageSize + 1} to ${
                              currentPage * pageSize
                            } of ${hirers.length} entries`
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-2 ms-auto">
                  <div class="d-lg-flex justify-content-end">
                    <VTPagination
                      v-model:currentPage="currentPage"
                      :boundary-links="true"
                      :total-pages="totalPages"
                      :max-page-links="maxPageLinks"
                      :hide-single-page="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <add-new-company-modal @fetch-data="fetchData" />
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import AddNewCompanyModal from './components/AddNewCompanyModal.vue'

export default {
  name: 'ResourceList',
  components: {
    AddNewCompanyModal
  },
  data() {
    return {
      totalPages: 1,
      currentPage: 1,
      maxPageLinks: 10,
      hirers: [],
      selectedKey: 'All',
      apiHeader: {},
      pageSize: 10,
      filters: {
        name: {
          value: '',
          keys: ['name', 'cost']
        }
      }
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader', 'formattedDate']),
    ...mapState(['apiUrl'])
  },

  watch: {
    ['selectedKey']() {
      this.filters.name.keys = []
      if (this.selectedKey == 'All') {
        this.keys.forEach((key) => {
          this.filters.name.keys.push(key.data)
        })
      } else {
        this.filters.name.keys.push(this.selectedKey)
      }
    }
  },

  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.fetchData()
    console.log('print hirer', this.hirers)
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    fetchData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/hirer/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.hirers = data.Hirers
          this.decreaseIsFetching()
          console.log('print data', this.hirers[0])
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    formattedAmount(amount) {
      if (isNaN(amount)) {
        return amount
      }
      const parts = amount.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    },
    toLink(id) {
      this.$router.push({
        name: 'CompanyProfile',
        params: { id: id }
      })
    }
  }
}
</script>

<style>
.clickable {
  cursor: pointer;
  transition: 0.1s;
}
.clickable:hover {
  background-color: #aaaaaa;
}
</style>
