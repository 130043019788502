<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import ToHold from './components/ToHold.vue'
import ToPay from './components/ToPay.vue'
import EditLastPay from './components/EditLastPay.vue'
import AddReason from './components/AddReason.vue'

import AgGrid from '@/components/custom/AgGrid.vue'
import { formattedAmount } from '@/assets/js/calculator'
import { formattedDate, getDateDiff, reverseDateFormatter } from '@/assets/js/dateFunction'

export default {
  name: 'CollectionProfile',
  components: {
    // TransactionDiv
    AgGrid,
    ToHold,
    ToPay,
    EditLastPay,
    AddReason
  },
  data() {
    return {
      clickedTransactionId: null,
      prepaymentEnum: {
        zero: '0 + 0',
        one: '1 + 0',
        two: '1 + 1',
        three: '1 + 2'
      },
      applicationProduct: {},
      allInstallments: [],
      settledInstallments: [],
      paymentHistory: [],
      paymentHistoryKey: [
        {
          field: 'paidAt',
          headerName: 'Paid At',
          filter: 'agDateColumnFilter',
          cellDataType: 'date'
        },
        { field: 'amount', headerName: 'Amount', cellDataType: 'currency', currency: 'RM' },
        { field: 'CreatedUser.name', headerName: 'Created By' },
        { field: 'UpdatedUser.name', headerName: 'Updated By' }
        // {
        //   field: 'UpdatedUser.name',
        //   headerName: 'Updated At',
        //   filter: 'agDateColumnFilter',
        //   cellDataType: 'date'
        // }
      ],
      installmentsKey: [
        { field: 'description', fieldName: 'Description' },
        { field: 'dueDate', fieldName: 'Due Date' },
        {
          field: 'chargeAmount',
          fieldName: 'Charge Amount',
          cellDataType: 'currency',
          currency: 'RM'
        },
        { field: 'paid', fieldName: 'Paid', cellDataType: 'currency', currency: 'RM' },
        { field: 'paidAt', fieldName: 'Paid At' },
        { field: 'Status', fieldName: 'Status', cellDataType: 'installmentStatus' },
        { field: 'Performance', fieldName: 'Performance', cellDataType: 'installmentPerformance' },
        { field: 'Reason', fieldName: 'Reason', cellDataType: 'installmentReason' }
      ]
    }
  },

  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl']),
    prepaymentPlan() {
      if (this.applicationProduct?.prepayment == null) return 'N/A'
      return this.getPrepaymentPlan(this.applicationProduct?.prepayment)
    },
    prepaymentInstallments() {
      if (this.applicationProduct?.prepayment == null) return 'N/A'
      let arr
      const enumKey = Object.keys(this.prepaymentEnum)
      let prepaymentKey

      enumKey.forEach((key) => {
        if (this.prepaymentEnum[key] == this.prepaymentPlan) {
          prepaymentKey = key
        }
      })

      if (prepaymentKey == 'zero') {
        arr = this.calPrepaymentInstallments('zero')
      }

      if (prepaymentKey == 'one') {
        arr = this.calPrepaymentInstallments('one')
      }

      if (prepaymentKey == 'two') {
        arr = this.calPrepaymentInstallments('two')
      }

      if (prepaymentKey == 'three') {
        arr = this.calPrepaymentInstallments('three')
      }
      return arr
    },
    onHoldSince() {
      if (this.applicationProduct?.ApplicantProductReschedules == null) return
      if (this.applicationProduct.ApplicantProductReschedules.length == 0) return 'N/A'
      return this.applicationProduct.ApplicantProductReschedules[0]?.createdAt
    },
    onHoldRemaining() {
      if (this.applicationProduct?.ApplicantProductReschedules == null) return
      if (this.applicationProduct.ApplicantProductReschedules.length == 0) return 0
      return this.applicationProduct.ApplicantProductReschedules[0]?.month
    }
  },

  watch: {
    prepaymentInstallments() {
      if (this.applicationProduct == null) return 'N/A'
      if (this.paymentHistory.length == 0) return 'N/A'

      const paymentHistory = structuredClone(this.paymentHistory).reverse()
      let totalPaid = this.applicationProduct.totalPaid

      let assumedPaidMonths
      assumedPaidMonths = Math.ceil(totalPaid / this.applicationProduct.monthlyInstallment)

      const postDueInstallments = this.allInstallments.filter((installment) => {
        return (
          new Date(reverseDateFormatter(installment.dueDate)) <= new Date() ||
          installment.dueDate == null
        )
      })

      assumedPaidMonths = Math.max(assumedPaidMonths, postDueInstallments.length)

      if (totalPaid <= 0) return

      for (let i = 0; i < assumedPaidMonths; i++) {
        let installment = this.allInstallments[i]
        let remainingPayment = installment.chargeAmount // Initialize remaining payment for the current installment

        for (const payment of paymentHistory) {
          if (payment.amount <= 0) {
            continue // Skip payments with zero amount
          }

          const difference = payment.amount - remainingPayment

          if (difference >= 0) {
            // Payment can cover the remaining installment charge
            payment.amount -= remainingPayment // Deduct remainingPayment from the payment
            installment.paid = installment.chargeAmount // Mark the installment as fully paid
            installment.paidAt = payment.paidAt // Assign the paidAt from payment to installment
            remainingPayment = 0 // No remaining payment for this installment
            break // Exit the loop as the installment is fully paid
          } else {
            // Payment is less than remaining installment charge
            remainingPayment -= payment.amount // Deduct payment amount from remaining installment charge
            installment.paid = payment.amount // Mark the installment as partially paid
            installment.paidAt = payment.paidAt // Assign the paidAt from payment to installment
            payment.amount = 0 // Fully consume this payment
          }
        }

        if (remainingPayment > 0) {
          // If there's still remaining payment after iterating through all payments
          installment.paid = installment.chargeAmount - remainingPayment // Mark the installment as partially paid
          // Since there may not be a payment for this installment, we can't assign paidAt
        }
        this.settledInstallments.unshift(installment)
      }
    }
  },
  created() {
    this.apiHeader = this.getAPIHeader()
    this.fetchData()
  },
  // beforeMount() {

  // },
  mounted() {},
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    formattedDate,
    formattedAmount,
    reverseDateFormatter,

    installmentsTableClicked(row, e) {
      // console.log(e.event.srcElement)
      const ele = e.event.srcElement
      if (ele.dataset.purpose == 'addReason') {
        const myModal = new this.$store.state.bootstrap.Modal('#AddReasonModal', {
          keyboard: false
        })
        this.clickedTransactionId = ele.dataset.id
        myModal.show()
      }

      // console.log(e.event.srcElement)
      // console.log(this.$refs.AddReasonModal, document.querySelector('#AddReasonModal'))
      // document.querySelector('#AddReasonModal').modal('show')
    },

    getResourceFileName(targetId) {
      const result = this.applicantDocumentTypes.find((documentType) => documentType.id == targetId)
      return result?.name
    },
    getResourceName(id) {
      if (!id) {
        return null
      }
      return this.resources.find((x) => x.id == id)?.name
    },

    async fetchData() {
      this.increaseIsFetching()
      try {
        const { data } = await axios.get(
          `${this.apiUrl}/applicantProduct/details/${this.$route.params.id}`,
          {
            headers: this.apiHeader
          }
        )
        this.applicationProduct = data.ApplicantProduct
        this.allInstallments = data.ApplicantProduct.ApplicantProductTransactions.map((x) => ({
          ...x,
          dueDate: formattedDate(x.dueDate)
        }))
        this.paymentHistory = this.applicationProduct?.ApplicantProductPayments.map((x) => ({
          ...x,
          paidAt: formattedDate(x.paidAt)
        }))

        this.decreaseIsFetching()
      } catch (error) {
        console.log(error)
        this.decreaseIsFetching()
      }
    },
    getpaymentStatus(endDate, startDate) {
      if (startDate) {
        const dayDiffs = getDateDiff(endDate, startDate)
        if (dayDiffs) {
          return dayDiffs > 7 ? 'Prompt' : dayDiffs < -7 ? 'Late' : 'Normal'
        } else {
          return 'Normal'
        }
      } else {
        return 'Pending'
      }
    },
    getPrepaymentPlan(prepayment) {
      const arr = JSON.parse(prepayment)
      let prepaymentKey
      // const

      if (arr.length == 0) {
        prepaymentKey = 'zero'
      }

      // 0 + 0
      if (arr[0] == 0 && arr[1] == 0) {
        prepaymentKey = 'zero'
      }

      // 1 + 0
      if (arr[0] == 1 && arr[1] == 0) {
        prepaymentKey = 'one'
      }

      // 0 + 1
      if (arr[0] == 0 && arr[1] == 1) {
        prepaymentKey = 'one'
      }

      // 1 + 1
      if (arr[0] == 1 && arr[1] == 1) {
        prepaymentKey = 'two'
      }

      // 0 + 2
      if (arr[0] == 0 && arr[1] == 2) {
        prepaymentKey = 'two'
      }

      // 1 + 2
      if (arr[0] == 1 && arr[1] == 2) {
        prepaymentKey = 'three'
      }

      return this.prepaymentEnum[prepaymentKey]
    },
    calPrepaymentInstallments(type) {
      let ppMonths
      let arr = []
      if (type == 'zero') ppMonths = 0
      if (type == 'one') ppMonths = 1
      if (type == 'two') ppMonths = 2
      if (type == 'three') ppMonths = 3
      const monthlyInstallment = this.applicationProduct.monthlyInstallment

      for (let i = 0; i < ppMonths; i++) {
        arr.push({
          desc: 'Pre-Payment',
          amount: monthlyInstallment,
          paid: false,
          paidAt: null,
          status: null,
          performance: null
        })
      }
      return arr
    },

    triggerTest(params) {
      return params
    }
  }
}
</script>
<template>
  <div class="py-4 container-fluid pt-0">
    <div class="card">
      <div class="card-header d-flex">
        <h5>
          {{ applicationProduct.hpNo }}

          <span v-if="applicationProduct.outstandingBalance == 0" class="badge badge-success"
            >Settled</span
          >
          <span v-else-if="onHoldRemaining > 0" class="badge badge-warning">Rescheduled</span>
          <span v-else class="badge badge-info">Ongoing</span>
        </h5>
      </div>
      <div class="card-body">
        <div v-if="applicationProduct.Hirer" id="customerInfo" class="grid-auto">
          <div class="wrapper">
            <h6 class="w-50">Customer</h6>
            <router-link
              :to="{
                name: 'CompanyProfile',
                params: { id: applicationProduct.Hirer.id }
              }"
              >{{ applicationProduct?.Hirer?.name ?? 'N/A' }}
              <p class="text-secondary">
                ( {{ applicationProduct.Hirer?.registrationNo ?? 'N/A' }} )
              </p></router-link
            >
          </div>

          <!-- <div class="wrapper">
            <h6 class="w-50">Agreement No.</h6>
            <p>{{ applicationProduct.hpNo ?? 'N/A' }}</p>
          </div> -->

          <div class="wrapper">
            <h6>Total Amount Payable</h6>
            <p>RM {{ formattedAmount(applicationProduct.totalAmountPayable) ?? 'N/A' }}</p>
          </div>

          <div class="wrapper">
            <h6>Loan Amount</h6>
            <p>RM {{ formattedAmount(applicationProduct.loanAmount) ?? 'N/A' }}</p>
          </div>

          <div class="wrapper">
            <h6>Rate</h6>
            <p>{{ applicationProduct.interestRate ?? 'N/A' }} %</p>
          </div>

          <div class="wrapper">
            <h6>Installment</h6>
            <p>RM {{ formattedAmount(applicationProduct.monthlyInstallment) ?? 'N/A' }}</p>
          </div>

          <div class="wrapper">
            <h6>Outstanding Balance</h6>
            <p>RM {{ formattedAmount(applicationProduct.outstandingBalance) ?? 'N/A' }}</p>
          </div>

          <div class="wrapper">
            <h6>Paid Amount</h6>
            <p>RM {{ formattedAmount(applicationProduct.totalPaid) ?? 'N/A' }}</p>
          </div>

          <div class="wrapper">
            <h6>Pre-Payment Type</h6>
            <p>{{ prepaymentPlan ?? 'N/A' }}</p>
          </div>

          <div class="wrapper">
            <h6>On Hold (Months Remaining)</h6>
            <p>{{ onHoldRemaining }}</p>
          </div>

          <div class="wrapper">
            <h6>On Hold Since (Date)</h6>
            <p>{{ onHoldSince }}</p>
          </div>

          <!-- {{ prepaymentInstallments }} -->
        </div>
      </div>
      <div class="card-footer">
        <div class="flex-row flex-end">
          <button
            class="btn btn-submain text-white"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#holdModal"
            :disabled="onHoldRemaining > 0"
          >
            Reschedule
          </button>
        </div>

        <ToHold @invalidateData="fetchData" />
      </div>
    </div>

    <div class="page-main-content" style="margin-top: 3rem">
      <div>
        <h5>Installments</h5>

        <div class="flex-row space-between">
          <button
            class="btn btn-submain text-white"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#payModal"
          >
            Make Payment
          </button>
          <button
            class="btn btn-submain text-white"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#editLastPayment"
            :disabled="paymentHistory.length == 0"
          >
            Edit Latest Payment
          </button>
        </div>
        <ToPay :lastPayment="paymentHistory[0]" @invalidateData="fetchData" />
        <EditLastPay :lastPayment="paymentHistory[0]" @invalidateData="fetchData" />
        <AddReason :applicantProductTransactionId="clickedTransactionId" />
        <AgGrid
          :data="settledInstallments"
          :keys="installmentsKey"
          :searchable="false"
          :exportable="false"
          @onRowClicked="installmentsTableClicked"
        ></AgGrid>
      </div>
    </div>

    <div style="margin-top: 3rem">
      <h5>Transaction Records</h5>

      <AgGrid
        :data="paymentHistory"
        :keys="paymentHistoryKey"
        :searchable="false"
        :exportable="false"
        @onRowClicked="triggerTest"
      ></AgGrid>
    </div>
  </div>
</template>

<style>
.collapse-container {
  cursor: pointer;
  border-radius: 20px;
}
.grid-auto {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  gap: 3rem;
}

.grid-auto > .wrapper {
  width: fit-content;
}

.grid-auto > .wrapper > h6 {
  white-space: nowrap;
  display: block;
}

.flex-end {
  justify-content: flex-end;
}
</style>
