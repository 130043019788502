<script>
import { computed, onMounted, ref, watch, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'
import { formattedAmount } from '@/assets/js/calculator'

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const editable = ref(true)
    const disableInput = ref(true)
    const newDetails = ref([reactive({})])
    const programData = ref([])
    const toDeleteIds = ref([])

    const increaseIsFetching = () => {
      store.commit('increaseIsFetching')
    }

    const decreaseIsFetching = () => {
      store.commit('decreaseIsFetching')
    }

    // function undoFormatCurrency(value) {
    //   // Remove commas
    //   let result = value.replace(/,/g, '')

    //   // Remove ".00" at the end
    //   result = result.replace(/\.00$/, '')

    //   // Remove any string prefixes
    //   result = result.replace(/^\D*/, '') // Removes non-numeric characters at the beginning

    //   return result
    // }

    function formatCurrency(value) {
      return formattedAmount(value)
    }

    function submitForm() {
      // check if any of the score is in range of 0-10, else return
      newDetails.value.forEach((detail) => {
        if (!detail.name) return
      })

      programData.value.FinancialProgramTiers.forEach((detail) => {
        if (!detail.name) return
      })

      updateAllTier.call(this)
    }

    function toggleSave() {
      disableInput.value = !disableInput.value
      if (disableInput.value) submitForm.call(this)
    }

    function addToDelete(id) {
      const index = toDeleteIds.value.indexOf(id)
      if (index !== -1) {
        // If id is already in the list, remove it
        toDeleteIds.value.splice(index, 1)
      } else {
        // If id is not in the list, add it
        toDeleteIds.value.push(id)
      }
    }

    function deleteProgram() {
      try {
        axios.delete(`${apiUrl.value}/financialProgram`, {
          data: { id: route.params.id },
          headers: getAPIHeader.value()
        })
        router.push({ name: 'FinancialProgram' })
      } catch (error) {
        alert('Unable to delete program')
        alert(error)
      }

      programData.value.FinancialProgramTiers.forEach((tier) => {
        deleteTier(tier.id)
      })
    }

    function viewFactors() {
      router.push({
        name: 'FinancialFactor',
        params: { financialProgramId: route.params.id }
      })
    }

    async function deleteTier(id) {
      try {
        await axios.delete(`${apiUrl.value}/financialProgramTier`, {
          data: { id: id },
          headers: getAPIHeader.value()
        })
      } catch (error) {
        alert(error)
      }
    }

    async function addTier(tier) {
      if (!tier.name) return
      console.log(tier, 'tier')
      try {
        await axios.post(
          `${apiUrl.value}/financialProgramTier`,
          {
            financialProgramId: route.params.id,
            name: tier.name,
            minScore: tier.minScore,
            maxScore: tier.maxScore,
            riskLevel: tier.riskLevel,
            minInterestRate: tier.minInterestRate,
            maxInterestRate: tier.maxInterestRate,
            maxTenure: tier.maxTenure,
            maxCapped: tier.maxCapped
          },
          {
            headers: getAPIHeader.value()
          }
        )
        // this.$emit('fetch-data');
      } catch (error) {
        alert(error)
      }
    }

    async function updateTier(tier) {
      try {
        await axios.put(
          `${apiUrl.value}/financialProgramTier`,
          {
            id: tier.id,
            financialProgramId: route.params.id,
            name: tier.name,
            minScore: tier.minScore,
            maxScore: tier.maxScore,
            riskLevel: tier.riskLevel,
            minInterestRate: tier.minInterestRate,
            maxInterestRate: tier.maxInterestRate,
            maxTenure: tier.maxTenure,
            maxCapped: tier.maxCapped
          },
          {
            headers: getAPIHeader.value()
          }
        )

        // this.$emit('fetch-data');
      } catch (error) {
        alert(error)
      }
    }
    async function updateProgram(program) {
      try {
        await axios.put(
          `${apiUrl.value}/financialProgram`,
          {
            id: route.params.id,
            name: program.name,
            maxTenure: program.maxTenure,
            percentageOnNew: program.percentageOnNew,
            percentageOnUsed: program.percentageOnUsed
          },
          {
            headers: getAPIHeader.value()
          }
        )
        // this.$emit('fetch-data');
      } catch (error) {
        alert(error)
      }
    }

    async function updateAllTier() {
      increaseIsFetching()
      const updateTierPromises = this.programData.FinancialProgramTiers.map(updateTier)
      const addTierPromises = this.newDetails.map(addTier)
      const deleteTierPromises = this.toDeleteIds.map(deleteTier)

      const results = await Promise.all(
        [...updateTierPromises],
        [...addTierPromises],
        [...deleteTierPromises][updateProgram(programData.value)]
      )
      decreaseIsFetching()
      if (results) {
        alert('Program updated successfully')
        window.location.reload()
      }
    }

    const getAttribute = async () => {
      try {
        const response = await axios.get(
          `${apiUrl.value}/financialProgram/details/${route.params.id}`,
          {
            headers: getAPIHeader.value()
          }
        )
        // Destructure data from the response
        // const { FinancialProgramTier } = response.data
        programData.value = response.data.FinancialProgram
      } catch (error) {
        alert(error)
      }
    }

    const getAPIHeader = computed(() => {
      return store.getters.getAPIHeader
    })

    const apiUrl = computed(() => {
      return store.state.apiUrl
    })

    onMounted(() => {
      getAttribute()
    })

    watch(
      newDetails,
      (newValue) => {
        if (newValue.at(-1)?.name) {
          newDetails.value.push(reactive({}))
        }
        newValue.forEach((input, i) => {
          if (!input.name && i != newValue.length - 1) {
            newDetails.value.splice(i, 1)
          }
        })
      },
      { deep: true }
    )

    return {
      keys: [
        { id: 1, data: 'name', name: 'Name' },
        { id: 2, data: 'minScore', name: 'Min Score' },
        { id: 3, data: 'maxScore', name: 'Max Score' },
        { id: 4, data: 'riskLevel', name: 'Risk Level' },
        { id: 5, data: 'minInterestRate', name: 'Min Interest' },
        { id: 6, data: 'minInterestRate', name: 'Max Interest' },
        { id: 7, data: 'maxTenure', name: 'Max Tenure' },
        { id: 8, data: 'maxCapped', name: 'Max Capped' }
      ],
      programData,
      getAPIHeader,
      apiUrl,
      editable,
      disableInput,
      newDetails,
      toDeleteIds,
      toggleSave,
      submitForm,
      addToDelete,
      deleteProgram,
      formatCurrency,
      viewFactors
    }
  }
}
</script>

<template>
  <div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="pb-0 card-header">
              <div class="flex-row title-wrapper">
                <div class="col-md-10 col-lg-10 mb-2">
                  <div class="d-lg-flex">
                    <div>
                      <h5 class="pb-1">Program - {{ programData?.name }}</h5>
                    </div>
                  </div>
                  <button class="btn btn-primary" @click="viewFactors">Factors</button>
                </div>

                <div class="flex-row">
                  <button
                    :class="disableInput ? 'edit' : 'save'"
                    class="btn btn-submain"
                    @click.prevent="toggleSave"
                  >
                    {{ disableInput ? 'Edit' : 'Save' }}
                  </button>
                  <button
                    v-if="!disableInput"
                    class="btn btn-submain edit"
                    @click.prevent="
                      () => {
                        disableInput = true
                      }
                    "
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div class="px-0 card-body">
              <div class="px-4 mt-3">
                <div class="flex-row title-wrapper">
                  <h5 class="pb-1">{{ programData?.name }}</h5>
                  <button
                    :disabled="disableInput"
                    @click="deleteProgram"
                    class="btn btn-submain edit"
                  >
                    Delete Program
                  </button>
                </div>
                <div class="mb-3 flex-row">
                  <div class="flex-col input-wrapper">
                    <label for="">Name</label>
                    <input v-model="programData.name" :disabled="disableInput" type="text" />
                  </div>

                  <!-- <div class="flex-col input-wrapper">
                    <label for="">Max Capped</label>
                    <input v-model="programData.maxCapped" :disabled="disableInput" type="text" />
                  </div> -->

                  <div class="flex-col input-wrapper">
                    <label for="">Percentage (New)</label>
                    <input
                      v-model="programData.percentageOnNew"
                      :disabled="disableInput"
                      type="number"
                    />
                  </div>

                  <div class="flex-col input-wrapper">
                    <label for="">Percentage (Used)</label>
                    <input
                      v-model="programData.percentageOnUsed"
                      :disabled="disableInput"
                      type="number"
                    />
                  </div>
                </div>
                <br />

                <div class="table-responsive">
                  <div v-if="disableInput">
                    <VTable
                      v-if="programData.FinancialProgramTiers"
                      :data="programData.FinancialProgramTiers"
                      class="table"
                    >
                      <template #head>
                        <tr class="tableHeader">
                          <template v-for="i in keys" :key="i">
                            <VTh
                              class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                              :sort-key="i.data"
                            >
                              {{ i.name }}
                            </VTh>
                          </template>
                        </tr>
                      </template>
                      <template #body="{ rows }">
                        <tr v-for="row in rows" :key="row">
                          <template v-for="i in keys" :key="i">
                            <td
                              v-if="i.data == 'maxCapped'"
                              class="text-sm font-weight-normal text-start text-wrap align-middle"
                            >
                              {{ formatCurrency(row[i.data]) ?? 0 }}
                            </td>

                            <td
                              v-else
                              class="text-sm font-weight-normal text-start text-wrap align-middle"
                            >
                              {{ row[i.data] ?? 'N/A' }}
                            </td>
                          </template>
                        </tr>
                      </template>
                    </VTable>
                  </div>
                  <div v-else>
                    <div
                      v-for="(program, i) in programData.FinancialProgramTiers"
                      :key="program"
                      class="new-detail flex-row"
                      :class="toDeleteIds.includes(program.id) ? 'delete' : ''"
                    >
                      <div class="flex-col input-wrapper">
                        <label :class="i == 0 ? 'show' : 'hide'" for="">Name</label>
                        <input
                          v-model="program.name"
                          class="form-control"
                          :disabled="disableInput"
                          type="text"
                          placeholder="Name of attribute"
                        />
                      </div>

                      <div class="flex-col input-wrapper">
                        <label :class="i == 0 ? 'show' : 'hide'" for="">Min Score</label>
                        <input
                          v-model="program.minScore"
                          class="form-control"
                          :disabled="disableInput"
                          type="number"
                        />
                      </div>

                      <div class="flex-col input-wrapper">
                        <label :class="i == 0 ? 'show' : 'hide'" for="">Max Score</label>
                        <input
                          v-model="program.maxScore"
                          class="form-control"
                          :disabled="disableInput"
                          type="number"
                        />
                      </div>

                      <div class="flex-col input-wrapper">
                        <label :class="i == 0 ? 'show' : 'hide'" for="">Risk Level</label>
                        <input
                          v-model="program.riskLevel"
                          class="form-control"
                          :disabled="disableInput"
                          type="text"
                          placeholder="Eg: Low, Mid, High"
                        />
                      </div>

                      <div class="flex-col input-wrapper">
                        <label :class="i == 0 ? 'show' : 'hide'" for="">Min Interest</label>
                        <input
                          v-model="program.minInterestRate"
                          class="form-control"
                          :disabled="disableInput"
                          type="number"
                        />
                      </div>

                      <div class="flex-col input-wrapper">
                        <label :class="i == 0 ? 'show' : 'hide'" for="">Max Interest</label>
                        <input
                          v-model="program.maxInterestRate"
                          class="form-control"
                          :disabled="disableInput"
                          type="number"
                        />
                      </div>

                      <div class="flex-col input-wrapper">
                        <!-- tenure in months -->
                        <label :class="i == 0 ? 'show' : 'hide'" for="">Max Tenure</label>
                        <select
                          v-model="program.maxTenure"
                          class="form-select"
                          :disabled="disableInput"
                        >
                          <option selected value="0">Select</option>
                          <option value="1">1 Year</option>
                          <option value="2">2 Years</option>
                          <option value="3">3 Years</option>
                          <option value="4">4 Years</option>
                          <option value="5">5 Years</option>
                        </select>
                      </div>

                      <div class="flex-col input-wrapper">
                        <label :class="i == 0 ? 'show' : 'hide'" for="">Maximum Loan Amount</label>
                        <input
                          v-model="program.maxCapped"
                          class="form-control"
                          :disabled="disableInput"
                          type="number"
                        />
                      </div>

                      <button
                        v-if="!disableInput"
                        type="button"
                        class="btn mb-0 border-0 btn-outline-danger"
                        @click="addToDelete(program.id)"
                      >
                        <!-- {{ toDeleteIds.includes(attr.id) ? 'Undo' : '&#10060;' }} -->
                        <i
                          v-if="!toDeleteIds.includes(program.id)"
                          class="fas fa-trash-alt"
                          aria-hidden="true"
                        ></i>
                        <i v-else class="fas fa-undo" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div v-for="(detail, i) in newDetails" :key="detail" class="new-detail flex-row">
                    <div class="flex-col input-wrapper">
                      <label :class="i == 0 ? 'show' : 'hide'" for="">Name</label>
                      <input
                        v-model="detail.name"
                        class="form-control"
                        :disabled="disableInput"
                        type="text"
                        placeholder="Name of attribute"
                      />
                    </div>

                    <div class="flex-col input-wrapper">
                      <label :class="i == 0 ? 'show' : 'hide'" for="">Min Score</label>
                      <input
                        v-model="detail.minScore"
                        class="form-control"
                        :disabled="disableInput"
                        type="number"
                      />
                    </div>

                    <div class="flex-col input-wrapper">
                      <label :class="i == 0 ? 'show' : 'hide'" for="">Max Score</label>
                      <input
                        v-model="detail.maxScore"
                        class="form-control"
                        :disabled="disableInput"
                        type="number"
                      />
                    </div>

                    <div class="flex-col input-wrapper">
                      <label :class="i == 0 ? 'show' : 'hide'" for="">Risk Level</label>
                      <input
                        v-model="detail.riskLevel"
                        class="form-control"
                        :disabled="disableInput"
                        type="text"
                        placeholder="Eg: Low, Mid, High"
                      />
                    </div>

                    <div class="flex-col input-wrapper">
                      <label :class="i == 0 ? 'show' : 'hide'" for="">Min Interest</label>
                      <input
                        v-model="detail.minInterestRate"
                        class="form-control"
                        :disabled="disableInput"
                        type="number"
                      />
                    </div>

                    <div class="flex-col input-wrapper">
                      <label :class="i == 0 ? 'show' : 'hide'" for="">Max Interest</label>
                      <input
                        v-model="detail.maxInterestRate"
                        class="form-control"
                        :disabled="disableInput"
                        type="number"
                      />
                    </div>

                    <div class="flex-col input-wrapper">
                      <!-- tenure in months -->
                      <label :class="i == 0 ? 'show' : 'hide'" for="">Max Tenure</label>
                      <select
                        v-model="detail.maxTenure"
                        class="form-select"
                        :disabled="disableInput"
                      >
                        <option selected value="0">Select</option>
                        <option value="1">1 Year</option>
                        <option value="2">2 Years</option>
                        <option value="3">3 Years</option>
                        <option value="4">4 Years</option>
                        <option value="5">5 Years</option>
                      </select>
                    </div>

                    <div class="flex-col input-wrapper">
                      <label :class="i == 0 ? 'show' : 'hide'" for="">Maximum Loan Amount</label>
                      <input
                        v-model="detail.maxCapped"
                        class="form-control"
                        :disabled="disableInput"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
input,
textarea {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4rem;
}

textarea[disabled],
input[disabled] {
  border: none;
  background: transparent !important;
}

select {
  min-width: 5rem;
  padding-right: 2rem;
}

.input-wrapper label.hide {
  visibility: hidden;
  line-height: 0;
  padding-top: unset;
  padding-bottom: unset;
}
.flex-row {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: flex-start;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-between {
  justify-content: space-between;
}

.flex-col.input-wrapper {
  min-width: min-content;
}

.input-wrapper label {
  text-wrap: nowrap;
  margin: unset;
  padding: 0.6rem;
}

.flex-row:nth-of-type(even) input,
.flex-row:nth-of-type(even) textarea {
  background: rgb(196, 227, 235);
}

.flex-row.title-wrapper {
  display: flex;
  justify-content: space-between;
}

.flex-row input {
  border-radius: 15px;
  padding: 0.6rem;
}

.flex-row.new-detail {
  overflow-x: auto;
}

button.save,
button.edit {
  color: white;
  height: fit-content;
}
button.save {
  background-color: green;
}

button.edit {
  background-color: red;
}

textarea.long-input {
  width: clamp(350px, 40dvw, 800px);
}

.flex-row.delete {
  border: 2px solid red;
}

.flex-row.delete input,
.flex-row.delete textarea {
  background: #ffcccc;
}

.divider {
  border-bottom: 1px solid #ccc;
  margin: 1rem 0;
  width: 100%;
}
</style>
