<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'
import EditProgram from './components/AddProgram.vue'

export default {
  components: {
    EditProgram
  },
  data() {
    return {
      editing: false,
      currentPage: 1,
      listOfProgram: [],
      keys: [{ id: 1, data: 'name', name: 'Program Name' }]
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl'])
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.fetchApplicationFactorData()
  },
  methods: {
    toggleEdit() {
      this.editing = !this.editing
    },
    toLink(id) {
      this.$router.push({ name: 'FinancialProgramDetail', params: { id: id } })
    },
    hideModal(e) {
      if (e.target.classList.contains('modal')) {
        this.editing = false
      }
    },
    async fetchApplicationFactorData() {
      axios
        .get(`${this.apiUrl}/financialProgram/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.listOfProgram = data.FinancialPrograms
          console.log(this.listOfProgram)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>

<template>
  <EditProgram />

  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="table-controls row">
              <div class="col-md-12 col-lg-12 mb-2">
                <div class="d-lg-flex flex-title">
                  <div>
                    <h5 class="pb-1">Tier List</h5>
                    <p>All Tiers</p>
                  </div>
                  <button
                    class="btn btn-submain text-white"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#addProgramModal"
                  >
                    +&nbsp; Create New
                  </button>
                </div>
              </div>
            </div>
            <div class="table-controls row">
              <div class="col-md-7 col-lg-7 mb-3"></div>
              <div class="col-md-3 col-lg-3 mb-3"></div>
              <div class="col-md-2 col-lg-2 mb-3 ms-auto">
                <select id="dropdown" class="form-select ml-2">
                  <option value="All">All</option>
                </select>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="px-4 mt-3">
              <div class="table-responsive">
                <VTable v-model:currentPage="currentPage" :data="listOfProgram" class="table">
                  <template #head>
                    <tr class="tableHeader">
                      <template v-for="i in keys" :key="i">
                        <VTh
                          class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                          :sort-key="i.data"
                        >
                          {{ i.name }}
                        </VTh>
                      </template>
                    </tr>
                  </template>
                  <template #body="{ rows }">
                    <tr v-for="row in rows" :key="row" class="clickable" @click="toLink(row.id)">
                      <template v-for="i in keys" :key="i">
                        <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                          {{ row[i.data] ?? 'N/A' }}
                        </td>
                      </template>
                    </tr>
                  </template>
                </VTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.flex-title {
  justify-content: space-between;
}

.flex-title button {
  height: fit-content;
}
</style>
