<template>
  <div
    id="NewResourceModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="NewResourceModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="NewResourceModalLabel" class="modal-title fs-5">
            New Resouce
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <div class="form-group col-md-6 mb-2 mt-2">
              <label class="form-label">Category*</label>
              <h5 class="fw-bold">{{ category }}</h5>
            </div>
            <div class="form-group col-md-6 mb-2 mt-2">
              <label class="form-label">Name*</label>
              <input
                id="name"
                v-model="resourceData.name"
                type="text"
                class="form-control"
                placeholder="Resource Name"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            id="btnClose"
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :class="{ disabled: submitting }"
            @click="submitUserForm"
          >
            <div
              v-if="submitting"
              class="spinner-border spinner-border-sm"
              role="status"
            ></div>
            <span class="ms-1">
              {{ submitting ? "Submitting" : "Submit" }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapState, mapMutations, mapGetters } from "vuex";
export default {
  name: "NewResourceModal",
  props: {
    category: {
      type: String,
      default: null,
    },
  },
  emits: ["fetch-data"],
  data() {
    return {
      resourceData: {
        name: "",
        category: "",
        description: null,
      },
      apiHeader: {},
      submitting: false,
    };
  },
  computed: {
    ...mapState(["isLoading", "apiUrl", "user"]),
    ...mapGetters(["getAPIHeader", "validateValue"]),
  },
  watch: {},
  mounted() {
    this.apiHeader = this.getAPIHeader();
  },
  methods: {
    ...mapMutations(["decreaseIsFetching", "increaseIsFetching"]),
    submitUserForm() {
      this.submitting = true;
      this.resourceData.category = this.category;
      axios
        .post(`${this.apiUrl}/resource`, this.resourceData, {
          headers: this.apiHeader,
        })
        .then(() => {
          this.submitting = false;
          document.getElementById("btnClose").click();
          this.$swal.fire({
            title: "Submitted Successfully",
            icon: "success",
            timer: 2000,
            timerProgressBar: true,
            willClose: () => {
              this.$emit("fetch-data");
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formattedDate(date) {
      return moment(date).format("DD-MM-YYYY hh:mm A");
    },
    formattedDateNoTime(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>

<style></style>
