<template>
  <!-- <div id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-4">
        <argon-avatar
          :img="img"
          alt="team-3"
          size="xl"
          shadow="sm"
          border-radius="lg"
        />
      </div>
      <div class="col-sm-auto col-8 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">
            {{ capitalizeFirstLetter(userData.fullName) }}
          </h5>
          <p class="mb-0 font-weight-bold text-sm">
            Store {{ user?.Role?.name }}
          </p>
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
        <button class="btn btn-outline-primary">Upload</button>
      </div>
    </div>
  </div> -->
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
    </div>
    <div class="card-body pt-0">
      <div class="row"></div>
      <div class="row">
        <div class="col-md-6">
          <label class="form-label mt-2">Full Name</label>
          <input
            id="name"
            v-model="userData.name"
            class="form-control"
            type="text"
            placeholder="012 1234567"
          />
        </div>
        <div class="col-md-6">
          <label class="form-label mt-2">Active</label>
          <div class="ms-1">
            <div class="form-check form-switch">
              <input
                id="navbarFixed"
                class="mt-1 form-check-input"
                type="checkbox"
                :checked="userData.status"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="form-label mt-2">Email</label>
          <input
            id="name"
            v-model="userData.email"
            class="form-control"
            type="email"
            placeholder="example@email.com"
          />
        </div>
        <div class="col-6">
          <label class="form-label mt-2">Phone Number</label>
          <input
            id="phone"
            v-model="userData.phone"
            class="form-control"
            type="text"
            placeholder="012 1234567"
          />
        </div>
      </div>
      <div class="mt-5">
        <h5>Position Info</h5>
        <div class="row">
          <div class="form-group col-md-6 mb-2 mt-2">
            <label class="form-label">Role*</label>
            <select
              id="choices-role"
              v-model="userData.roleId"
              class="form-control"
              name="choices-role"
              :class="{ 'is-invalid': requiredKeys[1].invalid }"
            ></select>
            <span class="text-danger" :class="{ 'd-none': !requiredKeys[1].invalid }">{{
              requiredKeys[1].text
            }}</span>
          </div>
          <div class="form-group col-md-6 mb-2 mt-2">
            <label class="form-label">Department*</label>
            <select
              id="choices-department"
              v-model="userData.departmentId"
              class="form-control"
              name="choices-role"
              :class="{ 'is-invalid': requiredKeys[2].invalid }"
            ></select>
            <span class="text-danger" :class="{ 'd-none': !requiredKeys[2].invalid }">{{
              requiredKeys[2].text
            }}</span>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <h5>User Signature</h5>
        <div class="col-2">
          <div v-if="userData.signatureUrl">
            <img
              class="w-100 img-fluid rounded img-thumbnail"
              :src="userData.signatureUrl"
              alt=""
            />
          </div>
          <div v-else>
            <p>No Signature Found</p>
          </div>
          <div class="mt-3">
            <button
              class="btn btn-primary mx-1"
              data-bs-toggle="modal"
              data-bs-target="#EditSignatureModal"
            >
              Upload
            </button>
          </div>
        </div>
      </div>

      <argon-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="submitProfileForm()"
        >Update Profile</argon-button
      >
    </div>
  </div>

  <div id="auth-info">
    <div class="card mt-4">
      <div class="card-header">
        <h5>Reset Username</h5>
      </div>
      <div class="card-body pt-0">
        <div class="row">
          <div class="col-6">
            <label class="form-label mt-2">Username</label>
            <input
              id="username"
              v-model="username"
              class="form-control"
              type="text"
              placeholder=""
              :class="{ 'is-invalid': requiredAuthKeys[0].invalid }"
            />
          </div>
        </div>
        <h5 class="mt-5">Username requirement</h5>
        <p class="text-muted mb-2">Please follow this guide for a strong username:</p>
        <ul class="text-muted ps-4 mb-0 float-start">
          <li>
            <span class="text-sm">Min 5 characters</span>
          </li>
          <li>
            <span class="text-sm">Username can't be existing</span>
          </li>
        </ul>
        <argon-button
          class="float-end mt-6 mb-0"
          color="dark"
          variant="gradient"
          size="sm"
          @click="submitAuthForm"
          >Update Username</argon-button
        >
      </div>
    </div>
  </div>
  <div id="password" class="card mt-4">
    <div class="card-header">
      <h5>Reset Password</h5>
    </div>
    <div class="card-body pt-0">
      <label class="form-label">New password</label>
      <input
        id="password"
        v-model="userAuthData.password"
        class="form-control"
        type="password"
        placeholder="New Password"
        :class="{ 'is-invalid': requiredPasswordKeys[0].invalid }"
      />
      <label class="form-label">Confirm new password</label>
      <input
        id="confirm-password"
        v-model="userAuthData.confirmPassword"
        class="form-control"
        type="password"
        placeholder="Confirm Password"
        :class="{ 'is-invalid': requiredPasswordKeys[1].invalid }"
      />
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">Please follow this guide for a strong password:</p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 8 characters</span>
        </li>
        <li>
          <span class="text-sm">One capital letter</span>
        </li>
      </ul>
      <argon-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        @click="submitPasswordForm"
        >Update password</argon-button
      >
    </div>
  </div>
  <EditSignatureModal :user-id="userData.id" @fetch-data="fetchData" />
</template>

<script>
import * as Choices from 'choices.js'
import ArgonButton from '@/components/ArgonButton.vue'
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios'
import { mapState, mapMutations, mapGetters } from 'vuex'
import EditSignatureModal from './EditSignatureModal.vue'

export default {
  name: 'SideNavItem',
  components: {
    ArgonButton,
    EditSignatureModal
    //ArgonAvatar,
  },
  data() {
    return {
      userData: {},
      username: null,
      userAuthData: {
        password: '',
        confirmPassword: ''
      },
      apiHeader: {},
      submitting: false,
      requiredKeys: [
        {
          name: 'name',
          text: 'Please enter full name',
          invalid: false,
          className: null
        },
        {
          name: 'roleId',
          text: 'Please select role',
          invalid: false,
          className: 'role__choice'
        },
        {
          name: 'departmentId',
          text: 'Please select department',
          invalid: false,
          className: 'department__choice'
        }
      ],
      requiredAuthKeys: [
        {
          name: 'username',
          text: 'Username does not match requirement',
          invalid: false
        }
      ],
      requiredPasswordKeys: [
        {
          name: 'newPassword',
          text: 'New password does not match requirements',
          invalid: false
        },
        {
          name: 'confirmPassword',
          text: 'Confirm password not match with password',
          invalid: false
        }
      ],
      roles: [],
      departments: [],
      resources: [],
      selectedImage: null
    }
  },
  computed: {
    ...mapState(['isLoading', 'apiUrl', 'user']),
    ...mapGetters(['validateValue', 'getAPIHeader', 'capitalizeFirstLetter'])
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
    if (this.$route.name == 'UserMyProfile') {
      this.userData = this.user
    } else {
      this.fetchData()
    }
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    fetchData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/user/details/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.userData = data.User
          this.username = this.userData.UserAuth.username
          this.fetchRoleData()
          this.fetchDepartmentData()
          this.fetchResourceData()
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    fetchResourceData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/resource/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.resources = data.Resources

          this.decreaseIsFetching()
        })
    },
    fetchRoleData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/role/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.roles = data.Roles
          let roleChoices = []
          this.roles.forEach((role) => {
            roleChoices.push({
              value: role.id,
              label: role.name,
              selected: role.id == this.userData.roleId
            })
          })

          if (document.getElementById('choices-role')) {
            new Choices(document.getElementById('choices-role'), {
              choices: roleChoices,
              classNames: {
                containerInner: 'choices__inner role__choice'
              }
            })
          }
          this.decreaseIsFetching()
        })
    },
    fetchDepartmentData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/department/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.departments = data.Departments
          let departmentChoices = []
          this.departments.forEach((department) => {
            departmentChoices.push({
              value: department.id,
              label: department.name,
              selected: department.id == this.userData.departmentId
            })
          })
          if (document.getElementById('choices-department')) {
            new Choices(document.getElementById('choices-department'), {
              choices: departmentChoices,
              classNames: {
                containerInner: 'choices__inner department__choice'
              }
            })
          }
          this.decreaseIsFetching()
        })
    },
    submitProfileForm() {
      console.log(this.userData)
      this.increaseIsFetching()
      if (this.validateUserForm()) {
        axios.put(
          `${this.apiUrl}/user`,
          {
            id: this.userData.id,
            departmentId: this.userData.departmentId,
            roleId: this.userData.roleId,
            name: this.userData.name,
            phone: this.userData.phone,
            email: this.userData.email,
            status: this.userData.status
          },
          {
            headers: this.apiHeader
          }
        )
        this.$swal.fire({
          title: 'Saved Successfully',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true
        })
        this.decreaseIsFetching()
      }
    },
    submitAuthForm() {
      this.increaseIsFetching()
      if (this.username.length > 5) {
        this.requiredAuthKeys[0].invalid = false
        axios.put(
          `${this.apiUrl}/userAuth`,
          {
            userId: this.userData.id,
            username: this.username
          },
          {
            headers: this.apiHeader
          }
        )
        this.$swal.fire({
          title: 'Saved Successfully',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true
        })
      } else {
        this.requiredAuthKeys[0].invalid = true
      }

      this.decreaseIsFetching()
    },
    submitPasswordForm() {
      this.increaseIsFetching()
      if (this.validatePassword()) {
        axios
          .put(
            `${this.apiUrl}/userAuth`,
            {
              userId: this.userData.id,
              password: this.userAuthData.password
            },
            {
              headers: this.apiHeader
            }
          )
          .then(() => {
            this.$swal.fire({
              title: 'Saved Successfully',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true
            })
          })
      }

      this.decreaseIsFetching()
    },
    createChoice(choices, choiceName, elementId) {
      if (Object.keys(this[choiceName]).length > 0) {
        this[choiceName].destroy()
      }
      const element = document.getElementById(elementId)
      this[choiceName] = new Choices(element, {
        searchEnabled: true,
        searchChoices: true,
        searchPlaceholderValue: 'Search here',
        choices: choices
      })
    },
    validateUserForm() {
      let result = true
      this.requiredKeys.forEach((x, index) => {
        const value = this.user[x.name]
        if (this.validateValue(value)) {
          result = false
          this.requiredKeys[index].invalid = true
          if (this.requiredKeys[index].className != null) {
            const classes = document.getElementsByClassName(this.requiredKeys[index].className)
            if (classes.length > 0) {
              classes[0].classList.add('border-danger')
            }
          }
        } else {
          this.requiredKeys[index].invalid = false
          if (this.requiredKeys[index].className != null) {
            const classes = document.getElementsByClassName(this.requiredKeys[index].className)
            if (classes.length > 0) {
              classes[0].classList.remove('border-danger')
            }
          }
        }
      })
      return result
    },
    validatePassword() {
      const capitalLetterRegex = /[A-Z]/
      const specialCharRegex = /[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/
      this.requiredPasswordKeys[1].invalid = false
      this.requiredPasswordKeys[1].validateNow = true
      if (this.userAuthData.password == this.userAuthData.confirmPassword) {
        if (this.userAuthData.password.length < 8) {
          this.requiredPasswordKeys[1].invalid = true
        } else if (!capitalLetterRegex.test(this.userAuthData.password)) {
          this.requiredPasswordKeys[1].invalid = true
        } else if (!specialCharRegex.test(this.userAuthData.password)) {
          this.requiredPasswordKeys[1].invalid = true
        }
      } else {
        this.requiredPasswordKeys[1].invalid = true
      }
      return !this.requiredPasswordKeys[1].invalid
    }
  }
}
</script>
