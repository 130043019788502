<template>
  <main class="mt-0 main-content bg-secondary">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card bg-secondary">
                <div class="pb-0 card-header bg-secondary text-center">
                  <img
                    class="text-center w-50"
                    :src="require('@/assets/img/logo&slogan-XCMG.png')"
                    alt="XCMG Logo"
                  />
                  <br />
                  <br />
                  <br />
                  <!-- <h4 class="font-weight-bolder">XCMG</h4> -->
                  <!-- <p class="mb-0">Sign in</p> -->
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="signIn">
                    <div class="mb-3">
                      <div class="form-group">
                        <input
                          v-model="username"
                          type="text"
                          class="form-control form-control-lg"
                          name="Username"
                          placeholder="Username"
                          isRequired="true"
                        />
                      </div>
                    </div>
                    <div class="mb-3">
                      <input
                        v-model="password"
                        type="password"
                        class="form-control form-control-lg"
                        name="Password"
                        placeholder="Password"
                        isRequired="true"
                      />
                    </div>
                    <p class="text-danger">
                      {{ errorMessage }}
                    </p>
                    <div class="text-center">
                      <div class="d-grid gap-2">
                        <button class="btn btn-main" type="submit" @submit="signIn">Sign In</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                :style="{
                  backgroundImage:
                    'url(' +
                    'https://s3.eu-central-1.amazonaws.com/vertikal.net/0_xcmg-60-6-hybrid-crane-dsc06212_96e04ec0.jpg' +
                    ')',
                  backgroundSize: 'cover'
                }"
              >
                <span class="mask bg-main"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Cookies from 'js-cookie'
// import ArgonInput from "@/components/ArgonInput.vue";
// import ArgonButton from "@/components/ArgonButton.vue";
const body = document.getElementsByTagName('body')[0]
import { mapState, mapMutations, mapGetters } from 'vuex'
import { loginWithDevice, AUTH_TOKEN_COOKIE_NAME } from '../../../router/authService'
// import { getDeviceId } from "../../../router/deviceService";
export default {
  name: 'Login Page',
  components: {
    // ArgonInput,
    // ArgonButton,
  },
  data() {
    return {
      username: '',
      password: '',
      errorMessage: ''
    }
  },
  computed: {
    ...mapState(['isLoading', 'apiUrl', 'user']),
    ...mapGetters(['validateValue', 'readFileAsBase64', 'getAPIHeader'])
  },
  created() {
    this.$store.state.hideConfigButton = true
    this.toggleDefaultLayout()
    body.classList.remove('bg-gray-100')
    if (Cookies.get(AUTH_TOKEN_COOKIE_NAME)) {
      this.$router.push({
        name: 'DashboardDefault'
      })
    }
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false
    this.toggleDefaultLayout()
    body.classList.add('bg-gray-100')
  },
  methods: {
    ...mapMutations(['toggleDefaultLayout', 'decreaseIsFetching', 'increaseIsFetching']),
    async signIn() {
      // const deviceId = await getDeviceId();
      this.errorMessage = ''
      this.increaseIsFetching()
      const res = await loginWithDevice(this.username, this.password)
      if (res.status == 0) {
        this.errorMessage = 'Login failed, please try again.'
      } else {
        this.routePush()
      }
      this.decreaseIsFetching()
    },
    routePush() {
      this.$router.push({
        name: 'DashboardDefault'
      })
    }
  }
}
</script>
