<template>
  <div
    id="ProductModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="ProductModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="ProductModalLabel" class="modal-title fs-5">
            {{ selectedProductId ? "Edit" : "New" }} Asset
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-group col-md-6 mb-2 mt-2">
            <label class="form-label">Asset Name*</label>
            <input
              id="name"
              v-model="productData.name"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': error.name }"
            />
            <span class="text-danger" :class="{ 'd-none': !error.name }"
              >Asset Name is required</span
            >
          </div>
          <div class="form-group col-md-6 mb-2 mt-2">
            <label class="form-label">Cost*</label>
            <input
              id="name"
              v-model="productData.cost"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': error.cost }"
            />
            <span class="text-danger" :class="{ 'd-none': !error.cost }"
              >Cost is required</span
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="submitForm()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "ProductModal",
  props: {
    brandId: {
      type: Number,
      default: null,
    },
    products: {
      type: Array,
      default: () => [],
    },
    selectedProductId: {
      type: Number,
      default: null,
    },
  },
  emits: ["fetch-data"],
  data() {
    return {
      apiHeader: {},
      productData: {
        name: null,
        cost: 0,
      },
      error: {
        name: false,
        cost: false,
      },
    };
  },
  computed: {
    ...mapGetters(["validateValue", "getAPIHeader", "formattedDate"]),
    ...mapState(["apiUrl"]),
  },
  watch: {
    brandId(id) {
      this.productData.brandId = id;
    },
    selectedProductId(id) {
      const foundProduct = this.products.find((x) => x.id === id);
      if (foundProduct) {
        this.productData = {
          id: foundProduct.id,
          brandId: foundProduct.brandId,
          name: foundProduct.name,
          cost: foundProduct.cost,
        };
      } else {
        this.productData = {
          brandId: this.brandId,
          name: null,
          cost: 0,
        };
      }
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.apiHeader = this.getAPIHeader();
  },

  methods: {
    submitForm() {
      let validate = true;
      Object.keys(this.productData).forEach((x) => {
        if (this.productData[x] == null || this.productData[x] == "") {
          this.error[x] = true;
          validate = false;
        } else {
          this.error[x] = false;
        }
      });
      if (validate) {
        let action = "post";
        if (this.selectedProductId) {
          action = "put";
        }
        axios[action](`${this.apiUrl}/product`, this.productData, {
          headers: this.apiHeader,
        })
          .then((res) => {
            console.log("RESPONSE RECEIVED: ", res.data);
            window.location.reload();
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
          });
      }
    },
  },
};
</script>

<style></style>
