<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    hirerId: {
      type: Number,
      default: null
    }
  },
  emits: ['refresh-guarantor'],
  data() {
    return {
      contact: {
        hirerId: '',
        name: '',
        identityNo: '',
        phone: '',
        email: ''
      },
      token: ''
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader', 'formattedDate']),
    ...mapState(['apiUrl'])
  },
  mounted() {
    this.token = this.getAPIHeader()
  },

  methods: {
    validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return regex.test(email)
    },
    validatePhoneNumber(phoneNumber) {
      return /^[0-9-]{7,12}$/.test(phoneNumber)
    },

    submitForm() {
      this.contact.hirerId = this.hirerId

      // Changed to optional
      if (this.contact.email && !this.validateEmail(this.contact.email)) {
        alert('Email is not valid')
        return
      }

      // Changed to optional
      if (this.contact.phone && !this.validatePhoneNumber(this.contact.phone)) {
        alert('Phone number is not valid')
        return
      }

      axios
        .post(`${this.apiUrl}/hirerContact/`, this.contact, {
          headers: this.token
        })
        .then((res) => {
          console.log('RESPONSE RECEIVED: ', res.data)
          if (res.data.status == 0) alert(res.data.msg)
          // emit refreshGuarantor
          this.$emit('refresh-guarantor')
        })
        .catch((err) => {
          console.log('AXIOS ERROR: ', err)
          alert(err?.response?.data?.msg)
        })
    }
  }
}
</script>

<template>
  <div
    id="ContactModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="ContactModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="ContactModalLabel" class="modal-title fs-5">Directorship</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <label class="form-label">Name*</label>
              <input v-model="contact.name" type="text" class="form-control col-8" />
            </div>
            <div class="col-6">
              <label class="form-label">Identity No*</label>
              <input v-model="contact.identityNo" type="text" class="form-control col-8" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <label class="form-label">Phone (Optional)</label>
              <input v-model="contact.phone" type="text" class="form-control col-8" />
            </div>
            <div class="col-6">
              <label class="form-label">Email (Optional)</label>
              <input v-model="contact.email" type="text" class="form-control col-8" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="submitForm()"
          >
            Create
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
