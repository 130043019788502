<script>
import axios from 'axios'
import { mapState, mapMutations, mapGetters } from 'vuex'
import LineChart from '@/examples/Charts/LineChart.vue'
import PieChart from '@/examples/Charts/PieChart.vue'
import AgGrid from '@/components/custom/AgGrid'

import Datepicker from '@vuepic/vue-datepicker'
import { formattedAmount } from '@/assets/js/calculator'

export default {
  name: 'CollectionOverview',
  components: {
    Datepicker,
    LineChart,
    AgGrid,
    PieChart
  },
  data() {
    return {
      overdueReceivableRatio: 0,
      installmentCollected: 0,
      nonPerformingLoan: 0,
      nonPerformingLoanRatio: 0,
      overdueCollected: 0,
      totalOverdue: 0,
      reasons: [],
      barValue: '',
      currentPage: 1,
      pageSize: 10,
      totalPages: 1,
      maxPageLinks: 10,
      showModal: false,
      monthDetails: [],
      topOverdue: [],
      topPartial: [],
      overviewData: '',
      dateSelected: '',
      selectedYear: null,
      collectionOverdue: {
        x: [],
        y: []
      },
      collectionPartial: {
        x: [],
        y: []
      },
      overdueReasons: {
        x: [],
        y: []
      },

      keys: [
        { field: 'ApplicantProduct.hpNo', headerName: 'HP No' },
        { field: 'ApplicantProduct.Product.name', headerName: 'Product' },
        { field: 'ApplicantProduct.Brand.name', headerName: 'Brand' },
        { field: 'ApplicantProduct.Hirer.name', headerName: 'Company' },
        { field: 'overdueAmount', headerName: 'Overdue Amount' }
      ]
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl', 'user']),
    availableYears() {
      const years = []
      const totalNumberofYears = 5
      for (let i = 0; i < totalNumberofYears; i++) {
        years.push(new Date().getFullYear() - i)
      }
      return years
    }
  },
  watch: {
    dateSelected(date) {
      this.fetchTopOverdue(date)
      this.fetchTopPartial(date)
      this.fetchNewOverdue(date)
      this.fetchOverdueReason(date)
    },
    selectedYear(year) {
      let dateRange
      if (year < 0) {
        dateRange = [this.getDateMonthsBefore(parseInt(year)), this.getDateMonthsBefore(0)]
      } else {
        dateRange = [`${year}-01-01`, `${year}-12-31`]
      }
      this.dateSelected = [new Date(dateRange[0]), new Date(dateRange[1])]
      // this.fetchProductRanking(dateRange)
    }
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.selectedYear = new Date().getFullYear()
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    formattedAmount,
    redirectTo(name, id) {
      this.$router.push({ name: name, params: { id: id } })
    },
    overdueTransactionDetail(e) {
      const { title, label, value } = e
      title
      if (value == 0) return
      this.unhideModal()

      let list
      this.barValue = value
      list = this.overviewData?.[label]
      list?.forEach((x) => {
        x = { ...x, overdueAmount: formattedAmount(x.chargeAmount - x.paidAmount) }
        this.monthDetails.push(x)
      })

      // Not Used since the change in the API
      // this.monthDetails = this.aggregateByApplicantProductId(this.monthDetails)
    },

    overdueDetailClick(row, e) {
      // console.log(e.event.srcElement.getAttribute('col-id'))
      const key = e.event.srcElement.getAttribute('col-id')
      if (key == 'ApplicantProduct.hpNo' || key == 'ApplicantProduct.Product.name') {
        this.redirectTo('ProductProfile', row.ApplicantProduct.Product.id)
      }

      if (key == 'ApplicantProduct.Brand.name') {
        this.redirectTo('TypeProfile', row.ApplicantProduct.Brand.id)
      }

      if (key == 'ApplicantProduct.Hirer.name') {
        this.redirectTo('CompanyProfile', row.ApplicantProduct.Hirer.id)
      }
    },

    unhideModal() {
      this.showModal = true
    },

    unhideReasonModal() {
      this.reasonModal = true
    },

    hideModal() {
      this.showModal = false
      //   clean up
      this.monthDetails = []
    },

    hideReasonModal() {
      this.reasonModal = false
      //   clean up
      this.reasonDetails = []
    },

    fetchOverdueReason(dateRange) {
      this.increaseIsFetching()

      axios
        .get(
          `${this.apiUrl}/analysis/overview_overdue_transaction_reason/${this.getDateQueryString(
            dateRange
          )}`,
          {
            headers: this.apiHeader
          }
        )
        .then(({ data }) => {
          const { totalReceivableOverdue } = data

          this.overdueReceivableRatio = totalReceivableOverdue.ratioOverdueReceivable * 100

          const reasons = data.reasons
          this.reasons = reasons
          const xLabels = Object.keys(reasons)
          const yValues = []

          // Iterate over each day to calculate the total number of reasons
          for (const day of xLabels) {
            const reasonsForDay = reasons[day]
            // Calculate total charge amount for the day
            const totalChargeForDay = Object.values(reasonsForDay).reduce((acc, items) => {
              // Sum up chargeAmount of each item in the array
              const sumOfChargeAmounts = items.reduce((total, item) => {
                return total + parseFloat(item.chargeAmount - item.paidAmount)
              }, 0)
              return acc + sumOfChargeAmounts
            }, 0)
            yValues.push(totalChargeForDay)
          }

          // Update overdueReasons with dynamic y values
          this.overdueReasons = {
            x: xLabels,
            y: yValues
          }

          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },

    fetchNewOverdue(dateRange) {
      // /analysis/overview_overdue_transaction_reason
      axios
        .get(
          `${this.apiUrl}/analysis/overview_overdue_transaction/${this.getDateQueryString(
            dateRange
          )}`,
          // `${this.apiUrl}/analysis/overview_overdue_transaction_reason/${this.getDateQueryString(
          //   dateRange
          // )}`,
          {
            headers: this.apiHeader
          }
        )
        .then(({ data }) => {
          const {
            period,
            installmentCollected,
            nonPerformingLoan,
            nonPerformingLoanRatio,
            overdueCollected,
            totalOverdue
          } = data

          this.installmentCollected = installmentCollected
          this.nonPerformingLoan = nonPerformingLoan
          this.nonPerformingLoanRatio = nonPerformingLoanRatio
          this.overdueCollected = overdueCollected
          this.totalOverdue = totalOverdue

          const test = {
            '0-30': {},
            '31-60': {},
            '61-90': {},
            '91-120': {},
            '121-150': {},
            '150+': {}
          }

          function accumulate(data) {
            return data.reduce((acc, item) => {
              const contribution = (item.chargeAmount || 0) - (item.paidAmount || 0)
              return acc + contribution
            }, 0)
          }

          Object.values(period).forEach((data, i) => {
            if (i == 0) test['0-30'] = data
            if (i == 1) test['31-60'] = data
            if (i == 2) test['61-90'] = data
            if (i == 3) test['91-120'] = data
            if (i == 4) test['121-150'] = data
            if (i == 5) test['150+'] = data
          })
          this.overviewData = test
          this.collectionOverdue = {
            y: Object.values(test).map((x) => {
              if (Object.keys(x).length > 0) return accumulate(x)
              return 0
            }),
            x: Object.keys(test)
          }

          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },

    fetchTopOverdue(dateRange) {
      this.increaseIsFetching()

      axios
        .get(`${this.apiUrl}/analysis/rank_overdue_hirer/${this.getDateQueryString(dateRange)}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.topOverdue = {
            y: Object.values(data).map((x) => {
              const result = x.reduce((acc, item) => {
                // Assuming each item in Overdue has properties toPay and payAmount
                const contribution = (item.chargeAmount || 0) - (item.paidAmount || 0)
                return acc + contribution
              }, 0)

              return result
            }),
            x: Object.keys(data)
          }

          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },

    fetchTopPartial(dateRange) {
      this.increaseIsFetching()

      axios
        .get(`${this.apiUrl}/analysis/rank_partially_hirer/${this.getDateQueryString(dateRange)}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.topPartial = {
            y: Object.values(data).map((x) => {
              const result = x.reduce((acc, item) => {
                // Assuming each item in Overdue has properties toPay and payAmount
                const contribution = (item.chargeAmount || 0) - (item.paidAmount || 0)
                return acc + contribution
              }, 0)

              return result
            }),
            x: Object.keys(data)
          }

          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    getDateQueryString(dateRange) {
      let queryString = ''
      const startDate = this.convertDate(dateRange?.[0])
      const endDate = this.convertDate(dateRange?.[1])

      if (startDate && endDate) {
        queryString = `?StartDate=${startDate}&EndDate=${endDate}`
      } else if (startDate) {
        queryString = `?StartDate=${startDate}`
      } else if (endDate) {
        queryString = `?EndDate=${endDate}`
      }
      return queryString
    },
    convertDate(date) {
      if (!date) {
        return
      }
      return new Date(date).toLocaleDateString('en-CA')
    },
    getDateMonthsBefore(monthsBefore) {
      const currentDate = new Date()
      const targetDate = new Date(currentDate)
      targetDate.setMonth(currentDate.getMonth() + monthsBefore)
      return targetDate.toLocaleDateString('en-CA') // 'en-CA' for the "YYYY-MM-DD" format
    },

    // Imported function cannot be used directly from template, so we need to create a wrapper function
    formatCurrency(value) {
      return formattedAmount(value)
    },
    aggregateByApplicantProductId(data) {
      const result = {}

      data.forEach((item) => {
        const productId = item.applicantProductId

        if (!result[productId]) {
          // If the key doesn't exist in the result, create an array for it
          result[productId] = []
        }

        // Push the item to the array corresponding to the productId
        result[productId].push(item)
      })

      // Convert the result object to an array of arrays
      const aggregatedData = Object.values(result)

      return aggregatedData
    }
  }
}
</script>

<template>
  <div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="row align-items-end">
            <div class="col-lg-3 col-md-6 col-12">
              <!-- <mini-statistics-card /> -->
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <!-- <mini-statistics-card /> -->
            </div>
            <div id="yearPicker" class="datepicker col-4">
              <Datepicker
                v-model="dateSelected"
                nable-time-picker="false"
                is-range="true"
                range
              ></Datepicker>
              <select v-model="selectedYear" class="form-select">
                <option v-for="year in availableYears" :key="year" :value="year">
                  {{ year }}
                </option>
                <option value="-6">Past 6 Months</option>
                <option value="-12">Past 12 Months</option>
                <option value="-24">Past 24 Months</option>
              </select>
            </div>
          </div>
          <div class="row mt-2">
            <div class="combined-data">
              <div class="flex-col">
                <div class="bold" col="3">Installment Collected</div>
                <div>RM {{ formattedAmount(installmentCollected) }}</div>
              </div>

              <div class="flex-col">
                <div class="bold" col="3">Non-Performing Loan</div>
                <div>RM {{ formattedAmount(nonPerformingLoan) }}</div>
              </div>

              <div class="flex-col">
                <div class="bold" col="3">Non-Performing Loan</div>
                <div>{{ nonPerformingLoanRatio.toFixed(2) }} %</div>
              </div>

              <div class="flex-col">
                <div class="bold" col="3">Overdue Collected</div>
                <div>RM {{ formattedAmount(overdueCollected) }}</div>
              </div>

              <div class="flex-col">
                <div class="bold" col="3">Total Overdue</div>
                <div>RM {{ formattedAmount(totalOverdue) }}</div>
              </div>

              <div class="flex-col">
                <div class="bold" col="3">Overdue Receivable</div>
                <div>{{ overdueReceivableRatio.toFixed(2) }} %</div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-6">
              <div class="w-100">
                <LineChart
                  id="collectionOverdue"
                  height="400"
                  chart-title="Overdue Transaction"
                  :chart-data="{
                    x: collectionOverdue.x,
                    y: collectionOverdue.y
                  }"
                  :extra-option="{
                    currency: { prefix: 'RM' }
                  }"
                  @dataClick="overdueTransactionDetail"
                />
              </div>
            </div>

            <div class="col-md-6">
              <div class="w-100">
                <PieChart
                  id="overdueReason"
                  height="400"
                  chart-title="Overdue Reason"
                  :chart-data="{
                    x: overdueReasons.x,
                    y: overdueReasons.y
                  }"
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12">
              <div class="w-100">
                <LineChart
                  id="topOverdue"
                  height="400"
                  chart-title="Overdue Clients"
                  :chart-data="{
                    x: topOverdue.x,
                    y: topOverdue.y
                  }"
                  :extra-option="{
                    currency: { prefix: 'RM' },
                    dataLabels: {
                      display: false
                    },
                    scales: {
                      x: { text: 'Clients' },
                      y: { text: 'Amount (RM)' }
                    }
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="c-modal" @click="hideModal">
      <div class="container" @click.stop="">
        <AgGrid
          :data="monthDetails"
          :keys="keys"
          :searchable="false"
          :exportable="false"
          @onRowClicked="overdueDetailClick"
        ></AgGrid>
      </div>
    </div>
  </div>
</template>

<style scoped>
.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  isolation: isolate;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.c-modal .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 80dvw;
  height: 80dvh;
  z-index: 1;
  overflow: auto;
}

td.clickable:hover {
  cursor: pointer;
  color: hsl(233, 77%, 64%);
}

.combined-data {
  display: flex;
  gap: 1rem;
}

.combined-data > .flex-col {
  border: 1px solid black;
  padding: 1rem;
  background: #fff;
}
</style>
