<script>
import AgGrid from '@/components/custom/AgGrid'
import { mapState, mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import { calPaidAmount } from '@/assets/js/calculator'
import { formattedDate, getPaymentDateClass } from '@/assets/js/dateFunction'
import { getUniqueIndex } from '@/assets/js/general-func'
import TransactionModal from '@/views/application/components/TransactionModal.vue'
import { dateSort } from '/src/assets/js/dateFunction.js'

export default {
  name: 'CollectionList',
  components: {
    TransactionModal,
    AgGrid
  },
  data() {
    return {
      totalPages: 1,
      currentPage: 1,
      maxPageLinks: 10,
      collections: [],
      status: 'pending',
      keys: [
        {
          headerName: '#',
          valueGetter: getUniqueIndex,
          pinned: 'left',
          headerCheckboxSelection: false,
          checkboxSelection: false,
          showDisabledCheckboxes: false,
          width: '5%'
        },
        { field: 'hirerName', headerName: 'Hirer' },
        { field: 'brandName', headerName: 'Brand' },
        { field: 'productName', headerName: 'Product' },
        { field: 'hpNo', headerName: 'Hp Agreement No.' },
        {
          field: 'totalPaid',
          headerName: 'Paid Amount',
          cellDataType: 'currency',
          currency: 'RM'
        },
        {
          field: 'loanAmount',
          headerName: 'Loan Amount',
          cellDataType: 'currency',
          currency: 'RM'
        },
        {
          field: 'totalAmountPayable',
          headerName: 'Total Payable',
          cellDataType: 'currency',
          currency: 'RM'
        },
        { field: 'tenure', headerName: 'Tenure (Year)' },
        // {
        //   field: 'paidMonths',
        //   headerName: 'Payment (Full | Partial | Overdue)',
        //   cellDataType: 'objectRenderer'
        // },
        {
          field: 'hasPendingPayment',
          headerName: 'Current Payment',
          cellDataType: 'booleanPendingPayment'
        }
      ],
      selectedKey: 'All',
      apiHeader: {},
      pageSize: 10,
      filters: {
        name: {
          value: '',
          keys: []
        }
      },
      targetTransactions: {},
      selectedTransactionId: null
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl']),
    statusCap() {
      return this.status.charAt(0).toUpperCase() + this.status.slice(1)
    }
  },

  watch: {
    ['selectedKey']() {
      this.filters.name.keys = []
      if (this.selectedKey == 'All') {
        this.keys.forEach((key) => {
          this.filters.name.keys.push(key.data)
        })
      } else {
        this.filters.name.keys.push(this.selectedKey)
      }
    }
  },

  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.fetchData()
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    dateSort,

    togglePaymentModal(applicantId) {
      const targetTransactions = this.collections.find((x) => x.id == applicantId)
      this.targetTransactions = targetTransactions.ApplicantProductTransactions
      const test = this.targetTransactions.find((x) => x.status == 'Pending')
      console.log(test)
      this.selectedTransactionId = test?.id ?? null
    },

    deleteApplicantProduct(targetId) {
      if (!confirm('Are you sure you want to delete this ?')) return
      axios
        .delete(`${this.apiUrl}/applicantProduct/`, {
          params: { id: targetId },
          headers: this.apiHeader
        })
        .then((res) => {
          console.log('RESPONSE RECEIVED: ', res.data)
          if (res.data.status == 1) {
            this.collections = this.collections.filter((x) => x.id != targetId)
          }
          this.$router.push({
            name: 'CollectionList',
            params: { status: 'pending' }
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    fetchData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/applicantProduct/transaction/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.collections = data.ApplicantProducts.map((x) => {
            const nextPayment = this.getNextPayment(x.ApplicantProductTransactions)
            return {
              ...x,
              hirerName: x.Hirer.name,
              productName: x.Product.name,
              brandName: x.Brand.name,
              loanAmount: x.loanAmount,
              paidAmount: calPaidAmount(x.ApplicantProductTransactions),
              totalPayable: x.totalPayable,
              tenureMonths: x.ApplicantProductTransactions.length,
              nextPaymentDate: nextPayment ? formattedDate(nextPayment.dueDate, Date.now()) : null,
              nextPaymentAmount: nextPayment ? nextPayment.chargeAmount : null,
              nextPaymentDateClass: nextPayment ? getPaymentDateClass(nextPayment.dueDate) : null,
              // Status of payment by, fully paid, partially paid, overdue
              paidMonths: {
                full: x.ApplicantProductTransactions.filter((x) => x.status == 'Fully Paid').length,
                partial: x.ApplicantProductTransactions.filter((x) => x.status == 'Partially Paid')
                  .length,
                overdue: x.ApplicantProductTransactions.filter((x) => x.status == 'Overdue').length
              }
            }
          })
          console.log(this.collections, 'collections')
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    getNextPayment(transactions) {
      const filtered = transactions.filter((x) => !x.paidAt)
      return filtered.length > 0 ? filtered[0] : null
    },
    toLink(row) {
      console.log(row.id)
      this.$router.push({
        name: 'CollectionProfile',
        params: { id: row.id }
      })
    }
  }
}
</script>

<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <TransactionModal
        :transactions="targetTransactions"
        :selected-transaction-id="selectedTransactionId"
        @fetch-data="fetchData"
      />
      <div class="col-12">
        <!-- <TransactionModal /> -->
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="table-controls row">
              <div class="col-md-8 col-lg-8 mb-2">
                <div class="d-lg-flex">
                  <div>
                    <h5 class="pb-1">Collection</h5>
                    <p>All {{ statusCap }} Collections</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="px-4 mt-3">
              <div v-if="collections.length > 0" class="table-responsive">
                <AgGrid
                  :data="collections"
                  :keys="keys"
                  :searchable="true"
                  :exportable="false"
                  @onRowClicked="toLink"
                ></AgGrid>
              </div>

              <div v-else>
                <h5 class="text-bold">No Record Found</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.clickable {
  cursor: pointer;
  transition: 0.1s;
}
.clickable:hover {
  background-color: #aaaaaa;
}

.pay-full {
  color: #00b300;
}

.pay-partial {
  color: #ff9900;
}

.pay-overdue {
  color: #ff0000;
}
</style>
