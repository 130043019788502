<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import CriteriaModal from './components/CriteriaModal.vue'

export default {
  name: 'CriteriaList',
  components: { CriteriaModal },
  data() {
    return {
      totalPages: 1,
      currentPage: 1,
      maxPageLinks: 10,
      criterias: [],
      selectedCriteriaId: null,
      keys: [
        { data: 'category', name: 'category' },
        { data: 'name', name: 'name' },
        { data: 'description', name: 'description' }
      ],
      selectedKey: 'All',
      apiHeader: {},
      isEdit: false,
      pageSize: 10,
      filters: {
        name: {
          value: '',
          keys: []
        }
      }
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader', 'formattedDate']),
    ...mapState(['apiUrl', 'token', 'user'])
  },

  watch: {
    ['selectedKey']() {
      this.filters.name.keys = []
      if (this.selectedKey == 'All') {
        this.keys.forEach((key) => {
          this.filters.name.keys.push(key.data)
        })
      } else {
        this.filters.name.keys.push(this.selectedKey)
      }
    }
  },

  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.fetchData()
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    fetchData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/criteria/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.criterias = data.Criterias
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    toModal(id, editable) {
      console.log(editable)
      if (editable) {
        this.selectedCriteriaId = id
        const myModal = new this.$store.state.bootstrap.Modal('#CriteriaModal', {
          keyboard: false
        })
        myModal.show()
      }
    }
  }
}
</script>

<template>
  <criteria-modal :criterias="criterias" :selected-criteria-id="selectedCriteriaId" />
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="table-controls row">
              <div class="col-md-4 col-lg-2 mb-2">
                <div class="d-lg-flex">
                  <div>
                    <h5 class="pb-1">Criteria</h5>
                    <p>All criterias</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 mb-2 ms-auto">
                <div class="d-lg-flex">
                  <div class="my-auto mt-4 ms-auto mt-lg-0">
                    <div class="my-auto ms-auto">
                      <button
                        class="btn btn-submain text-white"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#CriteriaModal"
                        @click="() => (selectedCriteriaId = null)"
                      >
                        +&nbsp; Create New
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-controls row">
              <div class="col-md-7 col-lg-7 mb-3"></div>
              <div class="col-md-3 col-lg-3 mb-3">
                <input
                  id="search"
                  v-model="filters.name.value"
                  class="form-control"
                  placeholder="Type to search..."
                />
              </div>
              <div class="col-md-2 col-lg-2 mb-3 ms-auto">
                <select id="dropdown" v-model="selectedKey" class="form-select ml-2">
                  <option value="All">All</option>
                  <option v-for="key in keys" :key="key.id" :value="key.data">
                    {{ key.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="px-4 mt-3">
              <div v-if="criterias.length > 0" class="table-responsive">
                <VTable
                  v-model:currentPage="currentPage"
                  :data="criterias"
                  :page-size="pageSize"
                  :filters="filters"
                  class="table"
                  @total-pages-changed="totalPages = $event"
                >
                  <template #head>
                    <template v-for="i in keys" :key="i">
                      <VTh
                        class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                        :sort-key="i.data"
                      >
                        {{ i.name }}
                      </VTh>
                    </template>
                  </template>
                  <template #body="{ rows }">
                    <tr
                      v-for="row in rows"
                      :key="row.id"
                      :class="{ clickable: row.editable }"
                      @click="toModal(row.id, row.editable)"
                    >
                      <template v-for="i in keys" :key="i">
                        <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                          {{ row[i.data] ?? 'N/A' }}
                        </td>
                      </template>
                    </tr>
                  </template>
                </VTable>
              </div>

              <div v-else>
                <h5 class="text-bold">No Record Found</h5>
              </div>

              <div class="table-controls row mt-6">
                <div class="col-md-6 col-lg-2 mb-2">
                  <div class="d-lg-flex">
                    <p>
                      {{
                        criterias.length < currentPage * pageSize
                          ? `Showing ${currentPage * pageSize - pageSize + 1} to ${
                              criterias.length
                            } of ${criterias.length} entries`
                          : `Showing ${currentPage * pageSize - pageSize + 1} to ${
                              currentPage * pageSize
                            } of ${criterias.length} entries`
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-2 ms-auto">
                  <div class="d-lg-flex justify-content-end">
                    <VTPagination
                      v-model:currentPage="currentPage"
                      :boundary-links="true"
                      :total-pages="totalPages"
                      :max-page-links="maxPageLinks"
                      :hide-single-page="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.clickable {
  cursor: pointer;
  transition: 0.1s;
}
.clickable:hover {
  background-color: #aaaaaa;
}
</style>
