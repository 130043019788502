import { color } from 'chart.js/helpers'
export const Utils = {
  months: ({ count }) => {
    const months = []
    const currentDate = new Date()
    for (let i = 0; i < count; i++) {
      const month = currentDate.toLocaleString('default', { month: 'short' })
      months.push(month)
      currentDate.setMonth(currentDate.getMonth() + 1)
    }
    return months
  },
  numbers: ({ count, min, max }) => {
    const numbers = []
    for (let i = 0; i < count; i++) {
      numbers.push(Utils.randomNumber(min, max))
    }
    return numbers
  },
  randomNumber: (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  },
  CHART_COLORS: {
    red: 'rgb(255, 99, 132)',
    blue: 'rgb(54, 162, 235)'
  },
  transparentize: (clr, opacity) => {
    const alpha = opacity === undefined ? 0.5 : 1 - opacity
    return color(clr).alpha(alpha).rgbString()
  }
}
