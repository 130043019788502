<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="table-controls row">
              <div class="col-md-4 col-lg-2 mb-2">
                <div class="d-lg-flex">
                  <div>
                    <h5 class="pb-1">Vendor</h5>
                    <p>All vendors</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 mb-2 ms-auto">
                <div class="d-lg-flex">
                  <div class="my-auto mt-4 ms-auto mt-lg-0">
                    <div class="my-auto ms-auto">
                      <button
                        class="btn btn-submain text-white"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#VendorModal"
                      >
                        +&nbsp; Create New
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-controls row">
              <div class="col-md-7 col-lg-7 mb-3"></div>
              <div class="col-md-3 col-lg-3 mb-3">
                <input
                  id="search"
                  v-model="filters.name.value"
                  class="form-control"
                  placeholder="Type to search..."
                />
              </div>
              <div class="col-md-2 col-lg-2 mb-3 ms-auto">
                <select id="dropdown" v-model="selectedKey" class="form-select ml-2">
                  <option value="All">All</option>
                  <option v-for="key in keys" :key="key.id" :value="key.data">
                    {{ key.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="px-4 mt-3">
              <div v-if="vendors.length > 0" class="table-responsive">
                <VTable
                  v-model:currentPage="currentPage"
                  :data="vendors"
                  :page-size="pageSize"
                  :filters="filters"
                  class="table"
                  @total-pages-changed="totalPages = $event"
                >
                  <template #head>
                    <tr class="tableHeader">
                      <template v-for="i in keys" :key="i">
                        <VTh
                          class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                          :sort-key="i.data"
                        >
                          {{ i.name }}
                        </VTh>
                      </template>
                    </tr>
                  </template>
                  <template #body="{ rows }">
                    <tr v-for="row in rows" :key="row" class="clickable" @click="toLink(row.id)">
                      <template v-for="i in keys" :key="i">
                        <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                          {{ row[i.data] ?? 'N/A' }}
                        </td>
                      </template>
                    </tr>
                  </template>
                </VTable>
              </div>

              <div v-else>
                <h5 class="text-bold">No Record Found</h5>
              </div>

              <div class="table-controls row mt-6">
                <div class="col-md-6 mb-2">
                  <div class="d-lg-flex">
                    <p>
                      {{
                        vendors.length < currentPage * pageSize
                          ? `Showing ${currentPage * pageSize - pageSize + 1} to ${
                              vendors.length
                            } of ${vendors.length} entries`
                          : `Showing ${currentPage * pageSize - pageSize + 1} to ${
                              currentPage * pageSize
                            } of ${vendors.length} entries`
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 mb-2 ms-auto">
                  <div class="d-lg-flex justify-content-end">
                    <VTPagination
                      v-model:currentPage="currentPage"
                      :boundary-links="true"
                      :total-pages="totalPages"
                      :max-page-links="maxPageLinks"
                      :hide-single-page="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <VendorModal @fetch-data="fetchData" />
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import VendorModal from './components/VendorModal.vue'
import moment from 'moment'
export default {
  name: 'VendorList',
  components: {
    VendorModal
  },
  data() {
    return {
      totalPages: 1,
      currentPage: 1,
      maxPageLinks: 10,
      vendors: [],
      keys: [{ id: 1, data: 'name', name: 'Name' }],
      selectedKey: 'All',
      apiHeader: {},
      isEdit: false,
      pageSize: 10,
      filters: {
        name: {
          value: '',
          keys: ['name']
        }
      }
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl'])
  },

  watch: {
    ['selectedKey']() {
      this.filters.name.keys = []
      if (this.selectedKey == 'All') {
        this.keys.forEach((key) => {
          this.filters.name.keys.push(key.data)
        })
      } else {
        this.filters.name.keys.push(this.selectedKey)
      }
    }
  },

  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.fetchData()
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),

    fetchData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/vendor/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.vendors = data.Vendors
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    formattedDate(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    formattedAmount(amount) {
      if (isNaN(amount)) {
        return amount
      }

      const parts = amount.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return parts.join('.')
    },
    toLink(id) {
      this.$router.push({
        name: 'VendorProfile',
        params: { id: id }
      })
    },
    createLink() {
      this.$router.push({
        name: 'vendorCreatePage'
      })
    }
  }
}
</script>

<style>
.clickable {
  cursor: pointer;
  transition: 0.1s;
}
.clickable:hover {
  background-color: #aaaaaa;
}
</style>
