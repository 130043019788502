<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'EditLastPay',
  props: {
    lastPayment: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      amount: 0,
      date: ''
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl'])
  },
  watch: {
    lastPayment() {
      this.amount = this.lastPayment.amount
      const [day, month, year] = this.lastPayment.paidAt.split('-')
      this.date = `${year}-${month}-${day}`
    }
  },
  methods: {
    checkDateYMD(date) {
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/
      return dateRegex.test(date)
    },
    editPayment() {
      if (this.amount < 0) {
        alert('Amount cannot be negative')
        return
      }

      if (!this.checkDateYMD(this.date)) {
        alert('Invalid date format')
        return
      }

      try {
        axios
          .put(
            `${this.apiUrl}/applicantProductPayment`,
            {
              // Id of the transaction
              id: this.lastPayment.id,
              applicantProductId: this.$route.params.id,
              amount: this.amount,
              paidAt: this.date
            },
            { headers: this.getAPIHeader() }
          )
          .then((res) => {
            const { status, msg } = res.data
            if (status == 0) {
              alert(msg)
            } else {
              alert('Payment successfully edited')
              this.$emit('invalidateData')
            }
          })
      } catch (error) {
        alert('An error occurred while making payment')
        console.log(error)
      }
    }
  }
}
</script>

<template>
  <div
    id="editLastPayment"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="editLastPayment"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="editLastPayment" class="modal-title fs-5">Edit Lastest Payment</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="flex-col">
              <label for="amount">Amount</label>
              <input v-model="amount" name="amount" id="amount" type="number" />
            </div>
            <div class="flex-col">
              <label for="date">Date of payment (YYYY-MM-DD)</label>
              <input v-model="date" name="date" id="date" type="text" placeholder="YYYY-MM-DD" />
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="editPayment"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
