<template>
  <div class="p-3 multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="card">
      <div class="card-header">
        <h5 class="font-weight-bolder my-3">Approval</h5>
      </div>
      <div class="card-body">
        <!-- <div class="text-start">
          <button class="btn btn-outline-primary" @click="generateReport()">
            Generate Report
          </button>
        </div> -->

        <table>
          <template v-for="a in approvals" :key="a">
            <tr>
              <th>{{ a.position }}</th>
              <td>
                <span
                  class="badge"
                  :class="[
                    a.status != null
                      ? a.status == 1
                        ? 'badge-success'
                        : 'badge-danger'
                      : 'badge-secondary'
                  ]"
                  >{{
                    a.status != null ? (a.status == 1 ? 'Approved' : 'Rejected') : 'Pending'
                  }}</span
                >
              </td>
              <td>
                <div v-if="a.status != null">
                  {{ new Date(a.at).toLocaleString('en-US') }}
                </div>
              </td>
              <td v-if="a.status == null && a.lock && user.Role.name == a.position">
                <button type="button" class="btn btn-primary me-2" @click="submitForm('approve')">
                  Approve
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#commentModal"
                >
                  Reject
                </button>
              </td>

              <!-- <td v-if="a.status != 1 && user.Role.name == approvals[i - 1]">Back</td> -->
              <td v-if="a.status == 0 && user.Role.name == 'Credit Officer' && a.lock == 1">
                <button type="button" class="btn btn-danger" @click="creditOfficerReattempt()">
                  Re-attempt
                </button>
              </td>
            </tr>
          </template>
        </table>

        <div v-if="user.Role.name == 'Superadmin'" class="text-end">
          <button class="btn btn-outline-primary" @click="submitForm('approve')">By Pass</button>
        </div>
      </div>

      <div class="card-body">
        <h5 class="font-weight-bolder my-3">SRC File</h5>
        <ul>
          <li>
            <a target="_blank" :href="application.src_url">
              <i class="far fa-file-pdf fa-2x"></i>
            </a>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <h5 class="font-weight-bolder my-3">History</h5>

        <ul>
          <li v-for="activity in history" :key="activity">
            {{ activity.description }}<br />({{
              new Date(activity.createdAt).toLocaleString('en-US')
            }})
          </li>
        </ul>
      </div>
    </div>

    <!-- Comment Modal -->
    <div
      id="commentModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="commentModal"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5 text-capitalize">Reason to reject</h1>
          </div>
          <div class="modal-body">
            <textarea v-model="comment" class="form-control" rows="3"></textarea>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="submitForm('reject')"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'Approval',
  props: {
    application: {
      type: Object,
      default: () => {}
    },
    agreements: {
      type: Array,
      default: () => []
    }
  },
  emits: ['fetch-data', 'next-step'],
  data() {
    return {
      comment: '',
      history,
      approvals: [
        {
          position: 'Credit Officer',
          name: '',
          status: null,
          at: '',
          lock: false
        },
        {
          position: 'Head of Operation',
          name: '',
          status: null,
          at: '',
          lock: false
        },
        {
          position: 'Malaysia Director',
          name: '',
          status: null,
          at: '',
          lock: false
        },
        {
          position: 'China Director',
          name: '',
          status: null,
          at: '',
          lock: false
        }
      ]
    }
  },
  computed: {
    ...mapState(['isLoading', 'apiUrl', 'user']),
    ...mapGetters(['getAPIHeader'])
  },
  watch: {
    application() {
      this.getApplication()
      this.getHistory()
    }
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.getHistory()
    this.getApplication()
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    creditOfficerReattempt() {
      axios
        .put(
          `${this.apiUrl}/applicant/`,
          {
            id: this.application.id,
            lockStatus: 0,
            progressId: 9
          },
          {
            headers: this.apiHeader
          }
        )
        .then((res) => {
          console.log(res)
          this.$emit('fetch-data')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getApplication() {
      let letGo = true
      for (let index = 0; index < this.approvals.length; index++) {
        this.approvals[index].status = this.application[`status${index + 1}`]
        this.approvals[index].name = this.application[`status${index + 1}User`]?.name
        this.approvals[index].at = this.application[`status${index + 1}At`]
        this.approvals[index].lock = letGo
        console.log(this.approvals[index].position, letGo)
        letGo = this.application[`status${index + 1}`]
      }
    },
    submitForm(action) {
      axios
        .put(
          `${this.apiUrl}/applicant/${action}`,
          {
            id: this.application.id,
            comment: this.comment
          },
          {
            headers: this.apiHeader
          }
        )
        .then(() => {
          if (this.user.Role.name == 'China Director' || this.user.Role.name == 'Superadmin') {
            this.submitGenerateAgreementForm()
          } else {
            this.$emit('fetch-data')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    submitGenerateAgreementForm() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/agreement/generate/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(() => {
          this.$emit('fetch-data')
          this.$emit('next-step')
          this.decreaseIsFetching()
        })
        .catch((err) => {
          this.decreaseIsFetching()
          console.log(err)
        })
    },

    getHistory() {
      console.log('getting history')
      axios
        .get(`${this.apiUrl}/applicant//activity/${this.$route.params.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.history = data.ApplicantActivities
          console.log(this.history)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    formattedDate(date) {
      return date ? moment(date).format('DD-MM-YYYY hh:mm:ss') : null
    }
  }
}
</script>

<style></style>
