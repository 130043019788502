<template>
  <nav
    id="navbarBlur"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none'
        : `navbar navbar-main navbar-expand-lg px-0 mx-4 border-radius-xl shadow-none position-sticky ${
            darkMode ? 'bg-default' : 'bg-white'
          } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :current-page="currentRouteName" :current-directory="currentDirectory" />
      <div
        class="sidenav-toggler sidenav-toggler-inner d-xl-block d-none"
        :class="isRTL ? 'me-3' : ''"
      >
        <a href="#" class="p-0 nav-link text-body" @click.prevent="navbarMinimize">
          <div class="sidenav-toggler-inner">
            <i
              class="sidenav-toggler-line"
              :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'"
            ></i>
            <i
              class="sidenav-toggler-line"
              :class="isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'"
            ></i>
          </div>
        </a>
      </div>
      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
      >
        <div class="pe-md-3 d-flex align-items-center" :class="isRTL ? 'me-md-auto' : 'ms-md-auto'">
          <!-- <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              :placeholder="isRTL ? 'أكتب هنا...' : 'Type here...'"
            />
          </div> -->
        </div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item dropdown d-flex align-items-center">
            <a
              id="dropdownMenuButton"
              href="#"
              :class="`p-0 nav-link d-flex ${
                isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
              }`"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="sidenav-normal mt-1">
                {{ capitalizeFirstLetter(user.name) }}
              </span>
              <div
                class="rounded-circle bg-white text-dark ms-2 text-center"
                style="width: 30px; height: 30px"
              >
                <div class="mx-auto my-1">
                  {{ getFirstLetter(user.name) }}
                </div>
              </div>
            </a>

            <ul
              class="px-2 pb-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="py-3 px-2">
                <h6 class="text-dark">
                  {{ capitalizeFirstLetter(user.name) }}
                </h6>
                <span class="text-dark">{{ user.email ?? 'N/A' }}</span>
              </li>
              <li class="mb-2">
                <router-link
                  class="dropdown-item border-radius-md"
                  :to="{
                    name: 'UserMyProfile'
                  }"
                >
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i class="fa fa-user"></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center ms-3">
                      <p class="mb-0 text-secondary">Your Profile</p>
                    </div>
                  </div>
                </router-link>
                <a class="dropdown-item border-radius-md" href="javascript:;" @click="logoutNow">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <i class="fas fa-sign-out-alt"></i>
                    </div>
                    <div class="d-flex flex-column justify-content-center ms-3">
                      <p class="mb-0 text-secondary">Logout</p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              id="iconNavbarSidenav"
              href="#"
              class="p-0 nav-link text-white"
              @click.prevent="navbarMinimize"
            >
              <div class="sidenav-toggler-inner">
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
                <i
                  :class="`sidenav-toggler-line ${
                    isNavFixed && !darkMode ? ' opacity-8 bg-dark' : 'bg-white'
                  }`"
                ></i>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from '../Breadcrumbs.vue'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { logout } from '../../router/authService'

export default {
  name: 'Navbar',
  components: {
    Breadcrumbs
  },
  data() {
    return {
      showMenu: false
    }
  },
  computed: {
    ...mapState(['isRTL', 'isNavFixed', 'darkMode', 'user']),
    ...mapGetters(['capitalizeFirstLetter']),
    currentRouteName() {
      return this.$route.meta.name
    },
    currentDirectory() {
      let dir = this.$route.path.split('/')[1]
      return dir.charAt(0).toUpperCase() + dir.slice(1)
    }
  },
  created() {
    this.minNav
  },
  mounted() {
    this.navbarMinimize()
  },

  // beforeUpdate() {
  //   this.toggleNavigationOnMobile()
  // },
  methods: {
    ...mapMutations(['navbarMinimize', 'toggleConfigurator']),
    ...mapActions(['toggleSidebarColor']),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize()
      }
    },
    async logoutNow() {
      await logout()
      this.$router.push({
        name: 'LoginPage'
      })
    },
    getFirstLetter(name) {
      return name?.charAt(0)?.toUpperCase()
    }
  }
}
</script>
