<template>
  <div
    id="BrandModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="BrandModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="BrandModalLabel" class="modal-title fs-5">
            {{ selectedBrandId ? "Edit" : "New" }} Type
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-group col-md-6 mb-2 mt-2">
            <label class="form-label">Type Name*</label>
            <input
              id="name"
              v-model="brandData.name"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': error.name }"
            />
            <span class="text-danger" :class="{ 'd-none': !error.name }"
              >Name is required</span
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="submitForm()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "BrandModal",
  props: {
    hirerId: {
      type: Number,
      default: null,
    },
    brand: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["fetch-data"],
  data() {
    return {
      apiHeader: {},
      brandData: {
        name: null,
      },
      error: {
        name: false,
      },
    };
  },
  computed: {
    ...mapGetters(["validateValue", "getAPIHeader", "formattedDate"]),
    ...mapState(["apiUrl"]),
  },
  watch: {
    brand(newData) {
      if (newData.id) {
        this.brandData = {
          id: this.brand.id,
          name: this.brand.name,
        };
      } else {
        this.brandData = {
          name: null,
        };
      }
    },
  },
  mounted() {
    this.apiHeader = this.getAPIHeader();
  },

  methods: {
    submitForm() {
      let validate = true;
      Object.keys(this.brandData).forEach((x) => {
        if (this.brandData[x] == null || this.brandData[x] == "") {
          this.error[x] = true;
          validate = false;
        } else {
          this.error[x] = false;
        }
      });
      if (validate) {
        let action = "post";
        if (this.brand.id) {
          action = "put";
        }
        axios[action](`${this.apiUrl}/brand`, this.brandData, {
          headers: this.apiHeader,
        })
          .then((res) => {
            console.log("RESPONSE RECEIVED: ", res.data);
            window.location.reload();
          })
          .catch((err) => {
            console.log("AXIOS ERROR: ", err);
          });
      }
    },
  },
};
</script>

<style></style>
