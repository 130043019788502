<template>
  <div class="card h-100">
    <div class="p-3 card-body">
      <h6>{{ title }}</h6>
      <div class="pt-3 chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
export default {
  name: 'ThinBarChart',
  props: {
    id: {
      type: String,
      default: 'thin-bar-chart'
    },
    title: {
      type: String,
      default: 'Thin Bar Chart'
    },
    height: {
      type: [String, Number],
      default: '200'
    },
    chart: {
      type: Object,
      required: true,
      labels: Array,
      datasets: {
        type: Object,
        label: String,
        data: Array,
        backgroundColor: Array
      },
      currency: {
        prefix: String
      }
    }
  },
  watch: {
    'chart.datasets.data': {
      handler(data) {
        if (data.length == 0) return
        this.buildChart()
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    buildChart() {
      // Chart Consumption by day
      var ctx = document.getElementById(this.id).getContext('2d')

      let chartStatus = Chart.getChart(this.id)
      if (chartStatus != undefined) {
        chartStatus.destroy()
      }
      //let bgColors =[];
      // this.chart.datasets.data.forEach(x => {
      //   bgColors.push(x >= 2 ? "#ff6600" : x > 0 ? "text-warning" : "text-white")
      // });
      new Chart(ctx, {
        type: 'bar',
        plugins: [ChartDataLabels],
        data: {
          labels: this.chart.labels,
          datasets: [
            {
              label: this.chart.datasets.label,
              tension: 0.4,
              borderWidth: 0,
              borderRadius: 0,
              borderSkipped: false,
              backgroundColor: this.chart.datasets.backgroundColor,
              data: this.chart.datasets.data,
              maxBarThickness: 50
            }
          ]
        },
        options: {
          layout: {
            padding: {
              top: 21
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              // color: '#FFFFFF',
              anchor: 'end',
              align: 'top',
              formatter: this.chart.currency ? this.formatCurrency : this.defaultFormatter
            },
            decimation: {
              enabled: false,
              algorithm: 'min-max'
            }
          },
          interaction: {
            intersect: false,
            mode: 'index'
          },
          onClick: (event, elements) => {
            if (elements.length > 0) {
              const clickedIndex = elements[0].index
              const clickedDataset = elements[0].datasetIndex
              // Mostly only need label
              const clickedLabel = this.chart.labels[clickedIndex]
              const clickedValue = this.chart.datasets.data[clickedIndex]

              // Emit a custom event with relevant information
              this.$emit('barClick', {
                index: clickedIndex,
                datasetIndex: clickedDataset,
                label: clickedLabel,
                value: clickedValue,
                title: this.title
              })
            }
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false
              },
              ticks: {
                display: false
              }
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false
              },
              ticks: {
                beginAtZero: true,
                font: {
                  size: 12,
                  family: 'Open Sans',
                  style: 'normal'
                },
                color: '#9ca2b7'
              }
            },
            // eslint-disable-next-line no-dupe-keys
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5]
              },
              ticks: {
                display: true,
                padding: 10,
                color: '#9ca2b7'
              }
            },
            // eslint-disable-next-line no-dupe-keys
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5]
              },
              ticks: {
                display: true,
                padding: 10,
                color: '#9ca2b7'
              }
            }
          }
        }
      })
    },
    formatCurrency(value) {
      // Try parsing the value into a float
      const floatValue = parseFloat(value)
      const prefix = this.chart.currency.prefix ?? '$'

      // Check if the parsed value is NaN
      if (isNaN(floatValue)) {
        // If NaN, return the original value
        return value
      }

      // Define magnitude abbreviations and corresponding thresholds
      const abbreviations = {
        B: 1e9, // Billion
        M: 1e6, // Million
        k: 1e3 // Thousand
        // Add more abbreviations if needed
      }

      // Find the appropriate abbreviation and threshold
      for (const abbreviation in abbreviations) {
        if (floatValue >= abbreviations[abbreviation]) {
          // Format the value with two decimal places
          const formattedValue = (floatValue / abbreviations[abbreviation]).toFixed(2)
          return `${prefix} ${formattedValue}${abbreviation}`
        }
      }

      // If the value is less than 1000, return the original value
      return `${prefix} ${floatValue.toFixed(2)}`
    },

    // the formatter function takes can take in both (value, context) or (value, index, values)
    defaultFormatter(value) {
      // Implement your default formatting logic here
      return value
    }
  }
}
</script>
