<script>
import TransactionModal from './TransactionModal.vue'
import { formattedAmount, undoFormatCurrency } from '@/assets/js/calculator'
import { formattedDate } from '@/assets/js/dateFunction'
export default {
  name: 'CompanyProfile',
  components: {
    TransactionModal
  },
  props: {
    transactions: {
      type: Array,
      default: () => []
    }
  },
  emits: ['fetch-data'],
  data() {
    return {
      selectedTransactionId: null,
      transactionSetting: {
        selectedKey: 'All',

        keys: [
          { data: 'description', name: 'Description' },
          { data: 'chargeAmount', name: 'Amount' },
          { data: 'paidAmount', name: 'Paid' },
          { data: 'dueDate', name: 'Due Date' },
          { data: 'paidAt', name: 'payment Date' },
          { data: 'status', name: 'Status' },
          { data: 'performance', name: 'Performance' }
        ]
      },
      currentPage: 1,
      pageSize: 10,
      totalPages: 1,
      maxPageLinks: 10
    }
  },
  computed: {},
  watch: {},
  beforeMount() {},
  mounted() {},
  methods: {
    formattedDate,
    formattedAmount,
    // test(e) {
    //   console.log('test', e.target.innerText)
    //   this.currentPage = e.target.innerText
    // },
    getPaymentPerformance(status, payDate, dueDate) {
      if (status == 'Pending' || payDate == null) {
        return 'Pending'
      }

      const [day, month, year] = payDate.split('-')
      const [day2, month2, year2] = dueDate.split('-')
      const paymentDate = new Date(`${year}-${month}-${day}`)
      const paymentDueDate = new Date(`${year2}-${month2}-${day2}`)

      if (paymentDate > paymentDueDate) {
        return 'Late'
      }

      if (paymentDate <= paymentDueDate) {
        return 'Prompt'
      }
    },
    getPaymentStatus(paid = 0, charge = 0) {
      const paidAmt = parseInt(undoFormatCurrency(paid))
      const chargeAmt = parseInt(undoFormatCurrency(charge))

      if (paidAmt == 0 || paidAmt == null || isNaN(paidAmt)) {
        return 'Pending'
      } else if (paidAmt > 0 && paidAmt < chargeAmt) {
        return 'Partially Paid'
      } else if (paidAmt == chargeAmt) {
        return 'Paid'
      } else if (paidAmt > chargeAmt) {
        return 'Overpaid'
      }
    },
    getStatusClass(status, dueDate = null) {
      const [day, month, year] = dueDate.split('-')
      const datedueDate = new Date(`${year}-${month}-${day}`)

      const today = new Date()

      // If paid amount is 0 and due date is in the future, return un-styled class
      if (datedueDate > today && status == 'Pending') {
        return ''
      }

      if (status == 'Pending') {
        return 'pending'
      } else if (status == 'Partially Paid') {
        return 'partial'
      } else {
        return 'paid'
      }
    },
    fetchData() {
      this.$emit('fetch-data')
    }
  }
}
</script>
<template>
  <transaction-modal
    :transactions="transactions"
    :selected-transaction-id="selectedTransactionId"
    @fetch-data="fetchData"
  />
  <div id="transactionDiv">
    <div
      class="collapse-container fw-bold fs-6 shadow-lg border border-1 border-primary bg-white w-100 px-4 py-2 my-3"
      data-bs-toggle="collapse"
      data-bs-target="#collapseTransaction"
      aria-expanded="false"
      aria-controls="collapseTransaction"
    >
      <span class="text-primary">Transactions</span>
    </div>
    <div id="collapseTransaction" class="collapse show">
      <div class="card">
        <div class="px-0 pb-0 card-body">
          <div class="px-4 mt-3">
            <div v-if="transactions.length > 0" class="table-responsive">
              <VTable
                v-model:current-page="currentPage"
                :data="transactions"
                :page-size="pageSize"
                class="table"
                @total-pages-changed="totalPages = $event"
              >
                <template #head>
                  <tr class="tableHeader">
                    <template v-for="i in transactionSetting.keys" :key="i">
                      <VTh
                        class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                        :sort-key="i.data"
                      >
                        {{ i.name }}
                      </VTh>
                    </template>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                    >
                      Action
                    </th>
                  </tr>
                </template>
                <template #body="{ rows }">
                  <tr v-for="row in rows" :key="row.id">
                    <template v-for="i in transactionSetting.keys" :key="i">
                      <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                        <!-- If column for 'paidAmount' -->
                        <span v-if="i.data == 'paidAmount'"> RM {{ row[i.data] ?? 0 }} </span>

                        <!-- Else if column for 'chargeAmount -->
                        <span v-else-if="i.data == 'chargeAmount'">
                          RM {{ row[i.data] ?? 0 }}
                        </span>

                        <!-- Else if column for 'status' -->
                        <span
                          v-else-if="i.data == 'status'"
                          :class="
                            getStatusClass(
                              getPaymentStatus(row?.paidAmount, row?.chargeAmount),
                              row?.dueDate
                            )
                          "
                        >
                          {{ getPaymentStatus(row?.paidAmount, row?.chargeAmount) }}
                        </span>

                        <!-- Else if column for 'performance' -->
                        <span
                          v-else-if="i.data == 'performance'"
                          :class="
                            getStatusClass(
                              getPaymentStatus(row?.paidAmount, row?.chargeAmount),
                              row?.dueDate
                            )
                          "
                          :data-performance="
                            getPaymentPerformance(
                              getPaymentStatus(row?.paidAmount, row?.chargeAmount),
                              row?.paidAt,
                              row?.dueDate
                            )
                          "
                        >
                          {{
                            getPaymentPerformance(
                              getPaymentStatus(row?.paidAmount, row?.chargeAmount),
                              row?.paidAt,
                              row?.dueDate
                            )
                          }}
                        </span>

                        <!-- Else -->
                        <span v-else>
                          {{ row[i.data] ?? 'N/A' }}
                        </span>
                      </td>
                    </template>
                    <td>
                      <button
                        class="btn btn-icon btn-outline-dark border-0"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#TransactionModal"
                        @click="
                          () => {
                            selectedTransactionId = row.id
                          }
                        "
                      >
                        <i class="far fa-edit"></i>
                      </button>
                    </td>
                  </tr>
                </template>
              </VTable>
            </div>

            <div v-else>
              <h5 class="text-bold">No Record Found</h5>
            </div>

            <div class="table-controls row mt-6">
              <div class="col-md-6 mb-2">
                <div class="d-lg-flex">
                  <p>
                    {{
                      transactions.length < currentPage * pageSize
                        ? `Showing ${currentPage * pageSize - pageSize + 1} to ${
                            transactions.length
                          } of ${transactions.length} entries`
                        : `Showing ${currentPage * pageSize - pageSize + 1} to ${
                            currentPage * pageSize
                          } of ${transactions.length} entries`
                    }}
                  </p>
                </div>
              </div>
              <div class="col-md-6 mb-2 ms-auto">
                <div class="d-lg-flex justify-content-end">
                  <VTPagination
                    v-model:current-page="currentPage"
                    :boundary-links="true"
                    :total-pages="totalPages"
                    :max-page-links="maxPageLinks"
                    :hide-single-page="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.collapse-container {
  cursor: pointer;
  border-radius: 20px;
}

/* [data-performance], */
.paid,
.partial,
.pending {
  font-weight: bold;
}

.paid {
  color: var(--bs-success);
}

.partial {
  color: var(--bs-yellow);
}

.pending {
  color: var(--bs-red);
}

.paid[data-performance='Late'],
.partial[data-performance='Late'],
.pending[data-performance='Late'] {
  color: var(--bs-red);
}

.paid[data-performance='Prompt'],
.partial[data-performance='Prompt'],
.pending[data-performance='Prompt'] {
  color: var(--bs-success);
}

.paid[data-performance='Pending'],
.partial[data-performance='Pending'],
.pending[data-performance='Pending'] {
  color: var(--bs-yellow);
}
/* span[data-performance='Late'] {
  color: var(--bs-red);
}

span[data-performance='Prompt'] {
  color: var(--bs-success);
}

span[data-performance='Pending'] {
  color: var(--bs-yellow);
} */
</style>
