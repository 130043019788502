<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import VendorModal from './components/VendorModal.vue'
import { formattedAmount } from '@/assets/js/calculator'
import { formattedDate } from '@/assets/js/dateFunction'
export default {
  name: 'VendorProfile',
  components: {
    VendorModal
  },
  data() {
    return {
      id: '',
      vendor: {},
      applicationProducts: [],
      totalLoanAmount: 0,
      applicationProductSetting: {
        selectedKey: 'All',
        totalPages: 1,
        currentPage: 1,
        maxPageLinks: 10,
        keys: [
          {
            data: 'letterOffer',
            name: 'Offer Letter',
            link: true,
            primaryKey: 'id',
            routerName: 'MasterProfile'
          },
          { data: 'hpNo', name: 'HP Number', text: true },
          { data: 'createdAt', name: 'Create Date', text: true },

          { data: 'loanAmount', name: 'Loan Amount', text: true, currency: 'RM' }
        ],
        filters: {
          name: {
            value: '',
            keys: ['name', 'cost']
          }
        },
        pageSize: 10
      }
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl'])
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.id = this.$route.params.id
    this.fetchData()
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    formattedDate,
    formattedAmount,

    deleteData() {
      console.log("This company id is",this.id)
      if (!confirm('Are you sure you want to delete this ?')) return
      axios
        .delete(`${this.apiUrl}/vendor`, {
          data : { id : this.id},
          headers : this.apiHeader 
        })
        .then((res) => {
          console.log('RESPONSE RECEIVED: ', res.data)
          this.$router.push({
            name: 'VendorList',
          })
        })
        .catch((err) => {
          console.log(err)
        })      
    },

    fetchData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/vendor/details/${this.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.vendor = data.Vendor
          this.fetchApplicationProductData()
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    fetchApplicationProductData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/applicantProduct/all`, {
          headers: this.apiHeader
        })

        .then(({ data }) => {
          this.applicationProducts = data.ApplicantProducts.filter(
            (x) => x.vendorId == this.id
          ).map((x) => ({ ...x, createdAt: this.formattedDate(x.createdAt) }))

          for (let i = 0; i < this.applicationProducts.length; i++) {
            this.totalLoanAmount += parseFloat(this.applicationProducts[i].loanAmount)
          }
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          this.decreaseIsFetching()
        })
    },
    getDiffMonth(date) {
      return moment().diff(moment(date), 'months')
    }
  }
}
</script>

<template>
  <vendor-modal :vendor="vendor" @fetch-data="fetchData" />

  <div class="py-4 container-fluid">
    <div class="row mb-3">
      <div class="col-8">
        <div class="card shadow">
          <div class="card-header d-flex">
            <h6>Vendor Profile</h6>
            <div class="ms-auto">
              <button
                type="button"
                class="btn btn0-sm btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#VendorModal"
              >
                Edit
              </button>
              <button
                class="btn btn-danger ms-2"
                type="button"
                @click="deleteData()"
              >
                Delete
              </button>
            </div>
          </div>
          <div class="card-body">
            <table class="table">
              <tr class="border-0">
                <th>Vendor Name</th>
                <th>:</th>
                <td>{{ vendor.name }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div id="applicationProductDiv">
      <div
        class="collapse-container fw-bold fs-6 shadow-lg border border-1 border-primary bg-white w-100 px-4 py-2 my-3"
        data-bs-toggle="collapse"
        data-bs-target="#collapseApplicationProduct"
        aria-expanded="false"
        aria-controls="collapseApplicationProduct"
      >
        <span class="text-primary">Existing Facilities</span>
      </div>
      <div id="collapseApplicationProduct" class="collapse show">
        <div class="card">
          <div class="px-0 pb-0 card-body">
            <div class="px-4 mt-3">
              <div v-if="applicationProducts.length > 0">
                <VTable
                  v-model:currentPage="applicationProductSetting.currentPage"
                  :data="applicationProducts"
                  :page-size="applicationProductSetting.pageSize"
                  :filters="applicationProductSetting.filters"
                  class="table"
                  @total-pages-changed="
                    () => {
                      applicationProductSetting.totalPages = $event
                    }
                  "
                >
                  <template #head>
                    <tr class="tableHeader">
                      <template v-for="i in applicationProductSetting.keys" :key="i">
                        <VTh
                          class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                          :sort-key="i.data"
                        >
                          {{ i.name }}
                        </VTh>
                      </template>
                    </tr>
                  </template>
                  <template #body="{ rows }">
                    <tr v-for="row in rows" :key="row">
                      <template v-for="i in applicationProductSetting.keys" :key="i">
                        <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                          <span v-if="i.currency">{{ i.currency }}&nbsp;</span>
                          <router-link
                            v-if="i.link"
                            :to="{
                              name: i.routerName,
                              params: { id: row[i.primaryKey] }
                            }"
                          >
                            {{ row[i.data] ?? 'N/A' }}
                          </router-link>

                          <span v-if="i.text">
                            {{ row[i.data] ?? 'N/A' }}
                          </span>
                          <span
                            v-if="i.badge"
                            class="badge"
                            :class="[
                              row[i.data] != null
                                ? row[i.data]
                                  ? 'badge-success'
                                  : 'badge-danger'
                                : 'badge-secondary'
                            ]"
                            >{{
                              row[i.data] != null ? (row[i.data] ? 'Yes' : 'No') : 'Pending'
                            }}</span
                          >
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td class="text-start text-wrap align-middle fw-bold" colspan="3">
                        Total Loan Amount
                      </td>
                      <td class="text-start text-wrap align-middle fw-bold">
                        RM {{ formattedAmount(totalLoanAmount) }}
                      </td>
                    </tr>
                  </template>
                </VTable>
                <div class="table-controls row mt-6">
                  <div class="col-md-6 mb-2">
                    <div class="d-lg-flex">
                      <p>
                        {{
                          applicationProducts.length <
                          applicationProductSetting.currentPage * applicationProductSetting.pageSize
                            ? `Showing ${
                                applicationProductSetting.currentPage *
                                  applicationProductSetting.pageSize -
                                applicationProductSetting.pageSize +
                                1
                              } to ${applicationProducts.length} of ${
                                applicationProducts.length
                              } entries`
                            : `Showing ${
                                applicationProductSetting.currentPage *
                                  applicationProductSetting.pageSize -
                                applicationProductSetting.pageSize +
                                1
                              } to ${
                                applicationProductSetting.currentPage *
                                applicationProductSetting.pageSize
                              } of ${applicationProducts.length} entries`
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6 mb-2 ms-auto">
                    <div class="d-lg-flex justify-content-end">
                      <VTPagination
                        :current-page="applicationProductSetting.currentPage"
                        :boundary-links="true"
                        :total-pages="applicationProductSetting.totalPages"
                        :max-page-links="applicationProductSetting.maxPageLinks"
                        :hide-single-page="false"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div v-else>
                <h5 class="text-bold">No Record Found</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.collapse-container {
  cursor: pointer;
  border-radius: 20px;
}
</style>
