<template>
  <loading :active="isLoading" :can-cancel="false" :is-full-page="true" />
  <div
    v-show="layout === 'landing'"
    class="landing-bg h-100 bg-gradient-primary position-fixed w-100"
  ></div>
  <sidenav v-if="showSidenav" />
  <main class="main-content position-relative max-height-vh-100 h-100">
    <navbar v-if="showNavbar" />
    <router-view :key="$route.path" />
  </main>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'
import Sidenav from './examples/Sidenav'
import Navbar from '@/examples/Navbars/Navbar.vue'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Loading,
    Sidenav,
    Navbar
  },
  computed: {
    ...mapState([
      'isLoading',
      'layout',
      'showSidenav',
      'showNavbar',
      'showFooter',
      'showConfig',
      'hideConfigButton'
    ])
  }
}
</script>
