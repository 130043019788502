<template>
  <div class="py-4 container-fluid">
    <div class="row mb-3">
      <div class="col-4">
        <div class="card shadow">
          <div class="m-3">
            <h5>Asset Type Image</h5>
            <div class="rounded-1">
              <img
                class="img-fluid"
                :src="
                  brand.logoUrl ? brand.logoUrl : require('@/assets/img/xcmg_logo_vertical.png')
                "
                style="max-width: 100%; max-height: 100px"
              />
            </div>
            <div class="mt-2">
              <button
                class="btn btn-primary mx-1"
                data-bs-toggle="modal"
                data-bs-target="#EditTypePicModal"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="class shadow">
          <div class="card">
            <div class="card-header d-flex">
              <h6>Asset Type Profile</h6>
              <div class="ms-auto">
                <button
                  type="button"
                  class="btn btn0-sm btn-outline-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#BrandModal"
                >
                  Edit
                </button>
                <button
                class="btn btn-danger ms-2"
                type="button"
                @click="deleteData()"
              >
                Delete
              </button>
              </div>
            </div>
            <div class="card-body">
              <table class="table">
                <tr class="border-0">
                  <th>Asset Type Name</th>
                  <th>:</th>
                  <td>{{ brand.name }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="productDiv">
      <div
        class="collapse-container fw-bold fs-6 shadow-lg border border-1 border-primary bg-white w-100 px-4 py-2 my-3"
        data-bs-toggle="collapse"
        data-bs-target="#collapseProduct"
        aria-expanded="false"
        aria-controls="collapseProduct"
      >
        <span class="text-primary">Assets</span>
      </div>
      <div id="collapseProduct" class="collapse show">
        <div class="card">
          <div class="px-0 pb-0 card-body">
            <div class="text-end me-4">
              <button
                class="btn btn-outline-primary"
                data-bs-toggle="modal"
                data-bs-target="#ProductModal"
                @click="
                  () => {
                    selectedProductId = null
                  }
                "
              >
                Create New
              </button>
            </div>

            <div class="px-4 mt-3">
              <div v-if="products.length > 0" class="table-responsive">
                <VTable
                  v-model:currentPage="productSetting.currentPage"
                  :data="products"
                  :page-size="productSetting.pageSize"
                  :filters="productSetting.filters"
                  class="table"
                  @total-pages-changed="
                    () => {
                      productSetting.totalPages = $event
                    }
                  "
                >
                  <template #head>
                    <tr class="tableHeader">
                      <template v-for="i in productSetting.keys" :key="i">
                        <VTh
                          class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                          :sort-key="i.data"
                        >
                          {{ i.name }}
                        </VTh>
                      </template>
                      <th
                        class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                      >
                        Action
                      </th>
                    </tr>
                  </template>
                  <template #body="{ rows }">
                    <tr v-for="row in rows" :key="row">
                      <template v-for="i in productSetting.keys" :key="i">
                        <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                          <span v-if="i.name == 'Cost'">{{ i.currency }} &nbsp;</span>
                          <router-link
                            v-if="i.link"
                            :to="{
                              name: i.routerName,
                              params: { id: row[i.primaryKey] }
                            }"
                          >
                            {{ row[i.data] ?? 'N/A' }}
                          </router-link>
                          <span v-else>
                            <span v-if="i.name == 'Cost'">
                              {{ formattedAmount(row[i.data]) ?? 'N/A' }}
                            </span>
                            <span v-else>
                              {{ row[i.data] ?? 'N/A' }}
                            </span>
                          </span>
                        </td>
                      </template>
                      <td>
                        <button
                          class="btn btn-icon btn-outline-dark border-0"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#ProductModal"
                          @click="
                            () => {
                              selectedProductId = row.id
                            }
                          "
                        >
                          <i class="far fa-edit"></i>
                        </button>
                      </td>
                    </tr>
                  </template>
                </VTable>
              </div>

              <div v-else>
                <h5 class="text-bold">No Record Found</h5>
              </div>

              <div class="table-controls row mt-6">
                <div class="col-md-6 mb-2">
                  <div class="d-lg-flex">
                    <p>
                      {{
                        products.length < productSetting.currentPage * productSetting.pageSize
                          ? `Showing ${
                              productSetting.currentPage * productSetting.pageSize -
                              productSetting.pageSize +
                              1
                            } to ${products.length} of ${products.length} entries`
                          : `Showing ${
                              productSetting.currentPage * productSetting.pageSize -
                              productSetting.pageSize +
                              1
                            } to ${productSetting.currentPage * productSetting.pageSize} of ${
                              products.length
                            } entries`
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 mb-2 ms-auto">
                  <div class="d-lg-flex justify-content-end">
                    <VTPagination
                      :current-page="productSetting.currentPage"
                      :boundary-links="true"
                      :total-pages="productSetting.totalPages"
                      :max-page-links="productSetting.maxPageLinks"
                      :hide-single-page="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="applicationDiv">
      <div
        class="collapse-container fw-bold fs-6 shadow-lg border border-1 border-primary bg-white w-100 px-4 py-2 my-3"
        data-bs-toggle="collapse"
        data-bs-target="#collapseApplication"
        aria-expanded="false"
        aria-controls="collapseApplication"
      >
        <span class="text-primary">Applications</span>
      </div>
      <div id="collapseApplication" class="collapse show">
        <div class="card">
          <div class="px-0 pb-0 card-body">
            <div class="px-4 mt-3">
              <div v-if="applications.length > 0" class="table-responsive">
                <VTable
                  v-model:currentPage="applicationSetting.currentPage"
                  :data="applications"
                  :page-size="applicationSetting.pageSize"
                  :filters="applicationSetting.filters"
                  class="table"
                  @total-pages-changed="
                    () => {
                      applicationSetting.totalPages = $event
                    }
                  "
                >
                  <template #head>
                    <tr class="tableHeader">
                      <template v-for="i in applicationSetting.keys" :key="i">
                        <VTh
                          class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                          :sort-key="i.data"
                        >
                          {{ i.name }}
                        </VTh>
                      </template>
                    </tr>
                  </template>
                  <template #body="{ rows }">
                    <tr v-for="row in rows" :key="row">
                      <template v-for="i in applicationSetting.keys" :key="i">
                        <td class="text-sm font-weight-normal text-start text-wrap align-middle">
                          <span v-if="i.currency">{{ i.currency }}&nbsp;</span>
                          <router-link
                            v-if="i.link"
                            :to="{
                              name: i.routerName,
                              params: { id: row[i.primaryKey] }
                            }"
                          >
                            {{ row[i.data] ?? 'N/A' }}
                          </router-link>
                          <span v-else>
                            {{ formattedAmount(row[i.data]) ?? 'N/A' }}
                          </span>
                        </td>
                      </template>
                    </tr>
                  </template>
                </VTable>
              </div>

              <div v-else>
                <h5 class="text-bold">No Record Found</h5>
              </div>

              <div class="table-controls row mt-6">
                <div class="col-md-6 mb-2">
                  <div class="d-lg-flex">
                    <p>
                      {{
                        applications.length <
                        applicationSetting.currentPage * applicationSetting.pageSize
                          ? `Showing ${
                              applicationSetting.currentPage * applicationSetting.pageSize -
                              applicationSetting.pageSize +
                              1
                            } to ${applications.length} of ${applications.length} entries`
                          : `Showing ${
                              applicationSetting.currentPage * applicationSetting.pageSize -
                              applicationSetting.pageSize +
                              1
                            } to ${
                              applicationSetting.currentPage * applicationSetting.pageSize
                            } of ${applications.length} entries`
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 mb-2 ms-auto">
                  <div class="d-lg-flex justify-content-end">
                    <VTPagination
                      :current-page="applicationSetting.currentPage"
                      :boundary-links="true"
                      :total-pages="applicationSetting.totalPages"
                      :max-page-links="applicationSetting.maxPageLinks"
                      :hide-single-page="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <EditTypePicModal :brand-id="brand.id" @fetch-data="fetchData" />
  <brand-modal :brand="brand" />
  <product-modal
    :products="products"
    :brand-id="brand.id"
    :selected-product-id="selectedProductId"
    @fetch-data="fetchData"
  />
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import BrandModal from './components/BrandModal.vue'
import ProductModal from './components/ProductModal.vue'
import EditTypePicModal from './components/EditTypePicModal.vue'
export default {
  name: 'BrandProfile',
  components: {
    BrandModal,
    ProductModal,
    EditTypePicModal
  },
  data() {
    return {
      id: '',
      brand: {},
      products: [],
      applications: [],
      selectedProductId: null,
      productSetting: {
        selectedKey: 'All',
        totalPages: 1,
        currentPage: 1,
        maxPageLinks: 10,
        primaryKey: 'id',
        clickable: false,
        routerName: 'ProductProfile',
        keys: [
          {
            data: 'name',
            name: 'Name',
            link: true,
            primaryKey: 'id',
            routerName: 'ProductProfile'
          },
          { data: 'cost', name: 'Cost', currency: 'RM' },
          {
            data: 'applicationCount',
            name: 'Application Count',
            link: false
          }
        ],
        filters: {
          name: {
            value: '',
            keys: ['name', 'cost', 'applicationCount']
          }
        },
        pageSize: 10
      },
      applicationSetting: {
        selectedKey: 'All',
        totalPages: 1,
        currentPage: 1,
        maxPageLinks: 10,
        keys: [
          { data: 'name', name: 'Product Name', text: true },
          { data: 'hirerName', name: 'Company', text: true },
          { data: 'cost', name: 'Cost', currency: 'RM', text: true },
          { data: 'loanAmount', name: 'Loan Amount', currency: 'RM' },
          { data: 'chasis', name: 'Chasis', text: true },
          { data: 'engine', name: 'Engine', text: true },
          { data: 'registrationNo', name: 'Registration No', text: true },
          { data: 'condition', name: 'Condition', text: true }
        ],
        filters: {
          name: {
            value: '',
            keys: [
              'name',
              'cost',
              'chasis',
              'engine',
              'registrationNo',
              'condition',
              'hirerName',
              'loanAmount'
            ]
          }
        },
        pageSize: 10
      }
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader']),
    ...mapState(['apiUrl'])
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.id = this.$route.params.id
    this.fetchData()
  },
  created() {
    // this.fetchData();
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),

    deleteData() {
      console.log("This company id is",this.id)
      if (!confirm('Are you sure you want to delete this ?')) return
      axios
        .delete(`${this.apiUrl}/brand`, {
          data : { id : this.id},
          headers : this.apiHeader 
        })
        .then((res) => {
          console.log('RESPONSE RECEIVED: ', res.data)
          this.$router.push({
            name: 'TypeList',
          })
        })
        .catch((err) => {
          console.log(err)
        })      
    },

    fetchData() {
      this.increaseIsFetching()
      console.log('attemping get')
      axios
        .get(`${this.apiUrl}/brand/details/${this.id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          // console.log(data.Brand.Products)
          this.brand = data.Brand
          this.products = data.Brand.Products.map((x) => ({
            ...x,
            applicationCount: x.ApplicantProducts.length
          }))
          this.applications = data.Brand.Products.flatMap((application) => {
            console.log(application)
            return application.ApplicantProducts.map((x, i) => ({
              ...x,
              name: this.brand.Products[i].name,
              hirerName: x?.Hirer?.name,
              condition: x.isNew ? 'New' : 'Used'
            }))
          })

          // this.applications = data.Brand.Products.ApplicantProducts.map((x) => {
          //   const product = this.products.find((y) => y.id == x.productId)
          //   return {
          //     ...x,
          //     ...product,
          //     hirerName: x.Applicant?.Hirer?.name,
          //     condition: x.isNew ? 'New' : 'Used'
          //   }
          // })
          console.log('please help', this.applications)
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
          console.log('error ?')
          this.decreaseIsFetching()
        })
    },
    formattedAmount(amount) {
      if (isNaN(amount)) {
        return amount
      }

      if (amount != null) {
        const parts = amount.toString().split('.')
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        return parts.join('.')
      }
      return amount
    },
    getDiffMonth(date) {
      return moment().diff(moment(date), 'months')
    },
    formattedDate(date) {
      return moment(date).format('DD-MM-YYYY hh:mm:ss')
    }
  }
}
</script>

<style></style>
