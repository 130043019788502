<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="table-controls row">
              <div class="col-md-4 col-lg-2 mb-2">
                <div class="d-lg-flex">
                  <div>
                    <h5 class="pb-1">Asset log</h5>
                    <p>Asset Log</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-controls row">
              <div class="col-md-7 col-lg-7 mb-3"></div>
              <div class="col-md-3 col-lg-3 mb-3">
                <input
                  id="search"
                  v-model="filters.name.value"
                  class="form-control"
                  placeholder="Type to search..."
                />
              </div>
              <div class="col-md-2 col-lg-2 mb-3 ms-auto">
                <select
                  id="dropdown"
                  v-model="selectedKey"
                  class="form-select ml-2"
                >
                  <option value="All">All</option>
                  <option v-for="key in keys" :key="key.id" :value="key.data">
                    {{ key.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="px-4 mt-3">
              <div v-if="resources.length > 0" class="table-responsive">
                <VTable
                  v-model:currentPage="currentPage"
                  :data="resources"
                  :page-size="pageSize"
                  :filters="filters"
                  class="table align-middle"
                  @total-pages-changed="totalPages = $event"
                >
                  <template #head>
                    <tr class="tableHeader">
                      <VTh
                        class="text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle"
                        sort-key="category"
                      >
                        Name
                      </VTh>
                    </tr>
                  </template>
                  <template #body="{ rows }">
                    <tr
                      v-for="row in rows"
                      :key="row"
                      class="clickable"
                      @click="toLink(row.category)"
                    >
                      <td
                        class="text-sm font-weight-normal text-start text-wrap align-middle"
                      >
                        {{ row.category ?? "N/A" }}
                      </td>
                    </tr>
                  </template>
                </VTable>
              </div>

              <div v-else>
                <h5 class="text-bold">No Record Found</h5>
              </div>

              <div class="table-controls row mt-6">
                <div class="col-md-6 col-lg-2 mb-2">
                  <div class="d-lg-flex">
                    <p>
                      {{
                        resources.length < currentPage * pageSize
                          ? `Showing ${
                              currentPage * pageSize - pageSize + 1
                            } to ${resources.length} of ${
                              resources.length
                            } entries`
                          : `Showing ${
                              currentPage * pageSize - pageSize + 1
                            } to ${currentPage * pageSize} of ${
                              resources.length
                            } entries`
                      }}
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4 mb-2 ms-auto">
                  <div class="d-lg-flex justify-content-end">
                    <VTPagination
                      v-model:currentPage="currentPage"
                      :boundary-links="true"
                      :total-pages="totalPages"
                      :max-page-links="maxPageLinks"
                      :hide-single-page="false"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import axios from "axios";

import moment from "moment";
export default {
  name: "ProductList",
  components: {},
  data() {
    return {
      totalPages: 1,
      currentPage: 1,
      maxPageLinks: 10,
      resources: [],
      keys: [{ id: 1, data: "category", name: "Name" }],
      selectedKey: "All",
      apiHeader: {},
      isEdit: false,
      pageSize: 10,
      filters: {
        name: {
          value: "",
          keys: ["category"],
        },
      },
    };
  },
  computed: {
    ...mapGetters(["validateValue", "getAPIHeader"]),
    ...mapState(["apiUrl"]),
  },

  watch: {
    ["selectedKey"]() {
      this.filters.name.keys = [];
      if (this.selectedKey == "All") {
        this.keys.forEach((key) => {
          this.filters.name.keys.push(key.data);
        });
      } else {
        this.filters.name.keys.push(this.selectedKey);
      }
    },
  },

  mounted() {
    this.apiHeader = this.getAPIHeader();
    this.fetchData();
  },
  methods: {
    ...mapMutations(["decreaseIsFetching", "increaseIsFetching"]),

    fetchData() {
      this.increaseIsFetching();
      axios
        .get(`${this.apiUrl}/resource/all`, {
          headers: this.apiHeader,
        })
        .then(({ data }) => {
          this.resources = [
            ...new Set(data.Resources.map((item) => item.category)),
          ].map((x) => ({ category: x }));
          // console.log(this.resources);
          //this.resources = data.Resources;
          this.decreaseIsFetching();
        })
        .catch((err) => {
          console.log(err);
          this.decreaseIsFetching();
        });
    },

    formattedDate(date) {
      return date ? moment(date).format("DD-MM-YYYY") : null;
    },
    formattedAmount(amount) {
      if (isNaN(amount)) {
        return amount;
      }

      const parts = amount.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    toLink(category) {
      this.$router.push({
        name: "ResourceProfile",
        params: { category: category },
      });
    },
  },
};
</script>

<style>
.clickable {
  cursor: pointer;
  transition: 0.1s;
}
.clickable:hover {
  background-color: #aaaaaa;
}
</style>
