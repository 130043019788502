<template>
  <div class="p-3 multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Application Information</h5>
      <div class="row">
        <div class="col-5">
          <div class="multisteps-form__content">
            <div class="mt-3 row">
              <div>
                <label for="dropdown">Company*</label>
                <select
                  id="hirer-choice"
                  v-model="applicationData.hirerId"
                  class="form-control"
                  :class="{ 'is-invalid': requiredKeys[0].invalid }"
                >
                  <option value="">Select Company</option>
                </select>
                <span class="text-danger" :class="{ 'd-none': !requiredKeys[0].invalid }">{{
                  requiredKeys[0].text
                }}</span>
              </div>
            </div>
            <div class="mt-3 row">
              <div>
                <label for="subject">Subject</label>
                <textarea
                  id="subject"
                  v-model="applicationData.purpose"
                  class="form-control"
                  placeholder="Type your subject here"
                  rows="5"
                ></textarea>
              </div>
            </div>
            <div class="mt-3 row">
              <div>
                <label for="dropdown">Financial Program*</label>
                <select
                  id="financial-program-choice"
                  v-model="selectedFinancialProgram"
                  class="form-control"
                >
                  <option value="">Select Program</option>
                </select>
                <p class="text-danger">{{ programErr }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Documents Submission</h5>
      <div class="multisteps-form__content">
        <div class="mt-3 row">
          <template v-for="name in Object.keys(fileData)" :key="name">
            <div class="mb-3 col-8 col-sm-8">
              <label :for="name" class="form-label">{{ fileData[name].label }}</label>
              <input
                :id="name"
                class="form-control"
                type="file"
                :multiple="fileData[name].multiple"
                :readonly="isReadOnly"
                @change="handleFileChange(name, $event)"
              />
            </div>
          </template>
        </div>
        <p class="text-danger">{{ fileMissingErr }}</p>
        <div v-if="applicationDocuments?.length > 0" class="mt-3 p-1 table-responsive">
          <VTable :data="applicationDocuments" class="w-50">
            <template #head>
              <tr class="tableHeader">
                <th
                  class="border border-1 text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle bg-main text-white"
                >
                  Name
                </th>
                <th
                  class="border border-1 text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle bg-main text-white"
                >
                  File
                </th>
                <th
                  class="border border-1 text-uppercase text-secondary text-xxs font-weight-bolder text-start text-wrap align-middle bg-main text-white"
                >
                  File
                </th>
              </tr>
            </template>
            <template #body="{ rows }">
              <tr v-for="row in rows" :key="row.id">
                <td class="border border-1">{{ row.Resource?.name ?? 'N/A' }}</td>
                <td class="border border-1">
                  <a target="_blank" :href="row.url"><i class="far fa-file-pdf"></i></a>
                </td>
                <td class="border border-1">
                  <a class="dustbin-clickable" @click="submitRemoveFileForm(row.id, $event)">
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </td>
              </tr>
            </template>
          </VTable>
        </div>
      </div>
    </div>
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder mt-3">Related Guarantors</h5>
      <div class="multisteps-form__content">
        <div class="row">
          <div class="text-end me-4">
            <button
              v-if="applicationData.hirerId"
              class="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#ContactModal"
              @click.prevent=""
            >
              Create New
            </button>
          </div>
          <div class="mt-3">
            <div class="mt-3 row">
              <div class="col-md-4">
                <label for="contact-choice">Contact*</label>
                <select
                  id="contact-choice"
                  v-model="selectedContact.name"
                  class="form-control"
                  :readonly="isReadOnly"
                >
                  <option value="">Select Contact</option>
                </select>
              </div>

              <div class="col-md-3">
                <label for="formFile" class="form-label">Identification Card Number</label>
                <input
                  id="formFile"
                  class="form-control"
                  type="text"
                  v-model="selectedContact.identityNo"
                  :readonly="isReadOnly"
                />
              </div>

              <div class="col-md-3">
                <label for="formFile" class="form-label">Identification Card*</label>
                <input
                  id="formFile"
                  class="form-control"
                  type="file"
                  :readonly="isReadOnly"
                  @change="handleContactICFileChange($event)"
                />
              </div>
            </div>

            <p v-if="selectedContactError" class="text-danger">
              Contact, Identification Card and Identification Card Number are required and cannot be
              duplicated
            </p>

            <button
              class="btn btn-submain text-white my-4"
              type="button"
              :disabled="isReadOnly"
              @click="addContact('selectedContact', 'gcontacts', 'selectedContactError')"
            >
              Add
            </button>

            <div class="row">
              <p><strong>Contacts</strong></p>
              <div v-if="gcontacts.length != 0" class="table-responsive">
                <VTable :data="gcontacts" class="table">
                  <template #head>
                    <tr>
                      <th>Name</th>
                      <th>Identification Card Number</th>
                      <th>Identification Card</th>
                      <th>Action</th>
                    </tr>
                  </template>
                  <template #body="{ rows }">
                    <tr v-for="row in rows" :key="row.id">
                      <td>{{ row.name }}</td>
                      <td>{{ row.identityNo }}</td>
                      <td v-if="row.url">
                        <a target="_blank" :href="row.url"> <i class="far fa-file"></i></a>
                      </td>
                      <td v-else>
                        {{ row.file.name }}
                      </td>

                      <td>
                        <button
                          class="btn"
                          type="button"
                          :disabled="isReadOnly"
                          @click="removeContact('gcontacts', index)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </template>
                </VTable>
              </div>
              <div v-else>
                <p>No Contacts</p>
              </div>
              <p class="text-danger">{{ guarantorErr }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder mt-3">Related Witness</h5>
      <div class="multisteps-form__content">
        <div class="row">
          <div class="text-end me-4">
            <button
              v-if="applicationData.hirerId"
              class="btn btn-outline-primary"
              data-bs-toggle="modal"
              data-bs-target="#ContactModal"
              @click.prevent=""
            >
              Create New
            </button>
          </div>
          <div class="mt-3">
            <div class="mt-3 row">
              <div class="col-md-4">
                <label for="witness-contact-choice">Contact*</label>
                <select
                  id="witness-contact-choice"
                  v-model="selectedWitnessContact.name"
                  class="form-control"
                  :readonly="isReadOnly"
                >
                  <option value="">Select Contact</option>
                </select>
              </div>

              <div class="col-md-3">
                <label for="formFile" class="form-label">Designation</label>
                <input
                  id="formFile"
                  class="form-control"
                  type="text"
                  v-model="selectedWitnessContact.designation"
                  :readonly="isReadOnly"
                />
              </div>

              <div class="col-md-3">
                <label for="formFile" class="form-label">Identification Card Number</label>
                <input
                  id="formFile"
                  class="form-control"
                  type="text"
                  v-model="selectedWitnessContact.identityNo"
                  :readonly="isReadOnly"
                />
              </div>
            </div>

            <p v-if="selectedWitnessContactError" class="text-danger">
              Contact and Identification Card Number are required and cannot be duplicated
            </p>

            <button
              class="btn btn-submain text-white my-4"
              type="button"
              :disabled="isReadOnly || witnessAdded"
              @click="
                addWitnessContact(
                  'selectedWitnessContact',
                  'wcontacts',
                  'selectedWitnessContactError'
                )
              "
            >
              Add
            </button>

            <div class="row">
              <p><strong>Contacts</strong></p>
              <div v-if="wcontacts.length != 0" class="table-responsive">
                <VTable :data="wcontacts" class="table">
                  <template #head>
                    <tr>
                      <th>Name</th>
                      <th>Designation</th>
                      <th>Identification Card Number</th>
                      <th>Action</th>
                    </tr>
                  </template>
                  <template #body="{ rows }">
                    <tr v-for="row in rows" :key="row.id">
                      <td>{{ row.name }}</td>
                      <td>{{ row.designation }}</td>
                      <td>
                        {{ row.identityNo ? row.identityNo : 'Not Provided' }}
                      </td>

                      <td>
                        <button
                          class="btn"
                          type="button"
                          :disabled="isReadOnly"
                          @click="removeContact('wcontacts', index)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </template>
                </VTable>
              </div>
              <div v-else>
                <p>No Contacts</p>
              </div>
              <p class="text-danger">{{ witnessErr }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 px-3 py-4">
      <div class="button-row d-flex justify-content-end col-12">
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="m-1"
          title="Next"
          :disabled="isReadOnly"
          @click="submitApplication()"
          >Save</argon-button
        >
      </div>
    </div>
    <AddGuarantor @refresh-guarantor="refreshGuarantor" :hirerId="applicationData.hirerId" />
    <AddWitness @refresh-guarantor="refreshGuarantor" :hirerId="applicationData.hirerId" />
  </div>
</template>

<script>
import Choices from 'choices.js'
import { mapState, mapGetters, mapMutations } from 'vuex'
import ArgonButton from '@/components/ArgonButton.vue'
import AddGuarantor from './AddGuarantor.vue'
import axios from 'axios'

export default {
  name: 'ProductInfo',
  components: {
    ArgonButton,
    AddGuarantor
  },

  props: {
    isReadOnly: {
      type: Boolean,
      default: false
    },
    applicationDocuments: {
      type: Array,
      default: () => []
    },
    application: {
      type: Object,
      default: () => {}
    },
    resources: {
      type: Array,
      default: () => []
    },
    financialPrograms: {
      type: Array,
      default: () => []
    }
  },
  emits: ['fetch-data', 'next-step', 'fetch-documents', 'refresh-guarantor'],

  data() {
    return {
      testApplication: [{ name: 'Name 1' }, { name: 'Name 2' }, { name: 'Name 3' }],
      //value
      hirers: [],
      apiHeader: {},
      fileCount: 0,
      applicationId: null,
      //form data
      applicationData: {
        hirerId: '',
        purpose: '',
        recommendBy: 1
      },
      fileData: {
        companyCtos: {
          label: 'CTOS Report (Company)*',
          multiple: false,
          file: [],
          resource: 'CTOS Report - Company'
        },
        individualCtos: {
          label: 'CTOS Reports (Individual)*',
          multiple: true,
          file: [],
          resource: 'CTOS Report - Shareholder'
        },
        ssmFile: {
          label: 'SSM Statement*',
          multiple: true,
          file: [],
          resource: 'Company SSM'
        },
        accStatementFiles: {
          label: 'Bank Statements*',
          multiple: true,
          file: [],
          resource: 'Bank Account Statement'
        },
        financialStatement: {
          label: 'Financial Statement',
          multiple: true,
          file: [],
          resource: 'Financial Statement'
        },
        customerAgeing: {
          label: 'Debtor Ageing',
          multiple: false,
          file: [],
          resource: 'Customer Ageing'
        },
        supplierAgeing: {
          label: 'Creditor Ageing',
          multiple: false,
          file: [],
          resource: 'Supplier Ageing'
        },
        otherFiles: {
          label: 'Other Files',
          multiple: true,
          file: [],
          resource: 'Other'
        }
      },
      selectedContact: {
        name: '',
        identityNo: '',
        file: null
      },
      selectedWitnessContact: {
        name: '',
        designation: '',
        identityNo: ''
      },
      selectedContactError: false,
      selectedWitnessContactError: false,
      gcontacts: [],
      wcontacts: [],
      selectedFinancialProgram: '',
      //choices
      hirerChoices: {},
      WitnessContactChoices: {},
      ContactChoices: {},
      financialProgramChoices: {},
      //validateInput
      witnessAdded: false,
      fileMissingErr: '',
      programErr: '',
      guarantorErr: '',
      witnessErr: '',
      requiredKeys: [
        {
          name: 'hirerId',
          text: 'Please select company',
          invalid: false,
          className: null
        }
      ],
      appHirerId: null
    }
  },
  computed: {
    ...mapState(['isLoading', 'apiUrl', 'user']),
    ...mapGetters(['validateValue', 'getAPIHeader', 'capitalizeFirstLetter'])
  },

  watch: {
    isReadOnly(newData) {
      if (newData) {
        this.companyChoices.disable()
        this.hirerContactChoices.disable()
      } else {
        this.companyChoices.enable()
        this.hirerContactChoices.enable()
      }
    },
    application() {
      if (this.application.hirerId) {
        this.applicationData = this.application
        this.fetchGuarantorData(this.application.id)
        this.fetchWitnessData(this.application.id)
        console.log(this.gcontacts)
      }
      this.fetchHirerData()
    },
    ['applicationData.hirerId'](id) {
      this.fetchHirerDetail(id)
      this.appHirerId = id
      // this.gcontacts = new Array();
      this.selectedContact.name = ''
      this.selectedWitnessContact.name = ''
      this.selectedContact.file = new Object()
      this.selectedWitnessContact.file = new Object()
    },
    ['applicationData.financialProgramId'](id) {
      this.selectedFinancialProgram = id
      this.getFinancialProgramChoice()
    },
    financialPrograms() {
      this.getFinancialProgramChoice()
    },
    fileCount(newData) {
      if (newData == 0) {
        if (this.application.id) {
          this.$emit('fetch-data')
          this.$emit('next-step')
        } else {
          this.$router.push({
            name: 'PendingApplication',
            params: { id: this.applicationData.id }
          })
        }
      }
    }
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    refreshGuarantor() {
      this.fetchHirerDetail(this.appHirerId)
    },
    extractFileName(url) {
      const lastSlashIndex = url.lastIndexOf('/')
      if (lastSlashIndex !== -1) {
        return url.substring(lastSlashIndex + 1)
      } else {
        return url // If there is no "/", return the original string
      }
    },

    getWitness() {
      axios
        .get(`${this.apiUrl}/applicantWitness/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          console.log('All Witness Data', data)
        })
    },

    fetchGuarantorData(id) {
      axios
        .get(`${this.apiUrl}/applicant/guarantor/${id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          console.log('Id target', id)
          console.log('Guarantor Result', data)
          this.gcontacts = data.ApplicantGuarantors
        })
    },

    fetchWitnessData(id) {
      axios
        .get(`${this.apiUrl}/applicant/witness/${id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          console.log('Id target', id)
          console.log('Witness Result', data)
          this.wcontacts = data.ApplicantWitness
        })
    },

    fetchHirerData() {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/hirer/all`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          this.hirers = data.Hirers
          let choices = []
          this.hirers.forEach((data) => {
            if (data.name) {
              choices.push({
                value: data.id,
                label: data.name,
                selected: this.application?.hirerId == data.id
              })
            }
          })

          this.decreaseIsFetching()
          this.createChoice(choices, 'hirerChoices', 'hirer-choice', this.application?.hirerId)
        })
    },
    fetchHirerDetail(id) {
      this.increaseIsFetching()
      axios
        .get(`${this.apiUrl}/hirer/details/${id}`, {
          headers: this.apiHeader
        })
        .then(({ data }) => {
          if (data.Hirer.HirerContacts && data.Hirer.HirerContacts.length > 0) {
            let choices = []
            data.Hirer.HirerContacts.forEach((data) => {
              choices.push({
                value: data.name,
                label: data.name
              })
            })
            this.createChoice(
              choices,
              'ContactChoices',
              'contact-choice',
              this.application?.hirerId
            )
            this.createChoice(
              choices,
              'WitnessContactChoices',
              'witness-contact-choice',
              this.application?.hirerId
            )
          }
          this.decreaseIsFetching()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getFinancialProgramChoice() {
      if (Object.keys(this.application).length > 0 && this.financialPrograms.length > 0) {
        let choices = []
        this.financialPrograms.forEach((x) => {
          choices.push({
            label: x.name,
            value: x.id,
            selected: x.id == this.application?.FinancialProgram?.id
          })
        })
        this.createChoice(choices, 'financialProgramChoices', 'financial-program-choice')
      }
    },

    // handleContactICFileChange(event) {
    //   this.selectedContact.file = event.target.files[0]
    // },

    handleContactICFileChange(event) {
      this.selectedContact.file = event.target.files[0]
    },

    handleWitnessContactICFileChange(event) {
      this.selectedWitnessContact.file = event.target.files[0]
    },

    handleFileChange(property, event) {
      const fileInput = event.target
      const files = fileInput.files
      this.fileData[property].file = Array.from(files)
    },
    addWitnessContact(selectedContactType, contactType, errorType) {
      if (
        this[selectedContactType].name &&
        this[contactType].filter((x) => x.name == this[selectedContactType].name).length < 1
      ) {
        this[errorType] = false
        this[contactType].push({ ...this[selectedContactType] })
        this[selectedContactType]['designation'] = ''
        this[selectedContactType]['identityNo'] = ''
        this.WitnessContactChoices.setChoiceByValue('')
        console.log(this[contactType])
      } else {
        this[errorType] = true
      }
      if (this[contactType].length > 0) {
        this.witnessAdded = true
      } else {
        this.witnessAdded = false
      }
    },
    addContact(selectedContactType, contactType, errorType) {
      // console.log(selectedContactType,this[selectedContactType])
      if (
        this[selectedContactType].name &&
        this[selectedContactType].file &&
        this[selectedContactType].file?.name &&
        this[contactType].filter((x) => x.name == this[selectedContactType].name).length < 1
      ) {
        this[errorType] = false
        this[contactType].push({ ...this[selectedContactType] })
        this[selectedContactType]['identityNo'] = ''
        this.ContactChoices.setChoiceByValue('')
      } else {
        this[errorType] = true
      }
    },
    removeContact(contactType, index) {
      if (this[contactType][index] && this[contactType][index].id) {
        this.submitRemoveContactForm(this[contactType][index].id)
      }
      this[contactType].splice(index, 1)
      if (this[contactType].length > 0) {
        this.witnessAdded = true
      } else {
        this.witnessAdded = false
      }
    },
    createChoice(choices, choiceName, elementId, disable) {
      if (Object.keys(this[choiceName]).length > 0) {
        this[choiceName].destroy()
      }
      const element = document.getElementById(elementId)
      this[choiceName] = new Choices(element, {
        searchEnabled: true,
        searchChoices: true,
        searchPlaceholderValue: 'Search here',
        choices: choices
      })

      if (disable) {
        this[choiceName].disable()
      }
    },
    submitApplication() {
      console.log('Submitting')
      console.log('Application Id', this.application.id)
      this.validateApplicantForm()
      this.validateFileForm()
      this.validateProgramForm()
      this.validateContactForm()
      this.validateWitnessForm()
      if (
        this.validateApplicantForm() &&
        this.validateFileForm() &&
        this.validateProgramForm() &&
        this.validateContactForm() &&
        this.validateWitnessForm()
      ) {
        let action = 'post'
        let payload = {
          purpose: this.applicationData.purpose,
          financialProgramId: this.selectedFinancialProgram
        }
        if (this.application.id) {
          action = 'put'
          payload.id = this.application.id
        } else {
          payload.hirerId = this.applicationData.hirerId
        }
        axios[action](`${this.apiUrl}/applicant`, payload, {
          headers: this.apiHeader
        })
          .then(({ data }) => {
            this.applicationData.id = data.id ? data.id : payload.id
            console.log(data)
            console.log('Submitted id', data.id)
            console.log('Payload id', payload.id)
            this.submitGuarantor(data.id)
            this.submitWitness(data.id)
            this.submitDoc(data.id ? data.id : payload.id)
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.decreaseIsFetching()
            this.$emit('next-step')
          })
      }
    },
    deleteApplication() {
      axios
        .delete(`${this.apiUrl}/applicant`, {
          data: { id: this.applicationData.id },
          headers: this.apiHeader
        })
        .then((res) => {
          console.log('RESPONSE RECEIVED: ', res.data)
          this.$router.push('/application/list')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    submitDoc(id) {
      Object.keys(this.fileData).forEach((key) => {
        if (this.fileData[key].file.length > 0) {
          console.log('File Data', this.fileData)
          this.fileCount++
          const resource = this.resources.find((x) => x.name == this.fileData[key].resource)
          const formData = new FormData()
          formData.append('applicantId', id)
          formData.append('resourceId', resource.id)
          formData.append('name', 'File')
          this.fileData[key].file.forEach((doc) => {
            formData.append('files', doc)
          })

          this.increaseIsFetching()

          axios
            .post(`${this.apiUrl}/applicantDocument`, formData, {
              headers: this.apiHeader,
              'Content-Type': 'multipart/form-data'
            })
            .then(() => {
              this.fileCount--
              this.decreaseIsFetching()
            })
        }
      })
    },
    submitWitness(id) {
      console.log('Witness to be submitted', this.wcontacts)
      this.wcontacts.forEach((contact) => {
        if (!contact.id) {
          this.increaseIsFetching()
          const formData = new FormData()
          formData.append('applicantId', id)
          formData.append('name', contact.name)
          formData.append('designation', contact.designation)
          formData.append('identityNo', contact.identityNo)
          axios
            .post(`${this.apiUrl}/applicantWitness`, formData, {
              headers: this.apiHeader,
              'Content-Type': 'multipart/form-data'
            })
            .then(() => {
              this.decreaseIsFetching()
            })
        }
      })
    },
    submitGuarantor(id) {
      this.gcontacts.forEach((contact) => {
        if (!contact.id) {
          this.increaseIsFetching()
          const formData = new FormData()
          formData.append('applicantId', id)
          formData.append('name', contact.name)
          formData.append('description', '')
          formData.append('phone', '')
          formData.append('identityNo', contact.identityNo)
          formData.append('file', contact.file)
          axios
            .post(`${this.apiUrl}/applicantGuarantor`, formData, {
              headers: this.apiHeader,
              'Content-Type': 'multipart/form-data'
            })
            .then(() => {
              this.decreaseIsFetching()
            })
        }
      })
    },
    submitRemoveFileForm(id, e) {
      axios
        .delete(`${this.apiUrl}/applicantDocument`, {
          headers: this.apiHeader,
          params: {
            id: id
          }
        })
        .then(() => {
          this.$emit('fetch-documents')
          // console.log(e.target.closest('tr'))
          e.target.closest('tr').remove()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    submitRemoveContactForm(id) {
      axios
        .delete(`${this.apiUrl}/applicantGuarantor`, {
          headers: this.apiHeader,
          params: {
            id: id
          }
        })
        .then(() => {})
        .catch((err) => {
          console.log(err)
        })
    },
    validateFileForm() {
      console.log('Validating Applicant Form')
      if (this.applicationDocuments.length == 0) {
        let missingFiles = 0
        const nullableFiles = [
          'supplierAgeing',
          'customerAgeing',
          'otherFiles',
          'financialStatement'
        ]

        Object.keys(this.fileData).forEach((key) => {
          if (!nullableFiles.includes(key)) {
            if (this.fileData[key].file.length == 0) {
              // here
              missingFiles++
            }
          }
        })

        if (missingFiles > 0) {
          console.log('Files are missing!')
          this.fileMissingErr = 'Files are missing!'
          return false
        } else {
          console.log('All files are acquired.')
          this.fileMissingErr = ''
          return true
        }
      } else {
        this.fileMissingErr = ''
        return true
      }
    },

    validateProgramForm() {
      if (this.selectedFinancialProgram == '') {
        this.programErr = 'Please select financial program'
        return false
      } else {
        this.programErr = ''
        return true
      }
    },

    validateApplicantForm() {
      console.log('Validating Applicant Form')
      let result = true
      this.requiredKeys.forEach((x, index) => {
        const value = this.applicationData[x.name]
        console.log(value)
        if (this.validateValue(value)) {
          result = false
          this.requiredKeys[index].invalid = true
          if (this.requiredKeys[index].className != null) {
            const classes = document.getElementsByClassName(this.requiredKeys[index].className)
            if (classes.length > 0) {
              classes[0].classList.add('border-danger')
            }
          }
        } else {
          this.requiredKeys[index].invalid = false
          if (this.requiredKeys[index].className != null) {
            const classes = document.getElementsByClassName(this.requiredKeys[index].className)
            if (classes.length > 0) {
              classes[0].classList.remove('border-danger')
            }
          }
        }
      })
      return result
    },
    validateContactForm() {
      if (this.gcontacts.length == 0) {
        this.guarantorErr = 'Please add at least 1 guarantor'
        return false
      } else {
        this.guarantorErr = ''
        return true
      }
    },
    validateWitnessForm() {
      if (this.wcontacts.length == 0) {
        this.witnessErr = 'Please add at least 1 witness'
        return false
      } else {
        this.witnessErr = ''
        return true
      }
    }
  }
}
</script>

<style>
.dustbin-clickable {
  cursor: pointer;
}

.input-wrapper input[type='checkbox'] {
  margin-right: 1rem;
}

.input-wrapper input[type='text'] {
  margin-left: 1rem;
}
</style>
