<template>
  <div class="p-3 multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="card">
      <div class="card-header">
        <h5 class="font-weight-bolder my-3">Agreement Documents</h5>
      </div>
      <div class="card-body">
        <template v-for="(agreement, index) in agreementData" :key="agreement">
          <h6 class="mb-3 mt-4">{{ agreement.label }}*</h6>
          <div class="table-responsive">
            <table class="table">
              <tr class="border-0">
                <th class="border border-1 w-15 bg-main text-white w-15">Model (Brand)</th>
                <th class="border border-1 w-15 bg-main text-white w-20">Agreement</th>
                <th class="border border-1 w-15 bg-main text-white w-20">Signed Agreement</th>
                <th class="border border-1 w-15 bg-main text-white">Action</th>
              </tr>
              <template v-for="(file, fileIndex) in agreement.files" :key="file">
                <tr class="border-0">
                  <td class="border border-1">
                    <div>{{ file.product.name }}</div>
                    <div>({{ file.brand.name }})</div>
                  </td>
                  <td class="border border-1 text-center">
                    <a target="_blank" :href="file.generate_url">
                      <i class="far fa-file-pdf fa-2x"></i>
                    </a>
                  </td>
                  <td class="border border-1 text-center">
                    <a v-if="file.signed_url" target="_blank" :href="file.signed_url"
                      ><i class="far fa-file-pdf fa-2x"></i
                    ></a>
                    <span v-else>N/A</span>
                  </td>
                  <td class="border border-1">
                    <input
                      id="formFile"
                      class="form-control"
                      type="file"
                      :readonly="isReadOnly"
                      @change="handleFileChange(index, fileIndex, file.id, $event)"
                    />
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="application.Progress?.seq && application.Progress?.seq <= 7"
      class="card mt-4 px-3 py-4"
    >
      <div class="mt-4 w-100 text-end">
        <button
          v-if="checkAllFilePresent"
          type="button"
          class="btn btn-primary"
          @click="confirmCloseCase()"
        >
          Complete
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'Approval',
  props: {
    application: {
      type: Object,
      default: () => {}
    },
    agreements: {
      type: Array,
      default: () => []
    }
  },
  emits: ['fetch-data'],
  data() {
    return {
      agreementData: [],
      allFilePresent: false
    }
  },
  computed: {
    ...mapState(['isLoading', 'apiUrl', 'user']),
    ...mapGetters(['getAPIHeader']),
    checkAllFilePresent() {
      let isAllFilePresent = true
      this.agreementData.forEach((agreement) => {
        agreement.files.forEach((file) => {
          if (!file.signed_url) {
            isAllFilePresent = false
          }
        })
      })
      return isAllFilePresent
    }
  },
  watch: {
    agreements: {
      handler(newData) {
        this.agreementData = newData
      },
      deep: true
    }
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.agreementData = this.agreements
  },
  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    confirmCloseCase() {
      this.$swal
        .fire({
          title: 'Once confirm, the collection of payment will be started',
          showCancelButton: true,
          confirmButtonText: 'Confirm'
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm()
          }
        })
    },
    submitForm() {
      axios
        .put(
          `${this.apiUrl}/applicant/complete`,
          {
            id: this.application.id
          },
          {
            headers: this.apiHeader
          }
        )
        .then(() => {
          this.$emit('fetch-data')
        })
        .catch((err) => {
          console.log(err)
          this.$emit('fetch-data')
        })
    },
    handleFileChange(index, fileIndex, id, event) {
      const formData = new FormData()
      formData.append('id', id)
      formData.append('file', event.target.files[0])
      this.increaseIsFetching()
      axios
        .post(`${this.apiUrl}/agreementDocument/upload`, formData, {
          headers: this.apiHeader,
          'Content-Type': 'multipart/form-data'
        })
        .then(({ data }) => {
          this.decreaseIsFetching()
          this.$swal.fire({
            title: 'Submitted Successfully',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
            willClose: () => {
              this.agreementData[index].files[fileIndex].signed_url = data.url
            }
          })
        })
        .catch((err) => {
          console.log(err)
          this.$swal.fire({
            title: 'Submitted Successfully',
            icon: 'success',
            timer: 2000,
            timerProgressBar: true,
            willClose: () => {
              this.$emit('fetch-data')
            }
          })
          this.decreaseIsFetching()
        })
    },

    shortUrl(url) {
      return url ? url.replace('https://xcmg-bucket.oss-ap-southeast-3.aliyuncs.com', '') : 'N/A'
    },
    formattedDate(date) {
      return date ? moment(date).format('DD-MM-YYYY hh:mm:ss') : null
    }
  }
}
</script>

<style></style>
