<template>
  <div class="p-3 multisteps-form__panel border-radius-xl" data-animation="FadeIn">
    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolder my-3">Score Card</h5>
      <div class="mt-3 mb-2">
        <p>CTOS - Company status</p>
        <span class="badge" :class="getCtosStatusClass(ctosStatus)">
          {{ ctosStatus }}
        </span>
      </div>
    </div>
    <template v-for="f in factorData" :key="f">
      <div class="card mt-4 px-3 py-4">
        <h5 class="font-weight-bolder my-3">{{ f.Factor.Resource.name }}</h5>
        <div class="multisteps-form__content">
          <div class="mt-3 row">
            <div class="col-2 col-sm-2">
              <p class="font-weight-bolder">Factor</p>
            </div>
            <div class="col-auto col-sm-auto">
              <p class="font-weight-bolder">:</p>
            </div>
            <div class="col-auto col-sm-auto">
              <p>{{ f.Factor.name }}</p>
            </div>
          </div>

          <div class="mt-3 row">
            <div class="col-2 col-sm-2">
              <p class="font-weight-bolder">Description/Formula</p>
            </div>
            <div class="col-auto col-sm-auto">
              <p class="font-weight-bolder">:</p>
            </div>
            <div class="col-auto col-sm-auto">
              <p>
                {{ f.Factor.description }}
              </p>
            </div>
          </div>

          <div v-if="f.remark" class="mt-3 row">
            <div class="col-2 col-sm-2">
              <p class="font-weight-bolder">CTOS</p>
            </div>
            <div class="col-auto col-sm-auto">
              <p class="font-weight-bolder">:</p>
            </div>
            <div class="col-auto col-sm-auto">
              <p>
                {{ f.remark ?? 'N/A' }}
              </p>
            </div>
          </div>

          <div class="mt-3 row">
            <div class="col-2 col-sm-2">
              <p class="font-weight-bolder">Factor Score</p>
            </div>
            <div class="col-auto col-sm-auto">
              <p class="font-weight-bolder">:</p>
            </div>
            <div class="col-4 col-sm-4">
              <select id="hirer-choice" v-model="f.score" class="form-control">
                <template
                  v-for="attr in f.Factor.FactorAttributes.sort(function (a, b) {
                    return a.score - b.score
                  })"
                  :key="attr"
                >
                  <option :value="attr.score">
                    {{ `${attr.score} ${attr.name}` }}
                  </option>
                </template>
              </select>
            </div>
          </div>

          <div class="mt-3 row">
            <div class="col-2 col-sm-2">
              <p class="font-weight-bolder">Factor Weight</p>
            </div>
            <div class="col-auto col-sm-auto">
              <p class="font-weight-bolder">:</p>
            </div>
            <div class="col-4 col-sm-4">
              <p>{{ f.Factor.weightage }} %</p>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="card mt-4 px-3 py-4">
      <h5 class="font-weight-bolderf my-3">Score Card Result</h5>
      <div class="multisteps-form__content">
        <div class="mt-3 row">
          <div class="col-2 col-sm-2">
            <p class="font-weight-bolder">Total Factor Score</p>
          </div>
          <div class="col-auto col-sm-auto">
            <p class="font-weight-bolder">:</p>
          </div>
          <div class="col-4 col-sm-4">
            <p>{{ totalFactorScore.toFixed(2) }} %</p>
          </div>
        </div>
      </div>
    </div>

    <div class="card mt-4 px-3 py-4">
      <div class="mt-4 button-row d-flex col-12">
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="mb-0 ms-auto js-btn-next"
          title="Next"
          @click="submitScoreForm()"
          >Save</argon-button
        >
      </div>
    </div>
  </div>
</template>

<script>
//   import ArgonInput from "@/components/ArgonInput.vue";
import { mapState, mapGetters, mapMutations } from 'vuex'
import ArgonButton from '@/components/ArgonButton.vue'
import axios from 'axios'

export default {
  name: 'ScoreCard',
  components: {
    //   ArgonInput,
    ArgonButton
  },

  props: {
    ctosStatus: {
      type: Boolean,
      default: false
    },
    resources: {
      type: Array,
      default: () => []
    },
    applicationFactors: {
      type: Array,
      default: () => []
    }
  },
  emits: ['fetch-data', 'next-step'],
  data() {
    return {
      factorData: []
      // totalFactorScore: 0
    }
  },
  computed: {
    ...mapState(['isLoading', 'apiUrl', 'user']),
    ...mapGetters(['getAPIHeader']),
    totalFactorScore() {
      return this.factorData.reduce((acc, f) => {
        return acc + f.score * 10 * (parseInt(f.Factor.weightage) / 100)
      }, 0)
    }
  },
  watch: {
    applicationFactors() {
      this.getFactorData()
    }
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
    this.getFactorData()
  },

  methods: {
    ...mapMutations(['decreaseIsFetching', 'increaseIsFetching']),
    getCtosStatusClass(status) {
      if (status == 'Pending') return 'badge-light'
      if (status == 'Completed') return 'badge-success'
      if (status == 'Failed') return 'badge-danger'
    },
    getFactorData() {
      this.factorData = new Array()
      this.applicationFactors.forEach((factor) => {
        const smallestScore = Math.min(...factor.Factor.FactorAttributes.map((attr) => attr.score))
        this.factorData.push({
          ...factor,
          score: factor?.score ?? smallestScore
        })
      })
    },
    submitScoreForm() {
      this.increaseIsFetching()
      this.factorData.forEach((f, index) => {
        const attr = f.Factor.FactorAttributes.find((x) => x.score == f.score)
        const payload = {
          id: f.id,
          applicantId: f.applicantId,
          status: null,
          score: f.score,
          factorAttributeId: attr.id
        }
        axios
          .put(`${this.apiUrl}/applicantFactor`, payload, {
            headers: this.apiHeader
          })
          .then(() => {
            if (index == this.factorData.length - 1) {
              this.decreaseIsFetching()
              this.$emit('fetch-data')
              this.$emit('next-step')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      })
    }
  }
}
</script>
