import moment from 'moment/moment'

export function formattedDate(date) {
  return date ? moment(date).format('DD-MM-YYYY') : null
}

export function reverseDateFormatter(date) {
  if (!date) return ''
  const [day, month, year] = date.split('-')
  return `${year}-${month}-${day}`
}

export function getDateDiff(enddate, startdate, type = 'days') {
  if (enddate) {
    const end = moment(enddate).utc()
    const now = moment(startdate).utc()
    return end.diff(now, type)
  } else {
    return null
  }
}

export function getPaymentDateClass(date, startDate) {
  if (startDate) {
    const daysDifference = getDateDiff(date, startDate)
    return daysDifference > 7
      ? 'text-bold text-success'
      : daysDifference < -7
      ? 'text-bold text-danger'
      : 'text-bold text-primary'
  } else {
    return 'text-secondary'
  }
}

export function dateSort(key = 'createdAt', a, b, isAsc) {
  const [day1, month1, year1] = getDateParts(a[key])
  const [day2, month2, year2] = getDateParts(b[key])
  const date1 = new Date(year1, month1, day1)
  const date2 = new Date(year2, month2, day2)

  if (isAsc == 1) return date2 - date1
  else return date1 - date2
}

function getDateParts(date) {
  if (!date) return []
  const [day, month, year] = date.split('-')
  return [day, month - 1, year]
}
