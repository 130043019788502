<script>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import { useRouter } from 'vue-router'

export default {
  name: 'EditFactor',
  props: {
    listOfFactor: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const router = useRouter()
    const factorType = ref(null)
    const store = useStore()

    const increaseIsFetching = () => {
      store.commit('increaseIsFetching')
    }

    const decreaseIsFetching = () => {
      store.commit('decreaseIsFetching')
    }

    const addedWeightage = ref(0)
    const newFactor = ref('')
    const newFactorDesc = ref('')
    const selectedFactorType = ref('')
    function submitForm() {
      if (this.totalWeight != 100) {
        alert('Total weightage must be exactly 100')
        return
      }

      //   Check if added weightage is more than 0 || Basic Validations
      if (this.addedWeightage) {
        if (!this.newFactor) {
          alert('Please enter new factor name')
          return
        }
        if (!this.newFactorDesc) {
          alert('Please enter new factor description')
          return
        }
        if (!this.selectedFactorType) {
          alert('Please select factor type')
          return
        }
      }

      updateAllFactors.call(this)

      console.log(this.newFactor, this.addedWeightage, this.newFactorDesc, this.selectedFactorType)
    }

    async function addNewFactor() {
      if (!this.addedWeightage) return

      try {
        const response = await axios.post(
          `${apiUrl.value}/factor`,
          {
            name: this.newFactor,
            description: this.newFactorDesc,
            weightage: this.addedWeightage,
            resourceId: this.selectedFactorType
          },
          {
            headers: getAPIHeader.value()
          }
        )
        console.log('Added New Factor:', response.data)
        // this.$emit('fetch-data');
        return response.data
      } catch (error) {
        console.log(error)
      }
    }

    async function updateFactor(factor) {
      try {
        const response = await axios.put(
          `${apiUrl.value}/factor`,
          {
            id: factor.id,
            resourceId: factor.resourceId,
            name: factor.name,
            description: factor.description,
            weightage: factor.weightage
          },
          {
            headers: getAPIHeader.value()
          }
        )
        console.log('Factor updated successfully:', response.data)
        // this.$emit('fetch-data');
      } catch (error) {
        console.log(error)
      }
    }

    async function updateAllFactors() {
      increaseIsFetching()
      const updateFactorPromises = this.allFactors.map(updateFactor)
      const addNewFactorPromise = addNewFactor.call(this)

      const results = await Promise.all([...updateFactorPromises, addNewFactorPromise])
      console.log('All factors updated successfully:', results)
      const successResult = results.find((res) => res?.msg === 'Created Successfully')
      decreaseIsFetching()
      if (successResult) {
        router.push({ name: 'FinancialFactorDetail', params: { id: successResult.id } })
      } else {
        window.location.reload()
      }

      // results.forEach((res) => {
      //   if (res?.msg == 'Created Successfully') {
      //     router.push({ name: 'FinancialFactorDetail', params: { id: res.id } })
      //   }
      // })
      // window.location.reload()
    }

    const getFactorType = async () => {
      try {
        const response = await axios.get(`${apiUrl.value}/resource/type/FACTOR_CATEGORY`, {
          headers: getAPIHeader.value()
        })
        factorType.value = response.data.Resources
      } catch (err) {
        console.error(err)
      }
    }

    const totalWeight = computed(() => {
      return props.listOfFactor.reduce((acc, curr) => {
        return acc + parseFloat(curr.weightage)
      }, parseFloat(addedWeightage.value))
    })

    const allFactors = computed(() => {
      return [...props.listOfFactor]
    })

    const getAPIHeader = computed(() => {
      return store.getters.getAPIHeader
    })

    const apiUrl = computed(() => {
      return store.state.apiUrl
    })

    onMounted(() => {
      console.log(props.listOfFactor)
      getFactorType()
    })

    return {
      submitForm,
      totalWeight,
      addedWeightage,
      newFactor,
      newFactorDesc,
      selectedFactorType,
      allFactors,
      factorType,
      getAPIHeader,
      apiUrl
    }
  }
}
</script>

<template>
  <div class="modal">
    <div class="modal-content">
      <form>
        <div class="flex-row new-input">
          <input v-model="newFactor" type="text" placeholder="Enter New Factor Here" />
          <input v-model="addedWeightage" placeholder="0" type="number" />
          <input v-model="newFactorDesc" type="text" placeholder="Enter Description Here" />
          <select v-model="selectedFactorType">
            <option value="" selected>Select Type</option>
            <option v-for="each in factorType" :key="each.id" :value="each.id">
              {{ each.name }}
            </option>
          </select>
        </div>

        <div v-for="each in listOfFactor" :key="each" class="factor-list">
          <!-- <div>{{ each.name }} - {{ each.weightage }}</div> -->
          <div class="flex-row">
            <!-- <label>{{ each.name }}</label> -->
            <label :for="each.id">{{ each.name }}</label>
            <input :id="each.id" v-model="each.weightage" type="number" :name="each.id" />
          </div>
        </div>
        <div class="factor-list">
          <div class="flex-row">
            <label for="totalWeight">Total</label>
            <input
              id="totalWeight"
              ref="totalWeight"
              type="text"
              name="totalWeight"
              readonly
              :value="totalWeight"
              :class="totalWeight == 100 ? 'valid' : 'invalid'"
            />
          </div>
        </div>

        <button class="btn btn-submain text-white" @click.prevent="submitForm" type="submit">
          Save
        </button>
      </form>
    </div>
  </div>
</template>

<style scoped>
.modal::after {
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  display: block;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  z-index: 1000;
  isolation: isolate;
}

.modal-content {
  width: fit-content;
  height: fit-content;
  max-height: 85dvh;
  overflow: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-row.new-input {
  gap: 1rem;
}

input,
select {
  padding: 0.5rem 1rem 0.5rem 0.75rem;
}

input {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4rem;
}

input.valid {
  border: 3px solid green;
}

input.invalid {
  border: 3px solid red;
}

.factor-list {
  margin-top: 5px;
}

.factor-list:nth-child(even) {
  background: #e5e5e5;
}
</style>
