export function calTotalInterestRate(amount, ir, tenure) {
  if (amount && ir && tenure) {
    const total = parseFloat(amount) * (parseFloat(ir) / 100 / 12) * (parseInt(tenure) * 12)
    return formattedAmount(total)
  } else {
    return 0
  }
}
export function calTotalPaylable(amount, ir, tenure) {
  if (amount && ir && tenure) {
    const tir =
      calTotalInterestRate(amount, ir, tenure) != 0
        ? calTotalInterestRate(amount, ir, tenure).replace(',', '')
        : calTotalInterestRate(amount, ir, tenure)
    const total = parseFloat(amount) + parseFloat(tir)

    return formattedAmount(total)
  } else {
    return 0
  }
}
export function calPaidAmount(transactions) {
  const total = transactions
    .filter((x) => x.paidAt)
    .map((x) => x.chargeAmount)
    .reduce((accumulator, currentValue) => {
      return parseFloat(accumulator) + parseFloat(currentValue)
    }, 0)
  return formattedAmount(total)
}
export function undoFormatCurrency(value) {
  if (!value || isFinite(value)) {
    return value
  }

  // Remove commas
  let result = value.replace(/,/g, '')

  // Remove ".00" at the end
  result = result.replace(/\.00$/, '')

  // Remove any string prefixes
  result = result.replace(/^\D*/, '') // Removes non-numeric characters at the beginning

  return result
}
export function formattedAmount(amount) {
  if ((amount != 0 && !amount) || isNaN(amount)) {
    return amount
  } else {
    amount = parseFloat(amount).toFixed(2)
  }
  const parts = amount.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function calPercent(num1, num2) {
  if (num1 && num2 && isNaN(num1) && isNaN(num2)) {
    return (num1 / num2) * 100
  } else {
    return 0
  }
}
