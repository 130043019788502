import { createStore } from 'vuex'
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import axios from 'axios'
import moment from 'moment'
import Cookies from 'js-cookie'
import { AUTH_TOKEN_COOKIE_NAME } from '../router/authService'

export default createStore({
  state: {
    //configuration
    hideConfigButton: false,
    isPinned: true,
    isLoading: false,
    showConfig: false,
    isRTL: false,
    color: '',
    sidebarType: 'bg-white',
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: 'default',
    name: 'default',
    isFetching: 0,
    // libraryx`
    bootstrap,
    axios,
    // default value
    //apiUrl: 'http://localhost/api',
    apiUrl: 'https://xcmgcapmy.org/api',
    // apiUrl: 'https://xcmg.saratix.com/api',
    token: null,
    privilegeLevel: 3,
    resources: [],
    countries: [],
    user: {}
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector('#app')
      if (
        sidenav_show.classList.contains('g-sidenav-show') &&
        sidenav_show.classList.contains('g-sidenav-hidden')
      ) {
        sidenav_show.classList.add('g-sidenav-pinned')
        sidenav_show.classList.remove('g-sidenav-hidden')
        state.isPinned = true
      } else if (
        sidenav_show.classList.contains('g-sidenav-show') &&
        sidenav_show.classList.contains('g-sidenav-pinned')
      ) {
        sidenav_show.classList.add('g-sidenav-hidden')
        sidenav_show.classList.remove('g-sidenav-pinned')
        state.isPinned = true
      } else if (sidenav_show.classList.contains('g-sidenav-show') && window.innerWidth < 1200) {
        sidenav_show.classList.add('g-sidenav-pinned')
        state.isPinned = true
      } else {
        sidenav_show.classList.add('g-sidenav-hidden')
        state.isPinned = true
      }
    },
    setSidebarType(state, payload) {
      state.sidebarType = payload
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true
      } else {
        state.isNavFixed = false
      }
    },
    toggleDefaultLayout(state) {
      state.showNavbar = !state.showNavbar
      state.showSidenav = !state.showSidenav
      state.showFooter = !state.showFooter
    },
    setResources(state, payload) {
      state.resources = payload
    },
    setCountries(state, payload) {
      state.countries = payload
    },
    setPrivilegeLevel(state, payload) {
      state.privilegeLevel = payload
    },
    setName(state, payload) {
      state.name = payload
    },
    setUser(state, payload) {
      state.user = payload
    },
    decreaseIsFetching(state) {
      state.isFetching--
      if (state.isFetching < 1) {
        state.isLoading = false
      }
    },
    increaseIsFetching(state) {
      state.isFetching++
      if (state.isFetching > 0) {
        state.isLoading = true
      }
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit('setSidebarType', payload)
    },
    async getResources({ commit }) {
      if (this.state.resources.length == 0) {
        try {
          return axios
            .get(`${this.state.url}/api/resource/action/all`, {
              headers: {
                Authorization: `Bearer ${this.state.token}`
              }
            })
            .then((response) => {
              commit('setResources', response.data)
              return response.data
            })
        } catch (err) {
          console.error(err)
        }
      }
      return this.state.resources
    },
    getCountries({ commit }) {
      if (this.state.countries.length == 0) {
        try {
          return axios
            .get(`${this.state.url}/api/resource/action/all`, {
              headers: {
                Authorization: `Bearer ${this.state.token}`
              }
            })
            .then((response) => {
              commit('setCountries', response.data)
              return response.data
            })
        } catch (err) {
          console.error(err)
        }
      }
      return this.state.countries
    },
    getName({ commit }, payload) {
      commit('setName', payload)
    },
    async getprivilegeLevel({ commit }, payload) {
      const role = payload.toLowerCase()
      let privilegeLevel = 0
      switch (role) {
        case 'superadmin':
          privilegeLevel = 4
          break
        case 'owner':
          privilegeLevel = 3
          break
        case 'manager':
          privilegeLevel = 2
          break
        case 'quality_checker':
          privilegeLevel = 1
          break
        default:
          privilegeLevel = 0
          break
      }
      commit('setprivilegeLevel', privilegeLevel)
      return privilegeLevel
    }
  },
  getters: {
    validateValue: () => (value) => {
      return value === undefined || value === null || value == ''
    },
    readFileAsBase64: () => (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    },
    getAPIHeader: () => () => {
      return {
        Authorization: `Bearer ${Cookies.get(AUTH_TOKEN_COOKIE_NAME)}`
      }
    },
    capitalizeFirstLetter: () => (string) => {
      return string?.charAt(0).toUpperCase() + string?.slice(1)
    },
    getResourceDesc: (state) => (code) => {
      const resource = state.resources.find((x) => x.ResourceCode === code)
      return resource ? resource.ResourceDesc : code && code != ' ' ? code : 'N/A'
    },
    formattedDate(date) {
      return moment(date).format('DD-MM-YYYY')
    }
  }
})
