<template>
  <div
    id="TransactionModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="TransactionModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="TransactionModalLabel" class="modal-title fs-5">Edit Transaction</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <h6 class="ps-1">
              {{ transactionData.description }}
              <span
                class="badge"
                :class="[
                  transactionData.status != 'Pending'
                    ? transactionData.status == 'Fast'
                      ? 'badge-success'
                      : transactionData.status == 'Normal'
                      ? 'badge-warning'
                      : 'badge-danger'
                    : 'badge-secondary'
                ]"
                >{{ transactionData.status }}</span
              >
            </h6>
          </div>
          <div>
            <label class="form-label">Installment Amount</label>
            <div class="ps-1">RM {{ transactionData.chargeAmount }}</div>
          </div>
          <div>
            <label class="form-label">Payment Amount</label>
            <div class="ps-1">
              <input
                v-model="transactionData.paidAmount"
                type="text"
                class="form-control"
                placeholder="Enter payment amount"
              />
            </div>
          </div>
          <div>
            <label class="form-label">Due Date</label>
            <div class="ps-1">{{ transactionData.dueDate }}</div>
          </div>
          <div class="row mb-10 pb-6">
            <div class="col-6">
              <label class="form-label">Payment Date</label>
              <VueDatePicker
                v-model="transactionData.paidAt"
                auto-apply
                format="dd-MM-yyyy"
                model-type="dd-MM-yyyy"
              />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button
              v-if="transactionData.paidAt"
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
              @click="submitForm()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'
import { undoFormatCurrency } from '@/assets/js/calculator'

export default {
  name: 'TransactionModal',
  components: {
    VueDatePicker
  },
  props: {
    transactions: {
      type: Array,
      default: () => []
    },
    selectedTransactionId: {
      type: Number,
      default: null
    }
  },

  emits: ['fetch-data'],
  data() {
    return {
      apiHeader: {},
      transactionData: {
        paidAt: null
      },
      error: {
        paidAt: false
      }
    }
  },
  computed: {
    ...mapGetters(['validateValue', 'getAPIHeader', 'formattedDate']),
    ...mapState(['apiUrl'])
  },
  watch: {
    selectedTransactionId(id) {
      const foundTransaction = this.transactions.find((x) => x.id === id)
      console.log(foundTransaction, 'foundTransaction', id)
      if (foundTransaction) {
        this.transactionData = JSON.parse(JSON.stringify(foundTransaction))
        this.transactionData.paidAt = this.transactionData.paidAt ?? this.transactionData.dueDate
      } else {
        // To account for the case where the transaction is not found (eg: when no payment status with "Pending")
        this.transactionData = {
          paidAt: null
        }
      }
    }
  },
  mounted() {
    this.apiHeader = this.getAPIHeader()
  },
  methods: {
    undoFormatCurrency,
    submitForm() {
      let validate = true
      console.log(this.transactions, 'transactions')
      console.log(this.selectedTransactionId, 'selectedTransactionId')
      Object.keys(this.error).forEach((x) => {
        if (this.transactionData[x] == null || this.transactionData[x] == '') {
          this.error[x] = true
          validate = false
        } else {
          this.error[x] = false
        }
      })
      if (validate) {
        axios
          .put(
            `${this.apiUrl}/applicantProductTransaction/`,
            {
              id: this.transactionData.id,
              paidAt: moment(this.transactionData.paidAt, 'DD-MM-YYYY').format('YYYY-MM-DD'),
              paidAmount: this.undoFormatCurrency(this.transactionData.paidAmount)
            },
            {
              headers: this.apiHeader
            }
          )
          .then(() => {
            this.$emit('fetch-data')
            this.$swal.fire({
              title: 'Submitted Successfully',
              icon: 'success',
              timer: 2000,
              timerProgressBar: true
            })
          })
          .catch((err) => {
            console.log('AXIOS ERROR: ', err)
          })
      }
    }
    // buildResourceChoice(id) {
    //   const choices = this.resources
    //     .filter((x) => x.category == "CONTACT_POSITION")
    //     .map((x) => ({
    //       label: x.name,
    //       value: x.id,
    //       selected: x.id == id,
    //     }));
    //   console.log(choices);
    //   if (Object.keys(this.positionChoices).length > 0) {
    //     this.positionChoices.destroy();
    //   }
    //   const element = document.getElementById("position-choices");
    //   if (element) {
    //     this.positionChoices = new Choices(element, {
    //       searchEnabled: true,
    //       searchChoices: true,
    //       searchPlaceholderValue: "Search here",
    //       choices: choices,
    //     });
    //   }
    // },
  }
}
</script>

<style></style>
