<template>
  <div
    id="EditProductModal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="EditProductModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h1 id="EditProductModalLabel" class="modal-title fs-5">
            Product Information
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <table class="table">
            <tr class="border-0">
              <th class="col-3">Name</th>
              <th>:</th>
              <td>
                <input
                  type="text"
                  v-model="profileToUpdate.name"
                  class="form-control col-8"
                />
              </td>
            </tr>
            <tr class="border-0">
              <th class="col-3">Brand</th>
              <th>:</th>
              <td>
                <select
                  id="brandName"
                  v-model="profileToUpdate.brandId"
                  class="form-control col-8"
                >
                  <option
                    v-for="brand in brandNames"
                    :key="brand.id"
                    :value="brand.id"
                  >
                    {{ brand.name }}
                  </option>
                </select>
              </td>
            </tr>
            <tr class="border-0">
              <th class="col-3">Cost</th>
              <th>:</th>
              <td>
                <input
                  type="text"
                  v-model="profileToUpdate.cost"
                  class="form-control col-8"
                />
              </td>
            </tr>
            <!-- <tr class="border-0">
                <th class="col-3">Condition</th>
                <th>:</th>
                <td>
                  <select v-model="profileToUpdate.isNew" class="form-control col-8">
                      <option value="1">New</option>
                      <option value="0">Used</option>
                  </select>
                </td>
              </tr> -->
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="updateProfile()"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "EditProductModal",
  props: {
    profileData: {
      type: Object,
    },
  },
  data() {
    return {
      id: "",
      token: "",
      profileToUpdate: {
        id: "",
        brandId: "",
        name: "",
        cost: "",
        isNew: "",
      },
      brandNames: [],
    };
  },
  computed: {
    ...mapGetters(["validateValue", "getAPIHeader", "formattedDate"]),
    ...mapState(["apiUrl"]),
  },

  watch: {
    profileData() {
      this.extracData();
    },
  },

  mounted() {
    this.id = this.$route.params.id;
    this.token = this.getAPIHeader();
    this.getBrandNameList();
  },

  methods: {
    extracData() {
      this.profileToUpdate.id = this.profileData.id;
      this.profileToUpdate.brandId = this.profileData.brandId;
      this.profileToUpdate.name = this.profileData.name;
      this.profileToUpdate.cost = this.profileData.cost;
      this.profileToUpdate.isNew = this.profileData.isNew;
    },

    getBrandNameList() {
      axios
        .get(`${this.apiUrl}/brand/all`, {
          headers: this.token,
        })
        .then((res) => {
          console.log("RESPONSE RECEIVED: ", res.data);
          this.brandNames = res.data.Brands;
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        });
    },

    updateProfile() {
      axios
        .put(`${this.apiUrl}/product/`, this.profileToUpdate, {
          headers: this.token,
        })
        .then((res) => {
          console.log("RESPONSE RECEIVED: ", res.data);
          window.location.reload();
        })
        .catch((err) => {
          console.log("AXIOS ERROR: ", err);
        });
    },
  },
};
</script>

<style></style>
